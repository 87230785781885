import * as React from 'react';
import { useState } from 'react';
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography } from '@mui/material';
import { Trans, useTranslation } from 'react-i18next';
import { namespaces } from '../../application/i18n.constants';
import ValidationForm from '../validators/ValidationForm';
import ValidationSelectField from '../validators/ValidationSelectField';
import ValidationTextField from '../validators/ValidationTextField';
import { IMacro, MacroSectionsList } from '../../core/models/macros/macros.models';
import { getNameForDotPhraseSection } from '../../core/services/macros/macros.services';

export interface IUpdateMacrosDialogProps {
	cancel: {
		action: () => void;
		text: string;
	};
	update: {
		action: (params: { section: string; macro: string; spokenForm: string }) => void;
		text: string;
	};
	title: string;
	shortCodeDisabled?: boolean;
	openedDotPhrase: IMacro;
}

function UpdateMacrosDialog({ cancel, update, title, openedDotPhrase, shortCodeDisabled }: IUpdateMacrosDialogProps) {
	const { t } = useTranslation(namespaces.components.dotPhrases);

	const [disableValidation, setDisableValidation] = useState<boolean>(true);
	const [isFromValid, setIsFromValid] = useState<boolean>(false);
	const [isComplete, setIsComplete] = useState<boolean>(false);
	const [section, setSection] = useState<string>(openedDotPhrase.section);
	const [macro, setMacro] = useState<string>(openedDotPhrase.macro);
	const [spokenForm, setSpokenForm] = useState<string>(openedDotPhrase.spokenForm);

	const cleanTextField = (text: string | undefined | null): string => {
		if (!text) {
			return '';
		}
		return text?.trim();
	};

	const onSubmit = (e: React.MouseEvent<HTMLElement>) => {
		e.preventDefault();
		if (isFromValid) {
			update.action({ section, macro: cleanTextField(macro), spokenForm: cleanTextField(spokenForm) });
		} else {
			setDisableValidation(false);
		}
	};

	return (
		<Dialog fullWidth onClose={cancel.action} open={Boolean(openedDotPhrase)}>
			<Box flexDirection="column" aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
				<DialogTitle id="alert-dialog-title" align="center">
					{title}
				</DialogTitle>
				<Typography fontSize="0.9rem" sx={{ px: 2, whiteSpace: 'pre-wrap' }}>
					<Trans>{t('createInstructions')}</Trans>
				</Typography>
				<DialogContent>
					<ValidationForm
						component="form"
						disableValidation={disableValidation}
						onValidationChange={(valid) => setIsFromValid(valid)}
						onCompletenessChange={(complete) => setIsComplete(complete)}
					>
						<ValidationSelectField
							sx={{ width: 128, mt: 1, mr: 1 }}
							id="sectionInput"
							type="text"
							required
							value={section}
							data={MacroSectionsList.map((x) => ({ value: x, text: getNameForDotPhraseSection(x) }))}
							label={t('section')}
							onChange={(e) => setSection(e.target.value)}
						/>
						{!shortCodeDisabled && (
							<ValidationSelectField
								sx={{ width: 128, mt: 1 }}
								id="dotPhraseSelect"
								type="text"
								disabled
								value={openedDotPhrase.shortCode}
								data={[{ value: openedDotPhrase.shortCode, text: openedDotPhrase.shortCode }]}
								label={t('shortCode')}
							/>
						)}
						<ValidationTextField
							id="descriptionInput"
							sx={{ width: '100%', mt: 2 }}
							type="text"
							disabled
							value={openedDotPhrase.description}
							label={t('description')}
						/>
						<ValidationTextField
							id="macroInput"
							sx={{ width: '100%', mt: 2 }}
							multiline
							rows={4}
							type="text"
							required={openedDotPhrase.shortCode?.length > 0}
							value={macro}
							label={t('macro')}
							onChange={(e) => setMacro(e.target.value)}
						/>
						<ValidationTextField
							id="spokenFormInput"
							sx={{ width: '100%', mt: 2 }}
							multiline
							rows={4}
							type="text"
							required
							value={spokenForm}
							label={t('spokenForm')}
							validation={() => {
								if (!spokenForm.trim().toLowerCase().startsWith('insert')) {
									return {
										success: false,
										error: t('spokenFormMustStartWithInsert'),
									};
								}
								return {
									success: !/[*,_{}():]/.test(spokenForm),
									error: t('spokenFormMustNotContainSpecialCharacters'),
								};
							}}
							onFocus={(e) => {
								if (e.target.value.trim() === '') {
									setSpokenForm('Insert ');
								}
							}}
							onBlur={(e) => {
								if (e.target.value?.trim().toLowerCase() === 'insert') {
									setSpokenForm('');
								}
							}}
							onChange={(e) => setSpokenForm(e.target.value)}
						/>
					</ValidationForm>
				</DialogContent>
				<DialogActions>
					<Button onClick={cancel.action}>{cancel.text}</Button>
					<Button
						variant="contained"
						disabled={
							!isComplete ||
							(openedDotPhrase.macro === macro &&
								openedDotPhrase.spokenForm === spokenForm &&
								openedDotPhrase.section === section)
						}
						onClick={onSubmit}
					>
						{update.text}
					</Button>
				</DialogActions>
			</Box>
		</Dialog>
	);
}

export default UpdateMacrosDialog;
