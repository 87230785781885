import { Box, Button, SxProps, Theme, Typography } from '@mui/material';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { namespaces } from '../../application/i18n.constants';

function BillingServicesListContainer({
	sx,
	children,
	title,
	onApplyAllIcdCodes,
	icdCodesEnabled,
}: {
	sx?: SxProps<Theme>;
	children: React.ReactNode[] | React.ReactNode;
	title: string;
	onApplyAllIcdCodes?: () => void;
	icdCodesEnabled: boolean;
}) {
	const { t } = useTranslation(namespaces.components.billingDetails);
	return (
		<Box>
			<Box sx={{ display: 'flex', justifyContent: 'space-between', ...sx }}>
				<Typography fontWeight="bold" variant="h6">
					{title}
				</Typography>
				{icdCodesEnabled && onApplyAllIcdCodes ? (
					<Button onClick={onApplyAllIcdCodes}>{t('applyICDCodes')}</Button>
				) : null}
			</Box>
			{children}
		</Box>
	);
}

export default BillingServicesListContainer;
