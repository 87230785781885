import { useTranslation } from 'react-i18next';
import React, { useCallback, useMemo, useState } from 'react';
import {
	Alert,
	Box,
	Button,
	DialogActions,
	DialogContent,
	DialogTitle,
	IconButton,
	Menu,
	MenuItem,
	Typography,
} from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { DocumentType, getJobStatusText, JobStatus } from '../../core/models/dictations/document.models';
import { IDictation } from '../../core/models/dictations/dictations.models';
import { namespaces } from '../../application/i18n.constants';
import Dialog from '../dialog/Dialog';
import SelectFiled from '../select/SelectField';
import { documentTypesForEHR } from '../../core/services/documents/documents.services';
import { EHRType } from '../../core/models/ehr.models';

function ChangeDocumentStatusDialog({
	open,
	onClose,
	job,
	onStatusChange,
}: {
	open: boolean;
	onClose: () => void;
	job: IDictation;
	onStatusChange: (job: IDictation, newStatus: JobStatus) => void;
}) {
	const { t } = useTranslation(namespaces.pages.jobsSearch);
	const [newStatus, setNewStatus] = useState<JobStatus | null>(null);

	const options = useMemo(() => {
		if (job.documentStatus) {
			if (job.documentStatus === JobStatus.Completed) {
				return [JobStatus.NotCompleted];
			}
			if (job.documentStatus === JobStatus.Removed) {
				return [JobStatus.NotCompleted];
			}
			if (job.documentStatus === JobStatus.Sending) {
				return [JobStatus.NotCompleted];
			}
			if (job.documentStatus === JobStatus.NotCompleted) {
				return [JobStatus.Removed];
			}
			return [];
		}
		return [JobStatus.Removed];
	}, [job]);

	const actualJobStatus = job.documentStatus || JobStatus.NotCompleted;

	return (
		<Dialog fullWidth onClose={onClose} open={open}>
			<DialogTitle id="alert-dialog-title" align="center">
				{t('changeDocumentStatus')}
			</DialogTitle>
			<DialogContent sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
				<Typography sx={{ mr: 2 }}>{t('documentStatusSelect')}</Typography>
				<SelectFiled
					data={[actualJobStatus, ...options].map((x) => ({
						value: x,
						text: getJobStatusText(x),
					}))}
					value={newStatus || actualJobStatus}
					onChange={(e) => setNewStatus(e.target.value as JobStatus)}
				/>
			</DialogContent>
			<DialogActions>
				<Button onClick={onClose}>{t('cancel')}</Button>
				<Button
					variant="contained"
					disabled={!newStatus}
					onClick={() => {
						onClose();
						onStatusChange(job, newStatus as JobStatus);
					}}
				>
					{t('save')}
				</Button>
			</DialogActions>
		</Dialog>
	);
}

function ChangeDocumentTypeDialog({
	ehr,
	username,
	open,
	onClose,
	job,
	onTypeChange,
}: {
	ehr: EHRType | null;
	username: string;
	open: boolean;
	onClose: () => void;
	job: IDictation;
	onTypeChange: (job: IDictation, newType: DocumentType) => void;
}) {
	const { t } = useTranslation(namespaces.pages.jobsSearch);
	const [newType, setNewType] = useState<DocumentType | null>(null);

	const options = documentTypesForEHR(ehr);

	const onSubmit = () => {
		onClose();
		onTypeChange(job, newType as DocumentType);
	};

	const isCheckedOut = job.isCheckedOut && username !== job.checkedOutBy;

	return (
		<Dialog fullWidth title={t('changeDocumentType')} onClose={onClose} open={open}>
			<DialogContent sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
				{isCheckedOut && (
					<Alert sx={{ width: '100%' }} severity="error">
						{t('isCheckedOutText', { name: job.checkedOutBy || t('anotherUser') })}
					</Alert>
				)}
				{job.isHeld && (
					<Alert sx={{ width: '100%' }} severity="warning">
						{t('isHeldText')}
					</Alert>
				)}
				<Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', mt: 2 }}>
					<Typography sx={{ mr: 2 }}>{t('documentTypeSelect')}</Typography>
					<SelectFiled
						disabled={isCheckedOut}
						data={options.map((x) => ({ value: x, text: x }))}
						value={newType || job.documentType}
						onChange={(e) => setNewType(e.target.value as DocumentType)}
					/>
				</Box>
			</DialogContent>
			<DialogActions>
				<Button onClick={onClose}>{t('cancel')}</Button>
				<Button variant="contained" disabled={!newType || isCheckedOut} onClick={onSubmit}>
					{t('save')}
				</Button>
			</DialogActions>
		</Dialog>
	);
}

function JobMenu({
	job,
	onChangeStatus,
	onChangeType,
	onChangeStat,
}: {
	job: IDictation;
	onChangeStatus: (job: IDictation) => void;
	onChangeType: (job: IDictation) => void;
	onChangeStat: (job: IDictation, newStat: boolean) => void;
}) {
	const { t } = useTranslation(namespaces.pages.jobsSearch);

	const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
	const open = Boolean(anchorEl);
	const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
		setAnchorEl(event.currentTarget);
	};
	const handleClose = () => {
		setAnchorEl(null);
	};

	return (
		<Box>
			<IconButton onClick={handleClick}>
				<MoreVertIcon />
			</IconButton>
			<Menu
				id="basic-menu"
				anchorEl={anchorEl}
				open={open}
				onClose={handleClose}
				MenuListProps={{
					'aria-labelledby': 'basic-button',
				}}
			>
				<MenuItem
					onClick={() => {
						handleClose();
						onChangeStatus(job);
					}}
				>
					{t('changeDocumentStatus')}
				</MenuItem>

				<MenuItem
					disabled={job.documentStatus === JobStatus.Completed}
					onClick={() => {
						handleClose();
						onChangeType(job);
					}}
				>
					{t('changeDocumentType')}
				</MenuItem>
				<MenuItem
					onClick={() => {
						handleClose();
						onChangeStat(job, !job.stat);
					}}
				>
					{job.stat ? t('removeStat') : t('markStat')}
				</MenuItem>
			</Menu>
		</Box>
	);
}
export function useJobMenu({
	ehr,
	username,
	onUpdateJob,
}: {
	ehr: EHRType | null;
	username: string;
	onUpdateJob: (
		job: IDictation,
		update: {
			documentStatus?: JobStatus;
			documentTypeName?: DocumentType;
			stat?: boolean;
		}
	) => void;
}) {
	const [changeStatusDialogJob, setChangeStatusDialogJob] = useState<IDictation | null>(null);
	const [changeTypeDialogJob, setChangeTypeDialogJob] = useState<IDictation | null>(null);

	const renderDocumentStatusDialog = useMemo(() => {
		return (
			changeStatusDialogJob && (
				<ChangeDocumentStatusDialog
					job={changeStatusDialogJob}
					onStatusChange={(j, newStatus) => onUpdateJob(j, { documentStatus: newStatus })}
					onClose={() => setChangeStatusDialogJob(null)}
					open={Boolean(changeStatusDialogJob)}
				/>
			)
		);
	}, [changeStatusDialogJob, setChangeStatusDialogJob]);

	const renderDocumentTypeDialog = useMemo(() => {
		return (
			changeTypeDialogJob && (
				<ChangeDocumentTypeDialog
					ehr={ehr}
					username={username}
					job={changeTypeDialogJob}
					onTypeChange={(j, newType) => onUpdateJob(j, { documentTypeName: newType })}
					onClose={() => setChangeTypeDialogJob(null)}
					open={Boolean(changeTypeDialogJob)}
				/>
			)
		);
	}, [changeTypeDialogJob, username, setChangeTypeDialogJob]);

	const renderMenu = useCallback(
		(job: IDictation) => {
			return (
				<JobMenu
					job={job}
					onChangeStatus={(j) => setChangeStatusDialogJob(j)}
					onChangeType={(j) => setChangeTypeDialogJob(j)}
					onChangeStat={(j, stat) => onUpdateJob(j, { stat })}
				/>
			);
		},
		[setChangeStatusDialogJob, setChangeTypeDialogJob]
	);

	return { renderMenu, renderDocumentStatusDialog, renderDocumentTypeDialog };
}

export default JobMenu;
