import createSagaMiddleware from 'redux-saga';
import { configureStore } from '@reduxjs/toolkit';
import appReducer from './state/app/app.reducer';
import userReducer from './state/user/user.reducer';
import authReducer from './state/auth/auth.reducer';
import worklistReducer from './state/worklist/worklist.reducer';
import patientsReducer from './state/patients/patients.reducer';
import adminReducer from './state/admin/admin.reducer';
import jobsReducer from './state/jobs/jobs.reducer';
import userAISettingsReducer from './state/user-ai-settings/user-ai-settings.reducer';

export const allReducers = {
	app: appReducer,
	user: userReducer,
	auth: authReducer,
	worklist: worklistReducer,
	patients: patientsReducer,
	jobs: jobsReducer,
	admin: adminReducer,
	userAISettings: userAISettingsReducer,
};

export const sagaMiddleware = createSagaMiddleware();

export const reduxStore = configureStore({
	reducer: allReducers,
	middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(sagaMiddleware),
	devTools: process.env.NODE_ENV !== 'production',
});
