import React from 'react';
import ReactDOM from 'react-dom/client';
import { AppInsightsContext } from '@microsoft/applicationinsights-react-js';
import { Provider } from 'react-redux';
import './index.css';
import './application/i18n';
import { ToastContainer } from 'react-toastify';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { IntercomProvider } from 'react-use-intercom';
import App from './pages/App/App';
import reportWebVitals from './utils/reportWebVitals';
import Spinner from './components/spinner/Spinner';
import loadAzureAppInsights, { reactPlugin } from './application/app-insights';
import { reduxStore, sagaMiddleware } from './core/reducers';
import rootSaga from './core/sagas';
import { navigator } from './system/navigator';
import CustomRouter from './components/router/CustomRouter';
import 'react-toastify/dist/ReactToastify.css';
import { initLogRocket } from './application/log-rocket';
import { environment } from './environment/environment';

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(
	<Provider store={reduxStore}>
		<React.StrictMode>
			<React.Suspense fallback={<Spinner />}>
				<AppInsightsContext.Provider value={reactPlugin}>
					<IntercomProvider appId={environment.INTERCOM_APP_ID}>
						<CustomRouter history={navigator}>
							<LocalizationProvider dateAdapter={AdapterDayjs}>
								<App />
							</LocalizationProvider>
							<ToastContainer
								position="top-right"
								autoClose={3000}
								hideProgressBar
								newestOnTop={false}
								closeOnClick
								rtl={false}
								pauseOnFocusLoss
								draggable
								pauseOnHover
							/>
						</CustomRouter>
					</IntercomProvider>
				</AppInsightsContext.Provider>
			</React.Suspense>
		</React.StrictMode>
	</Provider>
);

dayjs.extend(customParseFormat);
sagaMiddleware.run(rootSaga);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
loadAzureAppInsights();

initLogRocket();
