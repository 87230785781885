import * as React from 'react';
import { useEffect, useState } from 'react';
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, Typography } from '@mui/material';
import { Trans, useTranslation } from 'react-i18next';
import { namespaces } from '../../application/i18n.constants';
import ValidationForm from '../validators/ValidationForm';
import ValidationSelectField from '../validators/ValidationSelectField';
import ValidationTextField from '../validators/ValidationTextField';
import { MacroSection, MacroSectionsList } from '../../core/models/macros/macros.models';
import { IProvider } from '../../core/models/providers/providers.models';
import { getNameForDotPhraseSection } from '../../core/services/macros/macros.services';

export interface IAddMacrosDialogProps {
	open: boolean;
	cancel: {
		action: () => void;
		text: string;
	};
	add: {
		action: (params: {
			providerId: string;
			section: string;
			shortCode: string;
			description: string;
			macro: string;
			spokenForm: string;
		}) => void;
		text: string;
	};
	title: string;
	providers?: {
		disabled?: boolean;
		allProviders: IProvider[];
		preSelectedProvider: string;
	};
	shortCodeDisabled?: boolean;
}

function AddMacrosDialog({ open, cancel, add, title, shortCodeDisabled, providers }: IAddMacrosDialogProps) {
	const { t } = useTranslation(namespaces.components.dotPhrases);

	const [disableValidation, setDisableValidation] = useState<boolean>(true);
	const [selectedProvider, setSelectedProvider] = useState<string | null>(providers?.preSelectedProvider || null);

	const [isFromValid, setIsFromValid] = useState<boolean>(false);
	const [isComplete, setIsComplete] = useState<boolean>(false);

	const [section, setSection] = useState<MacroSection | ''>('');
	const [shortCode, setShortCode] = useState<string | ''>('');
	const [macro, setMacro] = useState<string>('');
	const [spokenForm, setSpokenForm] = useState<string>('');
	const [description, setDescription] = useState<string>('');

	const [showConfirm, setShowConfirm] = useState<boolean>(false);

	const resetFields = (fields: { provider?: string }) => {
		setSelectedProvider(fields.provider || '');

		setSection('');
		setShortCode('');

		setDescription('');
		setMacro('');
		setSpokenForm('');
	};

	useEffect(() => {
		if (open) {
			resetFields({ provider: providers?.preSelectedProvider });
		}
	}, [open]);

	const handleResetForm = () => {
		resetFields({});
		setShowConfirm(false);
	};

	const cleanTextField = (text: string | undefined | null): string => {
		if (!text) {
			return '';
		}
		return text?.trim();
	};

	const handleAddCode = () => {
		add.action({
			providerId: selectedProvider || 'N/A',
			section,
			shortCode,
			description: cleanTextField(description),
			macro: cleanTextField(macro),
			spokenForm: cleanTextField(spokenForm),
		});
		resetFields({});
		setTimeout(() => setShowConfirm(false), 200);
	};

	const onSubmit = (e: React.MouseEvent<HTMLElement>) => {
		e.preventDefault();
		if (isFromValid) {
			setShowConfirm(true);
		} else {
			setDisableValidation(false);
		}
	};

	const handleSetProvider = (providerId: string) => {
		setSelectedProvider(providerId);
	};

	return (
		<Dialog fullWidth onClose={cancel.action} open={open}>
			<Box flexDirection="column" aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
				<DialogTitle id="alert-dialog-title" align="center">
					{title}
				</DialogTitle>
				<Typography fontSize="0.9rem" sx={{ px: 2, whiteSpace: 'pre-wrap' }}>
					<Trans>{t('createInstructions')}</Trans>
				</Typography>
				<DialogContent>
					{showConfirm ? (
						<Box sx={{ padding: 1, display: 'flex', justifyContent: 'center' }}>
							<Typography>{`${t('addMacroText')} "${macro}" ${t('inSection')} ${section}?`}</Typography>
						</Box>
					) : (
						<Grid
							container
							sx={{
								width: '100%',
								display: 'flex',
								alignItems: 'center',
								justifyContent: 'center',
							}}
						>
							<ValidationForm
								sx={{ mt: 1 }}
								component="form"
								disableValidation={disableValidation}
								onValidationChange={(valid) => setIsFromValid(valid)}
								onCompletenessChange={(complete) => setIsComplete(complete)}
							>
								{providers ? (
									<ValidationSelectField
										sx={{ minWidth: 166, mt: 1, mr: 1 }}
										id="providerSelect"
										type="text"
										required={!!providers}
										value={selectedProvider}
										data={providers.allProviders.map((x) => ({ value: x.id, text: x.name }))}
										label={t('selectProvider')}
										disabled={providers.disabled}
										onChange={(e) => handleSetProvider(e.target.value)}
									/>
								) : null}
								<ValidationSelectField
									sx={{ width: 128, mt: 1, mr: 1 }}
									id="sectionInput"
									type="text"
									required
									value={section}
									data={MacroSectionsList.map((x) => ({ value: x, text: getNameForDotPhraseSection(x) }))}
									label={t('section')}
									onChange={(e) => setSection(e.target.value as MacroSection)}
								/>
								{!shortCodeDisabled && (
									<ValidationTextField
										id="shortCodeInput"
										sx={{ width: 128, mt: 1 }}
										type="text"
										value={shortCode}
										label={t('shortCode')}
										onChange={(e) => setShortCode(e.target.value)}
									/>
								)}
								<ValidationTextField
									id="descriptionInput"
									sx={{ width: '100%', mt: 2 }}
									type="text"
									required={shortCode?.length > 0}
									value={description}
									label={t('description')}
									onChange={(e) => setDescription(e.target.value)}
								/>
								<ValidationTextField
									id="macroInput"
									sx={{ width: '100%', mt: 2 }}
									multiline
									rows={4}
									type="text"
									value={macro}
									required={shortCode?.length > 0}
									label={t('macro')}
									onChange={(e) => setMacro(e.target.value)}
								/>
								<ValidationTextField
									id="spokenFormInput"
									sx={{ width: '100%', mt: 2 }}
									multiline
									rows={4}
									type="text"
									required
									value={spokenForm}
									label={t('spokenForm')}
									validation={() => {
										if (!spokenForm.trim().toLowerCase().startsWith('insert')) {
											return {
												success: false,
												error: t('spokenFormMustStartWithInsert'),
											};
										}
										return {
											success: !/[*,_{}():]/.test(spokenForm),
											error: t('spokenFormMustNotContainSpecialCharacters'),
										};
									}}
									onFocus={(e) => {
										if (e.target.value.trim() === '') {
											setSpokenForm('Insert ');
										}
									}}
									onBlur={(e) => {
										if (e.target.value?.trim().toLowerCase() === 'insert') {
											setSpokenForm('');
										}
									}}
									onChange={(e) => setSpokenForm(e.target.value)}
								/>
							</ValidationForm>
						</Grid>
					)}
				</DialogContent>
				{showConfirm ? (
					<DialogActions>
						<Button onClick={handleResetForm}>{t('back')}</Button>
						<Button variant="contained" color="success" type="submit" onClick={handleAddCode}>
							{t('continue')}
						</Button>
					</DialogActions>
				) : (
					<DialogActions>
						<Button onClick={cancel.action}>{cancel.text}</Button>
						<Button variant="contained" disabled={!isComplete} onClick={onSubmit}>
							{add.text}
						</Button>
					</DialogActions>
				)}
			</Box>
		</Dialog>
	);
}

export default AddMacrosDialog;
