import * as React from 'react';
import { useEffect, useMemo, useState } from 'react';
import { Box, Grid } from '@mui/material';
import { IUserAIConfigPhrase, IUserAIConfiguration } from '../../core/api/user/user-ai-settings.models';
import { IUserDisplayName } from '../../core/models/users/user.models';
import PageContainer from '../../components/page-container/PageContainer';
import HorizontalTabPanel from '../../components/tab-panel/HorizontalTabPanel';
import { IJobDictation, IOpenAISummary } from '../../core/models/dictations/dictations.models';
import UserAIConfigHeader from '../../components/user-ai-config/UserAIConfigHeader';
import AISettingsConfigurationSection from '../../components/user-ai-config/AISettingsConfigurationSection';
import AISettingsReviewSection from '../../components/user-ai-config/AISettingsReviewSection';
import { EHRType } from '../../core/models/ehr.models';

interface IUserAISettingsPageProps {
	isMobile: boolean;
	ehr: EHRType;
	enableSelectUser: boolean;
	isConfigChanged: boolean;
	isSaving: boolean;
	userInfoId: string;
	users: IUserDisplayName[];
	jobs: IJobDictation[];
	summaryIsLoading: boolean;
	transcription: { openAISummary: string | null; openAISummaryStructured: IOpenAISummary | null };
	configuration: IUserAIConfiguration;
	onConfigurationChange: (configuration: IUserAIConfigPhrase[]) => void;
	onDiscreteDiagnosisChange: (value: boolean) => void;
	onSelectUser: (userInfoId: string) => void;
	onSaveChanges: () => void;
	onReviewChanges: (blobId: string) => void;
}

function UserAISettingsPage({
	isMobile,
	ehr,
	enableSelectUser,
	isConfigChanged,
	isSaving,
	userInfoId,
	users,
	jobs,
	summaryIsLoading,
	transcription,
	configuration,
	onConfigurationChange,
	onDiscreteDiagnosisChange,
	onSelectUser,
	onSaveChanges,
	onReviewChanges,
}: IUserAISettingsPageProps) {
	const [activeTab, setActiveTab] = useState<'settings' | 'review'>('settings');

	const maxHeightDesktop = 'calc(100vh - 64px)';
	const maxHeightMobile = 'calc(100vh)';

	useEffect(() => {
		setActiveTab('settings');
	}, []);

	const Settings = useMemo(
		() => (
			<AISettingsConfigurationSection
				isMobile={isMobile}
				isSaving={isSaving}
				enableSelectUser={enableSelectUser}
				userInfoId={userInfoId}
				users={users}
				onSelectUser={onSelectUser}
				configuration={configuration}
				onConfigurationChange={onConfigurationChange}
				onDiscreteDiagnosisChange={onDiscreteDiagnosisChange}
				onSaveChanges={onSaveChanges}
			/>
		),
		[
			userInfoId,
			users,
			isSaving,
			onSelectUser,
			isMobile,
			configuration,
			enableSelectUser,
			onConfigurationChange,
			onDiscreteDiagnosisChange,
			onSaveChanges,
		]
	);

	const Review = useMemo(
		() => (
			<AISettingsReviewSection
				isLoading={summaryIsLoading}
				isConfigChanged={isConfigChanged}
				isMobile={isMobile}
				jobs={jobs}
				ehr={ehr}
				transcription={transcription}
				onReviewChanges={onReviewChanges}
			/>
		),
		[ehr, isMobile, jobs, transcription, onReviewChanges, isConfigChanged, summaryIsLoading, onReviewChanges]
	);

	return (
		<PageContainer navbar={!isMobile} disablePadding sx={{ maxHeight: isMobile ? maxHeightMobile : maxHeightDesktop }}>
			{isMobile ? (
				<Box
					sx={{
						width: '100%',
					}}
				>
					<UserAIConfigHeader
						isMobile={isMobile}
						isSaving={isSaving}
						enableSelectUser={enableSelectUser}
						onSelectUser={onSelectUser}
						users={users}
						userInfoId={userInfoId}
						onSaveChanges={onSaveChanges}
					/>
					<HorizontalTabPanel
						initTab={activeTab}
						tabs={[
							{
								id: 'settings',
								enabled: true,
								name: 'Settings',
								component: Settings,
							},
							{
								id: 'review',
								enabled: true,
								name: 'Review',
								component: Review,
							},
						]}
					/>
				</Box>
			) : (
				<Grid
					sx={{
						height: '100%',
						overflowY: 'auto',
					}}
					container
					rowSpacing={2}
				>
					<Grid
						sx={{
							height: '100%',
							overflowY: 'auto',
						}}
						item
						xs={6}
					>
						{Settings}
					</Grid>
					<Grid
						sx={{
							height: '100%',
							overflowY: 'auto',
						}}
						item
						xs={6}
					>
						{Review}
					</Grid>
				</Grid>
			)}
		</PageContainer>
	);
}

export default UserAISettingsPage;
