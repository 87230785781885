import { ISignalREventHandler } from './useSignalRjQuery';
import { AppDispatch } from '../core/core.types';
import { actions } from '../core/state/actions';

const eventHandlers = (): ISignalREventHandler[] => {
	return [
		{
			event: 'aicomplete',
			callback: (dispatch: AppDispatch, blobId: string) => {
				dispatch(actions.worklist.updatePromptAISummary({ blobId }));
				dispatch(actions.jobs.updatePromptAISummary({ blobId }));
				dispatch(actions.userAISettings.updatePromptAISummary({ blobId }));
			},
		},
	];
};

export default eventHandlers;
