import * as React from 'react';
import BillingServicesSection, { IBillingServicesSectionProps } from './BillingServicesSection';
import { EHRType } from '../../core/models/ehr.models';
import { BillingServicesFeatures } from './BillingServiceItemList';

interface IEhrBillingServicesSectionProps extends Omit<IBillingServicesSectionProps, 'disabledFeatures'> {
	ehr: EHRType;
}
function EhrBillingServicesSection({ ehr, ...props }: IEhrBillingServicesSectionProps) {
	if (ehr === EHRType.NextGen) {
		return (
			<BillingServicesSection
				// eslint-disable-next-line react/jsx-props-no-spreading
				{...props}
				billingCodes={props.billingCodes.filter((x) => x.serviceID || x.serviceType?.toLowerCase() === 'emcode')}
				disabledFeatures={[]}
			/>
		);
	}

	if (ehr === EHRType.PrimeSuite) {
		return (
			<BillingServicesSection
				// eslint-disable-next-line react/jsx-props-no-spreading
				{...props}
				billingCodes={props.billingCodes.filter((x) => x.serviceID || x.serviceType?.toLowerCase() === 'emcode')}
				disabledFeatures={[
					BillingServicesFeatures.Icd10Codes,
					BillingServicesFeatures.Units,
					BillingServicesFeatures.Modifiers,
				]}
			/>
		);
	}

	// eslint-disable-next-line react/jsx-props-no-spreading
	return <BillingServicesSection {...props} disabledFeatures={[]} />;
}

export default EhrBillingServicesSection;
