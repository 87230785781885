import { Tab, Tabs } from '@mui/material';
import { TabContext, TabPanel } from '@mui/lab';
import * as React from 'react';
import { ForwardedRef, useEffect, useImperativeHandle, useState } from 'react';
import { Box } from '@mui/system';

export interface IHorizontalTabs {
	setTab: (key: string) => void;
}

interface IHorizontalTabsProps {
	initTab?: string;
	current?: string;
	tabs: {
		id: string;
		enabled: boolean;
		name: string;
		component: React.ReactNode;
		onTabPress?: (tab: string) => void;
	}[];
	renderHeader?: (currentTab: string) => React.ReactNode;
	tabPanelContainerStyles?: React.CSSProperties;
}

const HorizontalTabPanel: React.ForwardRefRenderFunction<IHorizontalTabs, IHorizontalTabsProps> =
	function HorizontalTabPanelFunc(
		{ initTab, tabs, current, renderHeader, tabPanelContainerStyles }: IHorizontalTabsProps,
		forwardedRef: ForwardedRef<IHorizontalTabs>
	) {
		const [currentTab, setCurrentTab] = useState<string>(initTab || tabs[0].id);

		useImperativeHandle(forwardedRef, () => ({
			setTab(key: string) {
				setCurrentTab(key);
			},
		}));

		useEffect(() => {
			if (current) {
				setCurrentTab(current);
			}
		});

		useEffect(() => {
			if (currentTab) {
				setCurrentTab(currentTab);
			}
		}, [currentTab]);

		return (
			<TabContext value={currentTab}>
				<Tabs
					variant="fullWidth"
					value={currentTab}
					onChange={(event: React.SyntheticEvent, value: string) => {
						event.preventDefault();
						const handler = tabs.find((x) => x.id === value)?.onTabPress;
						setCurrentTab(value);
						if (handler) {
							handler(value);
						}
					}}
					aria-label="tab"
					sx={{ borderRight: 1, borderColor: 'divider' }}
				>
					{tabs.map((x) => (
						<Tab
							disabled={!x.enabled || tabs.length === 1}
							key={`tab-${x.id}`}
							label={x.name}
							id={x.id}
							value={x.id}
							aria-controls={`horizontal-tabpanel-${x.id}`}
						/>
					))}
				</Tabs>
				{renderHeader ? renderHeader(currentTab) : null}
				<Box
					sx={{
						overflow: 'auto',
						...tabPanelContainerStyles,
					}}
				>
					{tabs.map((x) => (
						<TabPanel sx={{ p: 0 }} key={`panel-${x.id}`} value={x.id}>
							{x.component}
						</TabPanel>
					))}
				</Box>
			</TabContext>
		);
	};

export default React.forwardRef(HorizontalTabPanel);
