import { cloneDeep, uniq } from 'lodash';
import {
	IBillingCode,
	IDiagnoseField,
	IDiagnoseFields,
	IDictation,
	IICDCode,
	IOrderField,
	IProcedureDocumentationField,
	IQANote,
	IUnrankedDiagnoseField,
} from '../../models/dictations/dictations.models';
import {
	IApiQANote,
	IDocProcedureDocumentationResponse,
	IProviderDictationResponse,
} from '../../api/dictations/dictations.api.models';
import { DocumentType, IDocumentDetails, IEMCoding } from '../../models/dictations/document.models';
import { distinct } from '../../../utils/array.utils';
import { IPatientEncounter } from '../../models/patients/patients.models';
import { EHRType } from '../../models/ehr.models';

export const diagHash = (diagnose: { SNOMEDCode: string }): string => {
	return `${diagnose.SNOMEDCode}`;
};

export const canEhrAddDiagnosis = (ehr: EHRType) => {
	return ehr !== EHRType.AllscriptsTW;
};

export const isVisit = (documentTypeName: string, ehr: EHRType) => {
	if (ehr === EHRType.PrimeSuite) {
		return (
			documentTypeName?.toLowerCase() === DocumentType.ProgressNote.toLowerCase() ||
			documentTypeName?.toLowerCase() === DocumentType.HistoryAndPhysical.toLowerCase()
		);
	}
	return documentTypeName?.toLowerCase() === DocumentType.Visit.toLowerCase();
};

export const defaultSearchDocumentType = (ehr: EHRType): DocumentType[] => {
	if (ehr === EHRType.PrimeSuite) {
		return [DocumentType.ProgressNote, DocumentType.HistoryAndPhysical];
	}
	return [DocumentType.Visit];
};

export const emCodeSupportOnly = (ehr: EHRType): boolean => {
	return ehr === EHRType.PrimeSuite;
};

export const maxBillingServices = (ehr: EHRType): number | null => {
	return emCodeSupportOnly(ehr) ? 1 : null;
};

export const documentTypesForEHR = (ehr: EHRType | null): DocumentType[] => {
	const documentTypes = [
		DocumentType.Visit,
		DocumentType.DictationLetter,
		DocumentType.DictationNote,
		DocumentType.OperativeNote,
		DocumentType.Scribe,
		DocumentType.NonPatient,
	];

	if (ehr === EHRType.PrimeSuite) {
		documentTypes.push(DocumentType.ProgressNote);
		documentTypes.push(DocumentType.HistoryAndPhysical);
	}
	return documentTypes;
};

export const canJobHaveEncounters = (documentType: string, ehr: EHRType) => isVisit(documentType, ehr);

// export const diagHash = (diagnose: IDiagnoseField): string => {
// 	const codes = diagnose.icdCodes
// 		.slice()
// 		.sort((a, b) => b.codeset.localeCompare(a.codeset))
// 		.filter((x) => x.codeset === 'ICD10' && x.code && x.code.length > 0);
//
// 	return codes.map((x) => `${x.codeset}-${x.code}`).reduce((x, y) => `${x}_${y}`);
// };
export const filterEligibleEncounters = (ehr: EHRType, encounters?: IPatientEncounter[]) =>
	encounters
		? encounters
				.filter((x) => isVisit(x.documentTypeName, ehr))
				.filter(
					(x) =>
						ehr !== EHRType.Athena || (x.iScribeDocumentStatus && x.iScribeDocumentStatus.toLowerCase() !== 'signed')
				)
				.filter(
					(x) =>
						ehr !== EHRType.Athena ||
						(x.documentStatus && x.documentStatus.toLowerCase() === 'open') ||
						x.documentStatus.toLowerCase() === 'review'
				)
		: [];

export const orderHash = (order: IOrderField): string => {
	return `${order.orderTypeGroup}_${order.orderTypeId}`;
};

export const getICD10Codes = (diagnoses: IDiagnoseField[]): string[] => {
	const codes = diagnoses
		.filter((x) => x.modified !== 2)
		.map((x) => x.icdCodes)
		.flat(1)
		.filter((x) => x.code && x.codeset.toLowerCase() === 'icd10')
		.map((x) => ({ ...x, code: x.code.replace(/[.]/g, '') }))
		.map((x) => x.code);

	return uniq(codes);
};

export const getDefaultEMCode = (): IEMCoding => {
	return {
		ServiceCategory: 0,
		NewPatient: false,
		PrimaryFocus: false,
		TimeSpent: 0,
		ExaminationType: 0,
		Complexity: {
			Risk: 0,
			Labs: false,
			Radiographic: false,
			Diagnostic: false,
			DiscussedResults: false,
			ObtainedRecords: false,
			ReviewedRecords: false,
			IndependentReview: false,
		},
		Coding: {
			Code: '',
			SuggestedCode: null,
			Mod1: null,
			Mod2: null,
			Mod3: null,
			Mod4: null,
			MappedDXs: null,
		},
	};
};

export const getDocumentEMCodingByBillingCodes = (
	emCoding: IEMCoding | null,
	billingCodes: IBillingCode[]
): IEMCoding | null => {
	if (!emCoding && (!billingCodes || billingCodes.length === 0 || billingCodes.length > 1)) {
		return emCoding;
	}
	const defaultEMCode = getDefaultEMCode();
	if (!emCoding) {
		return {
			...defaultEMCode,
			Coding: {
				...defaultEMCode.Coding,
				Code: billingCodes[0].procedureCode,
			},
		};
	}

	if (emCoding.Coding.Code !== billingCodes[0].procedureCode) {
		return {
			...emCoding,
			Coding: {
				...emCoding.Coding,
				Code: billingCodes[0].procedureCode,
			},
		};
	}

	return emCoding || null;
};

export const getBillingCodesByEMCoding = (emCoding: IEMCoding | null): IBillingCode[] => {
	if (!emCoding) {
		return [];
	}

	if (!emCoding.Coding.Code) {
		return [];
	}

	return [
		{
			modified: 0,
			procedureCode: emCoding.Coding.Code,
			serviceType: 'EMCODE',
			modifiers: [],
			iCD10Codes: [],
			billForService: false,
			units: null,
		},
	];
};

export const addDiagnoseToArray = (diagnoses: IDiagnoseField[], diagnose: IUnrankedDiagnoseField): IDiagnoseField[] => {
	const diags = cloneDeep(diagnoses);
	const currentDiagnose = diags.find((x) => diagHash(x) === diagHash(diagnose));

	const newModified = currentDiagnose?.diagnosisId ? 3 : 1;
	const newRanking = currentDiagnose?.diagnosisId
		? currentDiagnose.ranking
		: diagnoses
				.map((x) => x.ranking)
				.reduce((max, r) => {
					return r > max ? r : max;
				}, 0) + 1;

	if (!currentDiagnose || currentDiagnose.modified === 2) {
		return [
			...diags.filter((x) => diagHash(x) !== diagHash(diagnose)),
			{
				...diagnose,
				diagnosisId: currentDiagnose?.diagnosisId || null,
				ranking: newRanking,
				modified: newModified,
			},
		];
	}

	const newIcdCodes = distinct(
		[...currentDiagnose.icdCodes, ...diagnose.icdCodes],
		(item) => `${item.codeset}_${item.code}`
	);

	return [
		...diags.filter((x) => diagHash(x) !== diagHash(diagnose)),
		{
			...currentDiagnose,
			icdCodes: newIcdCodes,
			ranking: newRanking,
			modified: newModified,
		},
	];
};

export const addDiagnoseToFields = (
	contextFields: IDiagnoseFields,
	diagnose: IUnrankedDiagnoseField
): IDiagnoseFields => {
	return { ...contextFields, diagnoses: addDiagnoseToArray(contextFields.diagnoses, diagnose) };
};

export const onDeleteDiagnoseFromArray = (diagnoses: IDiagnoseField[], dHash: string): IDiagnoseField[] => {
	const diags = cloneDeep(diagnoses);
	const diagnose = diags.filter((x) => x.modified !== 2).find((x) => diagHash(x) === dHash);

	if (diagnose) {
		if (diagnose.diagnosisId) {
			diagnose.modified = 2;
			return diags;
		}
		return diags.filter((x) => diagHash(x) !== dHash);
	}

	return diags;
};

export const onDeleteDiagnoseFromFields = (contextFields: IDiagnoseFields, dHash: string): IDiagnoseFields => {
	return { ...contextFields, diagnoses: onDeleteDiagnoseFromArray(contextFields.diagnoses, dHash) };
};

export const addOrderToDiagnoseArray = (
	diagnoses: IDiagnoseField[],
	dHash: string,
	order: IOrderField
): IDiagnoseField[] => {
	const diags = cloneDeep(diagnoses);
	const diagnose = diags.find((x) => x.modified !== 2 && diagHash(x) === dHash);

	if (diagnose) {
		const currentOrder = diagnose.orders.find((x) => orderHash(x) === orderHash(order));
		if (!currentOrder || currentOrder.modified === 2) {
			diagnose.modified = diagnose.diagnosisId ? 3 : 1;
			diagnose.orders = [
				...diagnose.orders.filter((x) => orderHash(x) !== orderHash(order)),
				{ ...order, orderId: currentOrder?.orderId || null, modified: currentOrder?.orderId ? 3 : 1 },
			];
			return diags;
		}
	}
	return diags;
};

export const addOrderToDiagnoseFields = (
	contextFields: IDiagnoseFields,
	dHash: string,
	order: IOrderField
): IDiagnoseFields => {
	return { ...contextFields, diagnoses: addOrderToDiagnoseArray(contextFields.diagnoses, dHash, order) };
};

export const deleteDiagnoseOrderFromArray = (
	diagnoses: IDiagnoseField[],
	dHash: string,
	oHash: string
): IDiagnoseField[] => {
	const diags = cloneDeep(diagnoses);
	const diagnose = diags.find((x) => diagHash(x) === dHash);
	if (diagnose) {
		const order = diagnose.orders.find((x) => orderHash(x) === oHash);
		if (order) {
			if (order.orderId) {
				order.modified = 2;
				return diags;
			}
			diagnose.orders = [...diagnose.orders.filter((x) => orderHash(x) !== oHash)];
			return diags;
		}
	}
	return diags;
};

export const deleteDiagnoseOrderFromFields = (
	contextFields: IDiagnoseFields,
	dHash: string,
	oHash: string
): IDiagnoseFields => {
	return { ...contextFields, diagnoses: deleteDiagnoseOrderFromArray(contextFields.diagnoses, dHash, oHash) };
};

export const mergeDiagnoses = (
	current: IDiagnoseField[],
	pulled: IDiagnoseField[],
	includeOrders: boolean
): IDiagnoseField[] => {
	let result = cloneDeep(current);
	pulled.forEach((pulledDiag) => {
		if (!current.filter((x) => x.modified !== 2).find((x) => diagHash(x) === diagHash(pulledDiag))) {
			result = addDiagnoseToArray(result, { ...pulledDiag, modified: 1, diagnosisId: null, orders: [] });
		}
		if (includeOrders) {
			const currentDiag = result.filter((x) => x.modified !== 2).find((x) => diagHash(x) === diagHash(pulledDiag));
			if (currentDiag) {
				pulledDiag.orders.forEach((pulledOrder) => {
					if (
						!currentDiag.orders.filter((x) => x.modified !== 2).find((x) => orderHash(x) === orderHash(pulledOrder))
					) {
						result = addOrderToDiagnoseArray(result, diagHash(currentDiag), {
							...pulledOrder,
							modified: 1,
							orderId: null,
						});
					}
				});
			}
		}
	});

	return result;
};

export function mapProcedureDocumentationResponseToFields(
	procedureDocumentations: IDocProcedureDocumentationResponse[]
): IProcedureDocumentationField[] {
	return procedureDocumentations.map((pd) => ({
		procedureTemplateId: pd.procedureTemplateID,
		procedureDocumentationText: pd.proceduredocumentationtext,
		procedureTemplateName: pd.proceduretemplatename,
		templateData: pd.TemplateData,
		modified: pd.Modified,
	}));
}

export function mapProcedureDocumentationFieldsToResponse(
	procedureDocumentations: IProcedureDocumentationField[]
): IDocProcedureDocumentationResponse[] {
	return procedureDocumentations.map((x) => ({
		procedureTemplateID: x.procedureTemplateId,
		proceduretemplatename: x.procedureTemplateName,
		proceduredocumentationtext: x.procedureDocumentationText,
		TemplateData: x.templateData,
		Modified: x.modified,
	}));
}

export const createDiagnose = ({
	icd10Code,
	icd10Codes,
	snomedCode,
	snomedName,
	lexicalId,
	caption,
	ranking,
}: {
	icd10Code?: string;
	icd10Codes?: IICDCode[];
	snomedCode: string;
	snomedName?: string;
	lexicalId?: string;
	caption: string;
	ranking?: number;
}): IUnrankedDiagnoseField => ({
	SNOMEDCode: snomedCode,
	// ranking is not assigned
	ranking: ranking !== undefined ? ranking : -1,
	icdCodes: icd10Codes || (icd10Code ? [{ code: icd10Code, codeset: 'ICD10', description: '' }] : []),
	caption,
	diagnosisId: null,
	SNOMEDName: snomedName || null,
	modified: 0,
	orders: [],
	note: '',
	canPostCoordinate: false,
	selected: true,
	addToProblemList: false,
	lexicalId: lexicalId || '',
	problem: false,
	problemStatus: 0,
});
export const mapDocumentToFields = (document: IDocumentDetails): IDiagnoseFields => {
	return {
		chiefComplaints: document.ChiefComplaints,
		hpiSketchPad: document.hpiSketchPad,
		rosSketchPad: document.rosSketchPad,
		peSketchPad: document.peSketchPad,
		patientGoals: document.PatientGoals,
		patientInstructions: document.PatientInstructions,
		discussionNotes: document.DiscussionNotes,
		assessmentPlan: document.AssessmentPlanSketchPad || '',
		assessment: document.assSketchPad || '',
		plan: document.planSketchPad || '',
		diagnoses: document.Diagnoses
			? document.Diagnoses.map((diagnose) => ({
					diagnosisId: diagnose.DiagnosisID,
					ranking: diagnose.Ranking,
					caption: diagnose.Caption,
					icdCodes: diagnose.ICDCodes.map((y) => ({
						code: y.Code,
						codeset: y.Codeset,
						description: y.Description,
					})),
					selected: diagnose.Selected,
					lexicalId: diagnose.LexicalID,
					note: diagnose.Note,
					modified: diagnose.Modified,
					problem: diagnose.Problem,
					problemStatus: diagnose.ProblemStatus,
					addToProblemList: diagnose.AddToProblemList,
					canPostCoordinate: diagnose.CanPostCoordinate,
					SNOMEDCode: diagnose.SNOMEDCode,
					SNOMEDName: diagnose.SNOMEDName,
					laterality: diagnose.Laterality,
					orders: diagnose.Orders
						? diagnose.Orders.map((order) => ({
								orderId: order.OrderID,
								selected: order.Selected,
								orderItemId: order.OrderItemID,
								orderTypeId: order.OrderTypeID,
								orderTypeGroup: order.OrderTypeGroup,
								description: order.Description,
								interpretation: order.Interpretation
									? {
											note: order.Interpretation.Note || '',
											interpretationId: order.Interpretation.InterpretationID,
											orderId: order.Interpretation.OrderID,
											discussedWithPatient: order.Interpretation.DiscussedWithPatient,
									  }
									: undefined,
								status: order.Status,
								modified: order.Modified,
								class: order.Class
									? {
											class: order.Class.Class,
											classDescription: order.Class.ClassDescription,
									  }
									: undefined,
								procedure: order.Procedure
									? {
											selected: order.Procedure.Selected,
											procedureLexicalCode: order.Procedure.ProcedureLexicalCode,
											procedureDescription: order.Procedure.ProcedureDescription,
									  }
									: {
											selected: true,
											procedureLexicalCode: '',
											procedureDescription: order.Description,
									  },
								assignedTo: order.AssignedTo,
								createdBy: order.CreatedBy,
								lastModifiedBy: order.LastModifiedBy,
								lastModifiedDate: order.LastModifiedDate,
								orderedBy: order.OrderedBy,
								orderedDate: order.OrderedDate,
						  }))
						: [],
			  }))
			: [],
		procedureDocumentation: document.ProcedureDocumentations
			? mapProcedureDocumentationResponseToFields(document.ProcedureDocumentations)
			: [],
	};
};

export const mapApiQaNotes = (notes: IApiQANote[]): IQANote[] => {
	return notes.map((x) => ({
		id: x.Id,
		note: x.Note,
		createdDateTime: x.CreatedDateTime,
		username: x.Username,
		audioTimeMarker: parseFloat(x.AudioTimeMarker),
		isDone: x.IsDone,
		originalNote: x.Note,
		originalIsDone: x.IsDone,
	}));
};

export const mapApiDictation = (x: IProviderDictationResponse): IDictation => {
	return {
		patientID: x.PatientID,
		appointmentID: x.AppointmentID,
		appointmentDateTime: x.AppointmentDateTime,
		patientFirstName: x.PatientFirstName,
		patientLastName: x.PatientLastName,
		documentID: x.DocumentID,
		documentType: x.DocumentType,
		blobID: x.BlobID,
		blobUrl: x.BlobUrl,
		careProviderName: x.CareProviderName,
		providerUserInfoID: x.ProviderUserInfoID,
		documentSaveStatus: x.DocumentSaveStatus,
		createdDateTime: x.CreatedDateTime,
		isCheckedOut: x.IsCheckedOut,
		markedForQA: x.MarkedForQA,
		isHeld: x.isHeld,
		checkedOutBy: x.CheckedOutBy,
		stat: !!x.STAT,
		documentStatus: x.DocumentStatus || '',
	};
};
