import { createReducer } from '@reduxjs/toolkit';
import { userActions } from './user.actions';
import { IUserEHRVerification, IUserSettings } from '../../models/users/user.models';

export interface IUserState {
	settings: IUserSettings;
	ehrVerification: IUserEHRVerification;
	providersAreEditable: boolean;
}

const initialState: IUserState = {
	settings: {
		shortname: '',
		username: '',
		displayName: '',
		customerName: '',
		email: '',
		apiKey: '',
		userInfoId: '',
		iScribeUsername: '',
		intercomUserHash: '',
		customerId: '',
		departmentId: '',
		featureFlags: {
			viewBillingServices: {
				enabled: false,
			},
			editBillingServices: {
				enabled: false,
			},
			editProcedureDocumentation: {
				enabled: false,
			},
			saveOrderInterpretations: {
				enabled: false,
			},
			vssForceAllJobsToQA: {
				enabled: false,
			},
			ehrLogin: {
				enabled: false,
			},
			retrieveOpenAISummary: {
				enabled: false,
			},
			openAIBypassVSS: {
				enabled: false,
			},
			transcription: {
				enabled: false,
			},
			speechToText: {
				enabled: false,
			},
			useNewSupportOption: {
				enabled: false,
			},
		},
		permissions: {
			admin: false,
			qa: false,
			aiAccess: false,
			tenantAccess: false,
			allCustomers: false,
			downloadNotes: false,
			aiPreferences: false,
		},
		userCustomerPermissions: [],
		system: null,
	},
	ehrVerification: {
		loading: false,
		daysUntilExpiration: null,
		error: null,
	},
	providersAreEditable: false,
};

const userReducer = createReducer(initialState, (builder) => {
	builder
		.addCase(userActions.setUserSettingsAction, (state, action) => {
			state.settings = action.payload;
		})
		.addCase(userActions.clearUserSettingsAction, (state) => {
			state.settings = initialState.settings;
		})
		.addCase(userActions.setVerifyEhrLoadingAction, (state, action) => {
			state.ehrVerification.loading = action.payload;
		})
		.addCase(userActions.setVerifyEhrResultAction, (state, action) => {
			state.ehrVerification.error = action.payload.error;
			state.ehrVerification.daysUntilExpiration = action.payload.daysUntilExpiration;
		})
		.addCase(userActions.setProvidersAreEditableAction, (state, action) => {
			state.providersAreEditable = action.payload;
		});
});

export default userReducer;
