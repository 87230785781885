import * as React from 'react';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Box, Button, Grid, useMediaQuery } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { usePrompt } from 'react-router-dom';
import dayjs from 'dayjs';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { Theme, useTheme } from '@mui/system';
import { debounce } from 'lodash';
import LoadingButton from '@mui/lab/LoadingButton';
import SelectEncounters from '../../components/encounters/SelectEncounters';
import SearchPatient from '../../components/search-patient/SearchPatient';
import {
	ICustomPatientDemographics,
	ICustomPatientDemographicsData,
	IPatientDemographic,
	IPatientEncounter,
} from '../../core/models/patients/patients.models';
import {
	IBillingCode,
	IBillingModifier,
	IDiagnoseField,
	IDiagnoseFields,
	IDictation,
	IOpenAISummary,
	IOrderField,
	IProcedureCode,
	IProcedureDocumentationField,
	IProcedureDocumentationTemplate,
	IQANote,
	ITimestampedTranscription,
	IUnrankedDiagnoseField,
} from '../../core/models/dictations/dictations.models';
import Spinner from '../../components/spinner/Spinner';
import { IDiagnoseError, IDiagnosis } from '../../components/diagnosis/Diagnosis';
import { namespaces } from '../../application/i18n.constants';
import ConfirmActionDialog, {
	IConfirmActionDialogProps,
} from '../../components/confirm-action-dialog/ConfirmActionDialog';
import {
	addDiagnoseToFields,
	canJobHaveEncounters,
	createDiagnose,
	filterEligibleEncounters,
	getICD10Codes,
	isVisit,
	mapDocumentToFields,
} from '../../core/services/documents/documents.services';
import { environment } from '../../environment/environment';
import {
	CodedErrorType,
	IDocumentDetails,
	IDocumentSaveDataError,
	IPreviewDocument,
} from '../../core/models/dictations/document.models';
import { IFeatureFlags, IScriberPermissions } from '../../core/models/users/user.models';
import { DiagnoseTextFieldType } from '../../components/diagnosis/DiagnosisField';
import Loading from '../../components/spinner/Loading';
import DictationSideBar, { IDictationSideBar } from '../../components/dictation/dictation-side-bar/DictationSideBar';
import DictationSaveMenu, { ISaveOptions } from '../../components/dictation/dictation-save-menu/DictationSaveMenu';
import DictationPageHeader from '../../components/dictation/DictationPageHeader';
import { sortNotes } from '../../core/services/qaNotes/qa-notes.services';
import { IMacro } from '../../core/models/macros/macros.models';
import ExpandableMenu, { IExpandableMenuItem } from '../../components/expandable-menu/ExpandableMenu';
import DocumentPreview from '../../components/documentPreview/DocumentPreview';
import OpenAiSummary, {
	SummaryFieldDataType,
	SummaryFieldType,
} from '../../components/transcription/openai-summary/OpenAiSummary';
import { addBillingServices } from '../../core/services/billingCodes/billing-codes.services';
import { EHRType } from '../../core/models/ehr.models';
import EhrDiagnosis from '../../components/diagnosis/EhrDiagnosis';
import PageContainer from '../../components/page-container/PageContainer';
import { DictationSidebarTabType } from '../../components/dictation/dictation-sidebar-tabs/DictationSidebarTabs';
import { AIModelType } from '../../core/api/dictations/dictations.api.models';
import UpdatePatientDemographicsDialog from '../../components/patient-demographics-dialog/UpdatePatientDemographicsDialog';
import NotePdf, { getNotePatientDemographics, handleDownloadPdf } from '../../components/pdfTemplates/NotePdf';

const { ENV } = environment;

interface IDictationPageProps {
	ehr: EHRType;
	edited: boolean;
	username: string;
	permissions: IScriberPermissions;
	featureFlags: IFeatureFlags;
	onCloseDictation: () => void;
	onEditedChange: (isEdited: boolean) => void;
	isDocumentLoading: boolean;
	saveErrors?: CodedErrorType[];
	transcription: {
		azureASR: { text: string | null; timestampedText: ITimestampedTranscription[] | null };
		mModalASR: string | null;
		nuanceASR: string | null;
		openAISummary: string | null;
		openAISummaryStructured: IOpenAISummary | null;
		customOpenAISummary: string | null;
		customOpenAISummaryStructured: IOpenAISummary | null;
	};
	document?: IDocumentDetails;
	dictationStat?: boolean;
	dictation?: IDictation;
	patientDocuments?: IPatientEncounter[];
	patientDocumentsIsLoading: boolean;
	patientDemographicIsLoading: boolean;
	patientDemographic?: IPatientDemographic;
	currentEncounterIsLoading: boolean;
	currentEncounter?: IPatientEncounter;
	previewDocumentIsLoading: boolean;
	previewDocument?: IPreviewDocument;
	onPreviewDocumentSelect: ({
		documentId,
		documentPatientId,
		documentTypeName,
	}: {
		documentId: string;
		documentPatientId: string;
		documentTypeName: string;
	}) => void;
	isDocumentPullLoading: boolean;
	pulledDiagnoses: IDiagnoseField[] | null;
	onPullDocumentField: (fieldId: string, documentId: string) => void;
	isDocumentSaveLoading: boolean;
	onApplyOrderSet: (orderSet: { orderSetId: number; diagnoseHash?: string }) => void;
	onFieldsUpdate: (fields: IDiagnoseFields) => void;
	onDiagnosisRankingChanged: () => void;
	onSaveFields: (options: {
		markTranscriptionComplete: boolean;
		sendToQA: boolean;
		forceSave: boolean;
		forceToQA: boolean;
	}) => void;
	onMarkAsComplete: () => void;
	onSelectPatient: (patientID: string) => void;
	onSelectPatientEncounter: (encounter: IPatientEncounter) => void;
	unassignedOrders: IOrderField[];
	onUnassignedOrdersChange: (orders: IOrderField[]) => void;
	onCloseDiagnosesPull: () => void;
	onMergePulledAssessmentPlan: (diagnoses: IDiagnoseField[]) => void;
	saveProcedureDocumentationIsLoading: boolean;
	applyProcedureTemplateIsLoading: boolean;
	onApplyProcedureDocumentationTemplate: (template: IProcedureDocumentationTemplate) => void;
	onSaveProcedureDocumentations: (procedureDocumentation: IProcedureDocumentationField[]) => void;
	billingLoaded: boolean;
	billingClaimCreated: boolean;
	billingCodes: IBillingCode[];
	billingModifiersLoading: boolean;
	billingModifiers: IBillingModifier[];
	codeSearchIsLoading: boolean;
	procedureCodeSearchResults: IProcedureCode[];
	onBillingCodesChange: (codes: IBillingCode[]) => void;
	qaNotes: IQANote[];
	onQANotesChange: (notes: IQANote[]) => void;
	providerNote: string;
	onChangeProviderNote: (providerNote: string) => void;
	customPatientDemographics: ICustomPatientDemographicsData | null;
	onUpdateCustomPatientDemographics: (data: ICustomPatientDemographicsData) => void;
	aiPrompt?: {
		prompt: string;
		instructions: string;
		canUpdate: boolean;
		onChangePrompt: (aiPrompt: string) => void;
		onChangeInstructions: (aiInstructions: string) => void;
		lastTimePromptSubmitted: number | null;
		onPromptSubmit: (aiModel: AIModelType | null, prompt: { prompt: string; instructions: string }) => void;
	};
	providerDotPhrases: IMacro[];
	settings: {
		viewBillingServicesEnabled: boolean;
		editBillingServicesEnabled: boolean;
		editProcedureDocumentationEnabled: boolean;
		saveOrderInterpretationsEnabled: boolean;
	};
}

function DictationPage({
	ehr,
	edited,
	onCloseDictation,
	onEditedChange,
	isDocumentLoading,
	saveErrors,
	document,
	permissions,
	featureFlags,
	transcription,
	username,
	patientDemographic,
	patientDemographicIsLoading,
	patientDocumentsIsLoading,
	patientDocuments,
	currentEncounterIsLoading,
	currentEncounter,
	previewDocumentIsLoading,
	previewDocument,
	onPreviewDocumentSelect,
	dictationStat,
	dictation,
	isDocumentPullLoading,
	pulledDiagnoses,
	onPullDocumentField,
	isDocumentSaveLoading,
	onApplyOrderSet,
	onFieldsUpdate,
	onDiagnosisRankingChanged,
	onBillingCodesChange,
	onSaveFields,
	onMarkAsComplete,
	onSelectPatient,
	onSelectPatientEncounter,
	unassignedOrders,
	onUnassignedOrdersChange,
	onCloseDiagnosesPull,
	onMergePulledAssessmentPlan,
	saveProcedureDocumentationIsLoading,
	applyProcedureTemplateIsLoading,
	onApplyProcedureDocumentationTemplate,
	onSaveProcedureDocumentations,
	billingLoaded,
	billingClaimCreated,
	billingCodes: billingServices,
	billingModifiersLoading,
	billingModifiers,
	codeSearchIsLoading,
	procedureCodeSearchResults,
	qaNotes,
	onQANotesChange,
	providerNote,
	onChangeProviderNote,
	customPatientDemographics,
	onUpdateCustomPatientDemographics,
	providerDotPhrases,
	aiPrompt,
	settings,
}: IDictationPageProps) {
	const { t } = useTranslation(namespaces.pages.dictation);

	const theme = useTheme();
	const smallScreen = useMediaQuery((th: Theme) => th.breakpoints.down('sm'));

	const dictationSideBarRef = useRef<IDictationSideBar>(null);
	const diagnosisRef = useRef<IDiagnosis>(null);

	const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

	const fieldsRef = useRef<IDiagnoseFields | undefined>();
	const fields = useMemo(() => {
		const result = document ? mapDocumentToFields(document) : document;
		fieldsRef.current = result;
		return result;
	}, [document]);
	const diagnoses = useMemo(() => (fields ? fields.diagnoses : []), [JSON.stringify(fields?.diagnoses)]);
	const icd10Codes: string[] = useMemo(() => getICD10Codes(diagnoses), [diagnoses]);

	const billingCodesRef = useRef<IBillingCode[]>();
	const billingCodes = useMemo(() => {
		const result = billingServices;
		billingCodesRef.current = result;
		return result;
	}, [billingServices]);

	const [qaNotesIsEditing, setQANotesIsEditing] = React.useState(false);

	const [isUpdatePatientDemographicsOpen, setIsUpdatePatientDemographicsOpen] = React.useState(false);
	const [isSelectEncounterOpen, setIsSelectEncounterOpen] = React.useState(false);
	const [isSearchPatientOpen, setIsSearchPatientOpen] = React.useState(false);

	const [confirmDialogOpen, setConfirmDialogOpen] = useState<boolean>(false);
	const [confirmSaveProps, setConfirmSaveProps] = useState<Omit<IConfirmActionDialogProps, 'open'> | null>(null);

	const [qaNotesErrorDialogOpen, setQANotesErrorDialogOpen] = useState(false);
	const [qaNotesErrorProps, setQANotesErrorProps] = useState<Omit<IConfirmActionDialogProps, 'open'> | null>(null);

	const [lastSelectedTextField, setLastSelectedTextField] = useState<DiagnoseTextFieldType | null>(null);
	const lastSelectedTextFieldRef = useRef(lastSelectedTextField);
	const [qaNotePostFocusField, setQaNotePostFocusField] = useState<DiagnoseTextFieldType | null>(null);

	const [confirmSelectEncounterProps, setConfirmSelectEncounterProps] = useState<Omit<
		IConfirmActionDialogProps,
		'open'
	> | null>(null);

	const [isResettingPatient, setIsResettingPatient] = useState<boolean>(false);
	const [confirmSelectPatientProps, setConfirmSelectPatientProps] = useState<Omit<
		IConfirmActionDialogProps,
		'open'
	> | null>(null);

	const debounceCancel = debounce(onCloseDictation, 500);

	const visits = filterEligibleEncounters(ehr, patientDocuments);

	const canHaveEncounters = canJobHaveEncounters(dictation?.documentType || '', ehr);

	usePrompt(t('promptLeavePage'), edited && !isDocumentSaveLoading);

	useEffect(() => {
		if (!currentEncounter && patientDocuments && canHaveEncounters) {
			setIsSelectEncounterOpen(true);
		}
	}, [patientDocuments]);

	const hasAiSummary = () => transcription.openAISummaryStructured || transcription.openAISummary;
	const hasCustomAiSummary = () => transcription.customOpenAISummaryStructured || transcription.customOpenAISummary;

	const menuOpen = Boolean(anchorEl);
	const documentWithEncounterLoaded = document && currentEncounter;

	const onMenuClick = (event: React.MouseEvent<HTMLButtonElement>) => {
		setAnchorEl(event.currentTarget);
	};
	const onMenuClose = () => {
		setAnchorEl(null);
	};

	const onSave = (options: ISaveOptions) => {
		onSaveFields({
			markTranscriptionComplete: !!options.markAsComplete,
			sendToQA: !!options.sendToQA,
			forceSave: !!options.forceSave,
			forceToQA: !!options.forceToQA,
		});
		onEditedChange(false);
		setConfirmDialogOpen(false);
		setConfirmSaveProps(null);
	};

	const onDocumentSave = (options: ISaveOptions) => {
		onMenuClose();
		if (unassignedOrders.length > 0) {
			setConfirmSaveProps({
				title: t('areYouSureSave'),
				text:
					unassignedOrders.length === 1
						? t('ordersCantBeSaved')
						: t('orderCantBeSaved', { order: unassignedOrders[0].description }),
				cancel: {
					action: () => {},
					text: t('cancel'),
				},
				actions: [
					{
						action: () => onSave(options),
						text: t('saveAnyway'),
					},
				],
			});
			setConfirmDialogOpen(true);
		} else if (options.sendToQA && qaNotes.length === 0 && !options.forceToQA) {
			setQANotesErrorProps({
				title: t('qaNotesError'),
				text: t('qaNotesCantBeSaved'),
				cancel: {
					action: () => {},
					text: t('ok'),
				},
			});
			setQANotesErrorDialogOpen(true);
		} else {
			onSave(options);
		}
	};

	const handleCloseSelectEncounter = () => {
		setIsSelectEncounterOpen(false);
	};

	const onDiagnosisFieldsUpdate = (documentFields: IDiagnoseFields) => {
		onFieldsUpdate(documentFields);
	};

	const onPullDocument = (fieldId: string, encounter: IPatientEncounter) => {
		onPullDocumentField(fieldId, encounter.documentID);
	};

	const handleSelectPatientEncounter = (encounter: IPatientEncounter) => {
		if (currentEncounter) {
			handleCloseSelectEncounter();
			setConfirmSelectEncounterProps({
				title: t('areYouSureChangeEncounter'),
				text: t('changingEncounterDisclaimer'),
				cancel: {
					action: () => {},
					text: t('cancel'),
				},
				actions: [
					{
						action: () => {
							onSelectPatientEncounter(encounter);
							setConfirmSelectEncounterProps(null);
						},
						text: t('ok'),
					},
				],
			});
		} else {
			onSelectPatientEncounter(encounter);
			handleCloseSelectEncounter();
		}
	};

	const handleBillingCodesChange = (codes: IBillingCode[]) => {
		onBillingCodesChange(codes);
	};

	const handleSelectPatient = (patientID: string) => {
		setIsSearchPatientOpen(false);

		if (isResettingPatient && dictation?.patientID) {
			setConfirmSelectPatientProps({
				title: t('areYouSureChangePatient'),
				text: t('changingPatientDisclaimer'),
				cancel: {
					action: () => {},
					text: t('cancel'),
				},
				actions: [
					{
						action: () => {
							onSelectPatient(patientID);
							setIsResettingPatient(false);
							setConfirmSelectPatientProps(null);
						},
						text: t('ok'),
					},
				],
			});
		} else {
			onSelectPatient(patientID);
		}
	};

	const handleOnPatientClick = (reset: boolean) => {
		setIsSearchPatientOpen(true);
		setIsResettingPatient(reset);
	};

	const handleCloseSearchPatient = () => {
		setIsSearchPatientOpen(false);
	};

	const onTextFieldFocusChanged = (
		event: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>,
		fieldId: DiagnoseTextFieldType,
		focus: boolean
	) => {
		if (focus) {
			setLastSelectedTextField(fieldId);
		} else if (lastSelectedTextField === fieldId) {
			setLastSelectedTextField(null);
		}
	};

	const filterNotes = (notes: IQANote[]) => notes.filter((x) => x.id || (x.note && x.note.length > 0));

	const handleQANotesChange = (notes: IQANote[]) => {
		onQANotesChange(notes);
	};

	const handleQANoteAdd = () => {
		const audioTimeMarker: number = dictationSideBarRef.current?.audioPlayer.getTime() || 0;
		const newNote: IQANote = {
			note: '',
			originalNote: '',
			createdDateTime: dayjs().format(),
			audioTimeMarker,
			username,
			isDone: false,
			originalIsDone: false,
		};
		handleQANotesChange([...filterNotes(qaNotes), newNote]);
	};

	const diagnoseErrors: IDiagnoseError[] | undefined = saveErrors
		? (saveErrors
				.filter((x) => x.code === 'diagnoseLateralityRequired')
				.map((x) => {
					if (x.code === 'diagnoseLateralityRequired') {
						const error = x as IDocumentSaveDataError<{ SNOMED: string }>;
						return {
							field: 'diagnose',
							snomed: error.data.SNOMED,
							type: 'lateralityRequired',
						};
					}
					return null;
				})
				.filter((x) => x !== null) as IDiagnoseError[])
		: undefined;

	useEffect(() => {
		if (!qaNotesIsEditing) {
			handleQANotesChange([...sortNotes(filterNotes(qaNotes))]);
		}
	}, [qaNotesIsEditing]);

	const handleQANoteSubmit = () => {
		if (qaNotePostFocusField && diagnosisRef?.current) {
			diagnosisRef.current.focusTextField(qaNotePostFocusField);
			setQaNotePostFocusField(null);
		}
	};

	const handleChangeEncounterOpen = () => {
		setIsSelectEncounterOpen(true);
	};

	const onKeyDown = (event: KeyboardEvent) => {
		if (!event) {
			return;
		}
		const latestFiled = lastSelectedTextFieldRef.current;

		if (event.ctrlKey && event.code === 'KeyQ') {
			if (latestFiled) {
				setQaNotePostFocusField(latestFiled);
			}
			if (event) {
				event.preventDefault();
				if (dictationSideBarRef.current?.sidebar) {
					dictationSideBarRef.current.sidebar.addQaNote();
				}
			}
		}
	};

	useEffect(() => {
		lastSelectedTextFieldRef.current = lastSelectedTextField;
	}, [lastSelectedTextField]);

	useEffect(() => {
		window.addEventListener('keydown', onKeyDown, false);

		return () => {
			window.removeEventListener('keydown', onKeyDown, false);
		};
	}, []);

	const updatePatientDemographics = (data: ICustomPatientDemographics) => {
		const pdmg: ICustomPatientDemographicsData = {
			...customPatientDemographics,
			PatientDemographics: {
				...customPatientDemographics?.PatientDemographics,
				PatientFirstName: data.patientFirstName,
				PatientLastName: data.patientLastName,
				MRN: data.mrn,
				DateOfBirth: data.dateOfBirth,
			},
			Version: '1',
			Edited: true,
		};
		onUpdateCustomPatientDemographics(pdmg);
	};

	const getAppendResult = useCallback(
		(
			diagFields: IDiagnoseFields,
			fieldId: SummaryFieldType,
			data: SummaryFieldDataType
		):
			| { text?: string; diagnoses?: undefined }
			| { text?: undefined; diagnoses: IUnrankedDiagnoseField[] }
			| undefined => {
			if (fieldId === 'diagnoses') {
				return {
					diagnoses: data.diagnoses
						? data.diagnoses.map((x) => {
								const newDiagnose = createDiagnose({
									icd10Code: x.ICD10Code,
									snomedCode: x.SNOMEDCode,
									caption: x.Description,
								});
								return { ...newDiagnose, modified: 1 };
						  })
						: [],
				};
			}
			if (fieldId === 'billing') {
				if (data.text && data.text.length > 0) {
					return { text: data.text };
				}
				return undefined;
			}

			const currentFieldValue = diagFields[fieldId] as string | undefined;

			const result =
				// eslint-disable-next-line no-nested-ternary
				!!data?.text && data.text.length > 0
					? currentFieldValue && currentFieldValue.length > 0 && /\S/.test(currentFieldValue)
						? `${currentFieldValue}\n\n${data.text}`
						: data.text
					: currentFieldValue;
			return { text: result };
		},
		[]
	);

	const onAppendAll = useCallback(
		(data: {
			[fieldId in SummaryFieldType]?: SummaryFieldDataType;
		}) => {
			if (!fieldsRef.current || !billingCodesRef?.current) {
				return;
			}

			let documentFields = { ...fieldsRef.current };
			Object.keys(data).forEach((x) => {
				const fieldId = x as SummaryFieldType;
				const appendResult = getAppendResult(documentFields, fieldId, data[fieldId] as SummaryFieldDataType);

				if (appendResult) {
					if (fieldId === 'billing') {
						if (appendResult?.text) {
							handleBillingCodesChange(
								addBillingServices(billingCodesRef.current || [], icd10Codes, appendResult?.text)
							);
						}
					}
					if (fieldId === 'diagnoses') {
						if (appendResult?.diagnoses) {
							appendResult.diagnoses.forEach((diag) => {
								documentFields = addDiagnoseToFields(documentFields, diag);
							});
						}
					} else {
						documentFields = {
							...documentFields,
							[fieldId]: appendResult?.text,
						};
					}
				}
			});

			onDiagnosisFieldsUpdate(documentFields);
		},
		[fieldsRef, billingCodesRef, icd10Codes]
	);

	const expandableMenuItems = useMemo(() => {
		const items: IExpandableMenuItem[] = [];

		const splitAISummary = hasCustomAiSummary();

		if (hasAiSummary() || hasCustomAiSummary()) {
			items.push({
				title: 'summary',
				Item: (
					<Box sx={{ height: '100%', overflowY: 'hidden' }} display="flex" flexDirection="row">
						{hasAiSummary() && (
							<OpenAiSummary
								ehr={ehr}
								summaryTitle={splitAISummary ? 'Default' : undefined}
								addDisabled={!documentWithEncounterLoaded}
								onAppend={onAppendAll}
								selectedDiagnoses={diagnoses.filter((x) => x.modified !== 2)}
								summary={transcription.openAISummaryStructured}
								text={transcription.openAISummary}
							/>
						)}
						{hasCustomAiSummary() && (
							<OpenAiSummary
								ehr={ehr}
								summaryTitle={splitAISummary ? 'Custom Prompt' : undefined}
								addDisabled={!documentWithEncounterLoaded}
								onAppend={onAppendAll}
								selectedDiagnoses={diagnoses.filter((x) => x.modified !== 2)}
								summary={transcription.customOpenAISummaryStructured}
								text={transcription.customOpenAISummary}
							/>
						)}
					</Box>
				),
			});
		}

		if (patientDocuments) {
			const documents = patientDocuments.filter((x) => !isVisit(x.documentTypeName, ehr));
			if (documents.length) {
				items.push({
					title: 'documents',
					Item: (
						<DocumentPreview
							documents={documents}
							document={previewDocument || null}
							documentIsLoading={previewDocumentIsLoading}
							onDocumentSelect={onPreviewDocumentSelect}
						/>
					),
				});
			}
		}

		return items;
	}, [
		ehr,
		// SUMMARY
		documentWithEncounterLoaded,
		transcription.openAISummary,
		transcription.openAISummaryStructured,
		transcription.customOpenAISummary,
		transcription.customOpenAISummaryStructured,
		onAppendAll,
		diagnoses,
		// DOCUMENTS
		patientDocuments,
		onPreviewDocumentSelect,
		previewDocument,
		previewDocumentIsLoading,
	]);

	const canDownloadPdf = useMemo(
		() =>
			permissions.downloadNotes &&
			(transcription.customOpenAISummaryStructured || transcription.openAISummaryStructured),
		[permissions.downloadNotes, transcription.customOpenAISummaryStructured, transcription.openAISummaryStructured]
	);

	const downloadPdf = useCallback(
		() =>
			handleDownloadPdf(
				<NotePdf
					note={transcription.customOpenAISummaryStructured || transcription.openAISummaryStructured}
					patientDemographics={getNotePatientDemographics(patientDemographic, customPatientDemographics, dictation)}
					text={null}
				/>,
				document?.DocumentID ? `${document.DocumentID}` : ''
			),
		[
			transcription.customOpenAISummaryStructured,
			transcription.openAISummaryStructured,
			patientDemographic,
			customPatientDemographics,
			dictation,
			document,
		]
	);

	const headerHeight = 8;
	const minPageHeight = `calc(100vh - ${theme.spacing(headerHeight)})`;

	const sideBarSize = {
		sm: 5,
		md: qaNotesIsEditing ? 6 : 5,
		lg: qaNotesIsEditing ? 4 : 3,
	};

	const border = !smallScreen ? '0.1rem solid darkgray' : 0;

	return (
		<PageContainer navbar={false} disablePadding sx={{ display: 'flex', flexDirection: 'column' }}>
			<DictationPageHeader>
				<Button variant="contained" disableElevation onClick={debounceCancel}>
					{t('cancel')}
				</Button>
				<Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100%' }}>
					{canDownloadPdf ? (
						<LoadingButton
							sx={{
								color: 'white',
							}}
							color="primary"
							onClick={downloadPdf}
						>
							{t('download')}
						</LoadingButton>
					) : null}
					<Box>
						{dictation && (document || !canHaveEncounters) && (
							<DictationSaveMenu
								renderButton={() =>
									!isDocumentSaveLoading ? (
										<Button sx={{ pr: 0 }} variant="contained" disableElevation onClick={onMenuClick}>
											{t('saveAndSend')}
											<ArrowDropDownIcon sx={{ fontSize: '2rem' }} />
										</Button>
									) : (
										<Spinner color="secondary" />
									)
								}
								anchorEl={anchorEl}
								open={menuOpen}
								canCheckin={canHaveEncounters}
								canMarkAsComplete={!canHaveEncounters}
								onMarkAsComplete={onMarkAsComplete}
								forceToQA={featureFlags.vssForceAllJobsToQA.enabled}
								canSave={ENV === 'dev'}
								canSendToQa={!dictation.markedForQA}
								canHold
								onClose={onMenuClose}
								canSend={!qaNotes.filter((x) => !x.isDone).length}
								onSave={(options: ISaveOptions) => onDocumentSave(options)}
							/>
						)}
					</Box>
				</Box>
			</DictationPageHeader>
			<Box>
				<Box>
					<Grid container direction="row">
						{dictation ? (
							<Grid
								item
								// eslint-disable-next-line react/jsx-props-no-spreading
								{...sideBarSize}
								justifyContent="center"
								sx={{
									flex: 1,
									minHeight: minPageHeight,
									borderRight: border,
									transition: (th) => th.transitions.create('all', { easing: th.transitions.easing.easeIn }),
								}}
							>
								<DictationSideBar
									ref={dictationSideBarRef}
									containerSx={{
										display: 'flex',
										flex: 1,
										flexDirection: 'column',
										maxHeight: minPageHeight,
										transition: (th) => th.transitions.create('all', { easing: th.transitions.easing.easeIn }),
									}}
									audioUrl={dictation.blobUrl}
									patientId={dictation.patientID}
									isEncountersLoading={patientDocumentsIsLoading}
									isPatientLoading={patientDemographicIsLoading || currentEncounterIsLoading || !patientDemographic}
									onChangeEncounter={handleChangeEncounterOpen}
									disableEncounter={!canHaveEncounters}
									changeEncounterDisabled={
										isDocumentLoading || currentEncounterIsLoading || (patientDocuments ? visits.length === 0 : true)
									}
									changePatientDisabled={isDocumentLoading || currentEncounterIsLoading}
									patient={{
										demographic: patientDemographic,
										encounter: currentEncounter,
										stat: !!dictationStat,
										customDemographics: customPatientDemographics || undefined,
										integratedPatient: ehr !== EHRType.NonIntegrated,
									}}
									onPatientSelect={handleOnPatientClick}
									onPatientUpdate={() => setIsUpdatePatientDemographicsOpen(true)}
									sidebar={{
										qaNotes,
										disabledTabs: canHaveEncounters
											? []
											: [DictationSidebarTabType.PROVIDER_NOTE, DictationSidebarTabType.QA_NOTES],
										disableAddNewNote: !documentWithEncounterLoaded,
										canEditNoteIsDone: permissions.qa,
										qaNotesIsEditing,
										handleQANoteIsEditing: setQANotesIsEditing,
										handleQANoteAdd,
										handleQANotesChange,
										handleQANoteSubmit,
										transcription: {
											azure: transcription.azureASR,
											mModal: transcription.mModalASR,
											nuance: transcription.nuanceASR,
										},
										providerNote,
										onChangeProviderNote,
										aiPrompt,
									}}
								/>
							</Grid>
						) : null}
						<Grid
							item
							sm={7}
							md={qaNotesIsEditing ? 6 : 7}
							lg={qaNotesIsEditing ? 8 : 9}
							sx={{
								transition: (thm) => thm.transitions.create('all', { easing: thm.transitions.easing.easeIn }),
							}}
						>
							<Box
								sx={{
									minHeight: minPageHeight,
									maxHeight: minPageHeight,
									display: 'flex',
									flexDirection: 'row',
									flex: 1,
								}}
							>
								<ExpandableMenu
									flex={hasCustomAiSummary() ? 2 : 1}
									containerSx={{
										display: 'flex',
										overflowY: 'auto',
									}}
									childSx={{
										borderLeft: border,
									}}
									items={expandableMenuItems}
									initOpen="summary"
									disableCollapse={!documentWithEncounterLoaded}
								/>
								{(documentWithEncounterLoaded || isDocumentLoading) && (
									<Box
										sx={{
											minHeight: minPageHeight,
											flex: 1,
											overflowY: 'auto',
											borderLeft: border,
											px: 2,
											pt: 2,
										}}
									>
										{documentWithEncounterLoaded ? (
											<EhrDiagnosis
												ref={diagnosisRef}
												ehr={ehr}
												encounters={(patientDocuments || [])
													.filter((x) => isVisit(x.documentTypeName, ehr))
													.filter((x) => document?.DocumentID && x.documentID !== document.DocumentID.toString())}
												encountersIsLoading={patientDocumentsIsLoading}
												currentEncounter={currentEncounter}
												fields={fields as IDiagnoseFields}
												diagnoseErrors={diagnoseErrors}
												onChange={onDiagnosisFieldsUpdate}
												onApplyOrderSet={onApplyOrderSet}
												isPullLoading={isDocumentPullLoading}
												onPull={onPullDocument}
												pulledDiagnoses={pulledDiagnoses}
												onCloseDiagnosesPull={onCloseDiagnosesPull}
												onMergePulledAssessmentPlan={onMergePulledAssessmentPlan}
												unassignedOrders={unassignedOrders}
												onUnassignedOrdersChange={onUnassignedOrdersChange}
												saveProcedureDocumentationIsLoading={saveProcedureDocumentationIsLoading}
												applyProcedureTemplateIsLoading={applyProcedureTemplateIsLoading}
												onApplyProcedureDocumentationTemplate={onApplyProcedureDocumentationTemplate}
												onSaveProcedureDocumentations={onSaveProcedureDocumentations}
												billingLoaded={billingLoaded}
												billingClaimCreated={billingClaimCreated}
												billingCodes={billingCodes}
												handleBillingCodesChange={handleBillingCodesChange}
												billingModifiersLoading={billingModifiersLoading}
												billingModifiers={billingModifiers}
												codeSearchIsLoading={codeSearchIsLoading}
												procedureCodeSearchResults={procedureCodeSearchResults}
												onTextFieldFocusChanged={onTextFieldFocusChanged}
												providerDotPhrases={providerDotPhrases}
												settings={settings}
												onDiagnosisRankingChanged={onDiagnosisRankingChanged}
											/>
										) : (
											isDocumentLoading && <Loading sx={{ height: '100%' }} />
										)}
									</Box>
								)}
							</Box>
						</Grid>
					</Grid>
					{isSearchPatientOpen && (
						<SearchPatient
							open={isSearchPatientOpen}
							onClose={handleCloseSearchPatient}
							isLoading={false}
							onSelectPatient={handleSelectPatient}
						/>
					)}
					{patientDocuments && !isDocumentLoading && (
						<SelectEncounters
							onClose={handleCloseSelectEncounter}
							isLoading={false}
							selectedId={currentEncounter?.documentID}
							encounters={visits}
							onSelect={handleSelectPatientEncounter}
							open={isSelectEncounterOpen}
						/>
					)}
					{confirmSaveProps && (
						<ConfirmActionDialog
							open={confirmDialogOpen}
							title={confirmSaveProps.title}
							text={confirmSaveProps.text}
							actions={confirmSaveProps.actions}
							cancel={{
								action: () => {
									setConfirmDialogOpen(false);
									setConfirmSaveProps(null);
								},
								text: confirmSaveProps.cancel.text,
							}}
						/>
					)}
					{qaNotesErrorProps && (
						<ConfirmActionDialog
							open={qaNotesErrorDialogOpen}
							title={qaNotesErrorProps.title}
							text={qaNotesErrorProps.text}
							actions={qaNotesErrorProps.actions}
							cancel={{
								action: () => {
									setQANotesErrorDialogOpen(false);
									setQANotesErrorProps(null);
								},
								text: qaNotesErrorProps.cancel.text,
							}}
						/>
					)}
					{confirmSelectEncounterProps && (
						<ConfirmActionDialog
							open
							title={confirmSelectEncounterProps.title}
							text={confirmSelectEncounterProps.text}
							actions={confirmSelectEncounterProps.actions}
							cancel={{
								action: () => {
									setConfirmSelectEncounterProps(null);
								},
								text: confirmSelectEncounterProps.cancel.text,
							}}
						/>
					)}
					{confirmSelectPatientProps && (
						<ConfirmActionDialog
							open
							title={confirmSelectPatientProps.title}
							text={confirmSelectPatientProps.text}
							actions={confirmSelectPatientProps.actions}
							cancel={{
								action: () => {
									setIsResettingPatient(false);
									setConfirmSelectPatientProps(null);
								},
								text: confirmSelectPatientProps.cancel.text,
							}}
						/>
					)}
					{isUpdatePatientDemographicsOpen && (
						<UpdatePatientDemographicsDialog
							isLoading={false}
							open={isUpdatePatientDemographicsOpen}
							action={{
								action: (data: ICustomPatientDemographics) => {
									setIsUpdatePatientDemographicsOpen(false);
									updatePatientDemographics(data);
								},
								text: 'update',
							}}
							data={{
								patientFirstName: customPatientDemographics?.PatientDemographics.PatientFirstName || '',
								patientLastName: customPatientDemographics?.PatientDemographics.PatientLastName || '',
								dateOfBirth: customPatientDemographics?.PatientDemographics.DateOfBirth || '',
								mrn: customPatientDemographics?.PatientDemographics.MRN || '',
							}}
							cancel={{
								action: () => {
									setIsUpdatePatientDemographicsOpen(false);
								},
								text: 'cancel',
							}}
						/>
					)}
				</Box>
			</Box>
		</PageContainer>
	);
}

export default DictationPage;
