import { IApiResponse } from '../api-service';
import { AnyObject } from '../../../shared/types';
import { IPatientCommunicationResult } from '../patients/patients.api.models';
import {
	IDiagnoseData,
	IDocProcedureDocumentationData,
	IDocumentDetails,
	IDocumentTemplateData,
	IEMCoding,
	IEncounterDocumentData,
	IOrderData,
	JobStatus,
} from '../../models/dictations/document.models';
import { IBillingCode, ITimestampedTranscription } from '../../models/dictations/dictations.models';

export interface IProvidersDictationResponse extends IApiResponse {
	DictatedDocumentsWorklistData: IProviderDictationResponse[];
}

export interface IProviderDictationResponse extends IApiResponse {
	PatientID: string;
	AppointmentID: string | null;
	AppointmentDateTime: string | null;
	PatientFirstName: string;
	PatientLastName: string;
	DocumentID: string;
	DocumentType: string;
	BlobID: string;
	BlobUrl: string;
	CareProviderName: string;
	ProviderUserInfoID: string | null;
	DocumentSaveStatus: string;
	CreatedDateTime: string;
	IsCheckedOut: boolean;
	CheckedOutBy: string;
	MarkedForQA: boolean;
	isHeld: boolean;
	STAT: boolean | null;
	DocumentStatus: JobStatus;
}

export interface IGetDocumentDetailsRequest {
	DocumentID: string;
	PullForwardSections?: {
		PullForwardToDocumentID: string;
		Goals: boolean;
		Assessment: boolean;
		Vitals: boolean;
		CC: boolean;
		ProcedureDocumentation: boolean;
		Instructions: boolean;
		Results: boolean;
		PE: boolean;
		ROS: boolean;
		AssessmentPlan: boolean;
		DiscussionNotes: boolean;
		Plan: boolean;
		HPI: boolean;
	};
	DocumentTypeName: string | null;
	DepartmentID: string | null;
	PatientID: string | null;
	SequenceNumber?: number;
	DeviceToken?: string;
}

export interface IEncounterResponse extends IApiResponse {
	IscribeDocument: {
		CreateByFullName: string;
		CreateDateTime: string;
		DocumentId: number;
		DocumentID: string;
		DocumentStatus: string;
		DocumentTypeName: string;
		DocumentTypeID: string;
		LastModifiedDateTime: string;
		RenderingProvider: {
			Firstname: string;
			LastName: string;
			CareProviderID: string;
			FullName: string;
		};
		SequenceNumber: number;
		Title: string;
		DictationStatus: string;
		PatientID: string;
		FileType: string;
		IScribeDocumentStatus: 'Signed' | 'Unsigned' | 'Saved';
		Stage: string;
		SaveStatus: string;
		DepartmentID: string;
		EncounterID: string;
	};
}

export interface IPreviewDocumentResponse extends IApiResponse {
	Baseimage64: string;
	ExportType: 'PDF';
}

export interface IDiagnosesResponse extends IDiagnoseData {}

export interface IOrderResponse extends IOrderData {}

export interface IDocProcedureDocumentationResponse extends IDocProcedureDocumentationData {}

export interface IDocProcedureDocumentationRequest extends IDocProcedureDocumentationResponse {}

export interface IGetDocumentDetailsResponse extends IDocumentDetails, IApiResponse {}

export interface IDocumentTemplate extends IDocumentTemplateData {}

export interface ISaveDocumentRequest {
	PatientID: string;
	DocumentId: string;
	EncounterID: string;
	BlobID: string;
	DocumentTypeID: number;
	DocumentName: string | null;
	OriginalHPIFreeText: string;
	MarkTranscriptionComplete: boolean;
	DocumentSaveStatus: string;
	PracticeLocationID: number;
	PatientGoals: string;
	EncounterWellChildRos: AnyObject[];
	Templates: IDocumentTemplate[];
	PESketchPad: string;
	UndiagnosedOrders: AnyObject[];
	EncounterWellChildRosTemplates: AnyObject[];
	RemoveHPITemplate: boolean;
	Medications: AnyObject[] | null;
	AssessmentPlanSketchPad: string | null;
	Vitals: AnyObject[] | null;
	HistoricalMedications: AnyObject[] | null;
	DiscussionNotes: string;
	CCSketchPad: string;
	ROSSketchPad: string;
	ASSSketchPad: string | null;
	PLANSketchPad: string | null;
	PESystems: AnyObject[] | null;
	PatientInstructions: string;
	Instructions: AnyObject[];
	EMCoding: IEMCoding | null;
	HPISketchPad: string;
	ProcedureDocumentations: IDocProcedureDocumentationResponse[];
	Diagnoses: IDiagnosesResponse[];
	DeviceToken: string | null;

	ReorderDiagnoses: boolean;
}

// export interface IDocumentEncounterData
// 	extends Omit<
// 		ISaveDocumentRequest,
// 		'DeviceToken' | 'MarkTranscriptionComplete' | 'BlobID' | 'DocumentSaveStatus' | 'RemoveHPITemplate'
// 	> {}

export interface IEncounterData {
	storeVersion: number;
	document: IEncounterDocumentData | null;
	reorderDiagnoses: boolean | null;
	billingClaimCreated: boolean | null;
	billing: IBillingCode[] | null;
}

export interface IApiQANote {
	Id?: number;
	AudioTimeMarker: string;
	Note: string;
	CreatedDateTime: string;
	Username: string;
	IsDone: boolean;
}

export interface IDocumentCheckInRequest {
	BlobID: string;
	MarkTranscriptionComplete: boolean;
	EncounterID: string;
	EncounterData: IEncounterData;
	PatientID: string;
	SendToQA: boolean;
	QANotes: IApiQANote[];
	ProviderNote: string;
}

export interface ICancelDocumentCheckOutRequest {
	BlobID: string;
}

export interface ISaveDocumentResponse extends IApiResponse {
	DocumentID: number;
	Error?: string;
	ValidationError?: string;
	CodedErrors?: string;
}

export interface IDocumentCheckInResponse extends IApiResponse {
	Error?: string;
	ValidationError?: string;
}

export interface IDocumentCheckOutRequest {
	blobID: string;
	encounterID: string | null;
}

export interface IRequestAISummaryRequest {
	documentID: string | null;
	patientID: string | null;
	appointmentID?: string | null;
	blobID: string;
	documentTypeID: string | null;
	firstName: string | null;
	lastName: string | null;
	dateOfBirth: string | null;
	gender: string | null;
	appointmentType: string | null;
	instructions: string | null;
	prompt: string;
	AIModel: AIModelType | null;
}

export enum AIModelType {
	GPT4 = 'MedicalGPT4',
	GPT4_32k = 'MedicalGPT4_32k',
	Claude_2 = 'Claude_2',
}

export interface IDocumentCheckOutResponse extends IApiResponse {
	EncounterData: string;
	QANotes: IApiQANote[];
	MModalASR: string | null;
	NuanceASR: string | null;
	AzureASR: string | null;
	Prompt: string | null;
	Instructions: string | null;
	AISummary: string | null;
	AISummaryStructured: string | null;
	PatientDemographics: string | null;
	TimestampedDictationResults: ITimestampedTranscription[] | null;
	ProviderNote: string | null;
}

export interface IDocumentCheckOutResult extends Omit<IDocumentCheckOutResponse, 'EncounterData'> {
	EncounterData: IEncounterData | null;
}

export interface IGetDocumentsByAppointmentRequest {
	PatientID: string;
	AppointmentID: string;
}

export interface IGetDocumentByAppointmentResponse {
	IscribeDocument: IPatientCommunicationResult;
	Success: boolean;
}

interface IDictatedDocumentsTrackingVersionResponse {
	VersionID: number;
	Username: string;
	DisplayName: string;
	EncounterData: string;
}

export interface IDictatedDocumentsTrackingResponse extends IApiResponse {
	Versions: IDictatedDocumentsTrackingVersionResponse[];
	MModalASR: string | null;
	NuanceASR: string | null;
	AzureASR: string | null;
	AISummary: string | null;
	AISummaryStructured: string | null;
	Prompt: string | null;
	Instructions: string | null;
	PatientDemographics: string | null;
	TimestampedDictationResults: ITimestampedTranscription[] | null;
	QANotes: IApiQANote[];
	ProviderNote: string | null;
}

export interface IOpenAISummaryResponse extends IApiResponse {
	OpenAISummary: string | null;
	OpenAISummaryStructured: string | null;
}

export interface IimoProblemSearchResponse {
	Result: {
		data: {
			items: Array<{
				code: string;
				length: string;
				title: string;
				IMO_LEXICAL_CODE: string;
				kndg_source: string;
				kndg_code: string;
				kndg_title: string;
				SCT_CONCEPT_ID: string;
				SNOMED_DESCRIPTION: string;
				RELATIONSHIP_TYPE: string;
				ICD10CM_CODE: string;
				ICD10CM_TITLE: string;
				DEFAULT_LEX_TEXT_IMO_CODE: string;
				ALT_LEX_TEXT_IMO_CODE: string;
				PAT_LEX_TEXT_IMO_CODE: string;
				CLINICAL_SPECIALTIES: string;
				CATEGORIES: string;
				PREF_LEX_FLAG: string;
				LASTUPDATED: string;
				prev_kndg_code: string;
				PREV_ICD10CM_CODE: string;
				NON_PRINCIPAL_DX: string;
				searchpayload: string;
			}>;
		};
	};
}

export interface IProcedureCodesResult {
	ProcedureCode: string;
	Description: string;
	ServiceType: string;
}

export interface IProcedureCodeSearchResponse extends IApiResponse {
	ProcedureCodes: IProcedureCodesResult[];
}

export interface IOrdersSearchResponse extends IApiResponse {
	Results: IOrderSearchResponse[];
}

export interface IOrderSearchResponse {
	Description: string;
	OrderTypeID: number;
	OrderTypeGroup: string;
	OrderID: string | null;
	OrderItemID: string | null;
}

export interface IOrderSetsResponse extends IApiResponse {
	OrderSets: Array<{
		OrderSetName: string;
		OrderSetID: number;
	}>;
}

export interface IOrderDetailsResponse extends IApiResponse {
	Diagnoses: Array<{
		Caption: string;
		SNOMEDCode: string;
		Orders: IOrderSearchResponse[];
	}>;
	UndiagnosedOrders: IOrderSearchResponse[];
}

export interface IGetBillingDetailsSearchQuery {
	documentID: string;
	patientID: string;
}

export interface ISaveBillingDetailsRequest {
	Services: Array<{
		ServiceID: number;
		ServiceType: string;
		ProcedureCode: string;
		ICD10Codes: Array<string>;
		Modifiers: Array<{
			ModifierID: string;
			Description: string;
			IsValid: boolean;
		}>;
		BillForService: boolean;
		Units?: number;
		Modified: number;
		IsValid: boolean;
		EncounterObjectName: string;
		Description: string;
		IsSelected: boolean;
		EncounterObjectID: string;
		ProcedureDocumentationTemplateID: string;
	}>;
	DocumentID: string;
	PatientID: string;
}

export interface ISaveBillingDetailsResponse extends IApiResponse {
	ValidationError?: string;
}

export interface IBillingDetailsSearchResponse extends IApiResponse {
	Services: Array<{
		ServiceID: number;
		ServiceType:
			| 'emcode'
			| 'EMCODE'
			| 'proceduretemplate'
			| 'PROCEDURETEMPLATE'
			| 'imaging'
			| 'IMAGING'
			| 'lab'
			| 'LAB';
		ProcedureCode: string;
		ICD10Codes: Array<string>;
		Modifiers: Array<{
			ModifierID: string;
			Description: string;
			IsValid: boolean;
		}>;
		BillForService: boolean;
		Units: number | null;
		Modified: number;
		IsValid: boolean;
		EncounterObjectName: string;
		Description: string;
		IsSelected: boolean;
		EncounterObjectID: string;
		ProcedureDocumentationTemplateID: string;
	}>;
	ReviewComplete: boolean;
	ClaimCreated: boolean;
	EhrLogin: string | null;
}

export interface IBillingModifiersResponse extends IApiResponse {
	Modifiers: Array<{
		ModifierID: string;
		Description: string;
		IsValid: boolean;
	}>;
}

export interface IProcedureDocumentationTemplatesResponse extends IApiResponse {
	TemplateList: IProcedureDocumentationTemplateResponse[];
}

export interface IProcedureDocumentationResponse extends IApiResponse {
	Template: {
		ProcedureTemplateID: string;
		ProcedureTemplateName: string;
		TemplateText: string;
	};
}

export interface IProcedureDocumentationTemplateResponse {
	UserId: number;
	EHRTemplateId: number;
	DocumentTypeId: number;
	TemplateName: string;
	CreatedDate: string;
	LastSelectedDate: string;
	TemplateType: 'proceduredocumentation';
	TemplateText: string;
	DocumentTypeID: number;
}

export interface IJobDictationResponse
	extends Omit<IProviderDictationResponse, 'MarkedForQA' | 'DocumentSaveStatus' | 'AppointmentID'> {
	STAT: boolean | null;
}

export interface IJobsSearchQuery {
	patientID?: string;
	dictatedBy?: string;
	transcribedBy?: string;
	qaBy?: string;
	dictatedStartDate?: string;
	dictatedEndDate?: string;
	sentStartDate?: string;
	sentEndDate?: string;
	documentType?: string;
	documentStatus?: string;
}
