import * as React from 'react';
import { useTranslation } from 'react-i18next';
import {
	Box,
	Checkbox,
	Chip,
	FormControlLabel,
	Grid,
	IconButton,
	ListItem,
	ListItemButton,
	Menu,
	MenuItem,
	MenuList,
	Paper,
	Stack,
	TextField,
	Typography,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import Spinner from '../spinner/Spinner';
import { IBillingCode, IBillingModifier } from '../../core/models/dictations/dictations.models';
import { namespaces } from '../../application/i18n.constants';

export enum BillingServicesFeatures {
	Modifiers = 'Modifiers',
	Icd10Codes = 'Icd10Codes',
	Units = 'Units',
}

interface Iicd10CodesMenuListProps {
	icd10CodesList: Array<string>;
	onIcdMenuItemClick: (code: string) => void;
}

function Icd10CodesMenuList({ icd10CodesList, onIcdMenuItemClick }: Iicd10CodesMenuListProps) {
	const { t } = useTranslation(namespaces.components.billingDetails);

	const handleIcdMenuItemClick = (code: string) => {
		onIcdMenuItemClick(code);
	};

	return (
		<MenuList>
			{icd10CodesList.length ? (
				icd10CodesList.map((code) => (
					<ListItem disablePadding key={code}>
						<ListItemButton onClick={() => handleIcdMenuItemClick(code)}>
							<Typography variant="subtitle1" sx={{ marginRight: '1rem' }}>
								{code}
							</Typography>
						</ListItemButton>
					</ListItem>
				))
			) : (
				<ListItem component="div">
					<Typography>{t('icd10CodesFull')}</Typography>
				</ListItem>
			)}
		</MenuList>
	);
}
interface IBillingModifiersListProps {
	billingModifiersResults: IBillingModifier[];
	activeModifiers: IBillingModifier[];
	onClick: (modifier: IBillingModifier) => void;
}

function BillingModifiersList({ billingModifiersResults, activeModifiers, onClick }: IBillingModifiersListProps) {
	const { t } = useTranslation(namespaces.components.billingDetails);
	const modifiersList = billingModifiersResults.filter(
		(item) => !activeModifiers.some((activeModifier) => activeModifier.modifierID === item.modifierID)
	);
	const handleClick = (modifier: IBillingModifier) => {
		onClick(modifier);
	};

	return (
		<MenuList sx={{ width: '25rem', maxWidth: '100%' }}>
			{modifiersList ? (
				modifiersList.map((modifier) => (
					<ListItem component="div" disablePadding key={modifier.modifierID}>
						<ListItemButton onClick={() => handleClick(modifier)}>
							<Typography variant="subtitle1" sx={{ marginRight: '1rem' }}>
								{modifier.modifierID}
							</Typography>
							<Typography variant="inherit" sx={{ fontSize: '0.75rem' }} noWrap>
								{modifier.description}
							</Typography>
						</ListItemButton>
					</ListItem>
				))
			) : (
				<MenuItem>{t('noResults')}</MenuItem>
			)}
		</MenuList>
	);
}

interface IBillingServicesItemProps {
	loading: boolean;
	readonly?: boolean;
	icd10Codes: Array<string>;
	billingServiceItem: IBillingCode;
	billingModifiersResults: IBillingModifier[];
	handleBillingModifierSelect: (modifier: IBillingModifier) => void;
	handleBillingModifierDelete: (modifier: IBillingModifier) => void;
	handleBillingCodeDelete: () => void;
	handleBillingCodeUnitChange: (units: number | null, procedureCode: string) => void;
	handleIcd10CodeDelete: (icd10Code: string, procedureCode: string) => void;
	handleIcd10CodeSelect: (icd10Code: string, procedureCode: string) => void;
	handleBillForServiceToggle: (bill: boolean, procedureCode: string) => void;
	disabledFeatures: BillingServicesFeatures[];
}

function BillingServicesItem({
	loading,
	readonly,
	icd10Codes,
	billingServiceItem,
	billingModifiersResults,
	handleIcd10CodeDelete,
	handleIcd10CodeSelect,
	handleBillingModifierSelect,
	handleBillingModifierDelete,
	handleBillingCodeUnitChange,
	handleBillingCodeDelete,
	handleBillForServiceToggle,
	disabledFeatures,
}: IBillingServicesItemProps) {
	const { t } = useTranslation(namespaces.components.billingDetails);

	const [open] = React.useState(false);
	const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
	const [icd10CodesAnchorEl, setIcd10CodesAnchorEl] = React.useState<null | HTMLElement>(null);

	const menuOpen = Boolean(anchorEl);
	const icd10CodesMenuOpen = Boolean(icd10CodesAnchorEl);
	const hasIcd10Codes = !!billingServiceItem.iCD10Codes.length;

	const onModifiersMenuClick = (event: React.MouseEvent<HTMLButtonElement>) => {
		setAnchorEl(event.currentTarget);
	};

	const onIcdCodesMenuClick = (event: React.MouseEvent<HTMLButtonElement>) => {
		setIcd10CodesAnchorEl(event.currentTarget);
	};

	const onMenuClose = () => {
		setAnchorEl(null);
		setIcd10CodesAnchorEl(null);
	};

	const handleDelete = (modifier: IBillingModifier) => {
		handleBillingModifierDelete(modifier);
	};

	const handleBillingCodeDeleteClick = () => {
		handleBillingCodeDelete();
	};

	const handleIcdMenuItemClick = (icd10Code: string, procedureCode: string) => {
		handleIcd10CodeSelect(icd10Code, procedureCode);
		setIcd10CodesAnchorEl(null);
	};

	const handleIcdCodeDelete = (icd10Code: string, procedureCode: string) => {
		handleIcd10CodeDelete(icd10Code, procedureCode);
	};

	const handleSelect = (modifier: IBillingModifier) => {
		setAnchorEl(null);
		handleBillingModifierSelect(modifier);
	};

	const handleBillForServiceChange = (billValue: boolean, procedureCode: string) => {
		handleBillForServiceToggle(billValue, procedureCode);
	};

	const hasFeature = (feature: BillingServicesFeatures) => {
		return !disabledFeatures.includes(feature);
	};

	return (
		<Paper>
			<Box sx={{ display: 'flex', alignItems: 'center', padding: '2rem 2rem 0.5rem 2rem', marginTop: '0.5rem' }}>
				<Grid direction="column" container>
					<Grid item sx={{ display: 'flex', flexDirection: 'row', marginBottom: '1rem' }}>
						<Grid item md={4} sx={{ display: 'flex', alignItems: 'center' }}>
							<Typography variant="h6">{billingServiceItem.procedureCode}</Typography>
						</Grid>
						{hasFeature(BillingServicesFeatures.Units) && (
							<Grid item md={6} sx={{ display: 'flex', alignItems: 'center' }}>
								<Typography sx={{ marginRight: '2rem' }}>{t('units')}: </Typography>
								<TextField
									size="small"
									value={
										billingServiceItem.units === undefined || billingServiceItem.units === null
											? ''
											: billingServiceItem.units
									}
									type="number"
									disabled={readonly}
									onChange={(e) => {
										handleBillingCodeUnitChange(
											e.target.value === '' ? null : Number(e.target.value),
											billingServiceItem.procedureCode
										);
									}}
								/>
							</Grid>
						)}
						{!readonly && (
							<Grid
								item
								md={hasFeature(BillingServicesFeatures.Units) ? 2 : 8}
								sx={{ display: 'flex', justifyContent: 'end' }}
							>
								<IconButton onClick={() => handleBillingCodeDeleteClick()}>
									<CloseIcon sx={{ fontSize: '1rem' }} />
								</IconButton>
							</Grid>
						)}
					</Grid>
					<Grid item>
						{hasFeature(BillingServicesFeatures.Modifiers) && (
							<Grid item>
								<Typography>{t('modifiers')}: </Typography>
								{billingServiceItem.modifiers?.map((modifier) => (
									<Chip
										key={modifier.modifierID}
										sx={{ marginLeft: '0.5rem' }}
										label={modifier.modifierID}
										variant="outlined"
										onDelete={!readonly ? () => handleDelete(modifier) : undefined}
									/>
								))}
								{!readonly && (
									<IconButton
										size="small"
										sx={{ marginLeft: '1rem' }}
										aria-controls={open ? 'split-button-menu' : undefined}
										aria-expanded={open ? 'true' : undefined}
										aria-label="add modifiers"
										aria-haspopup="menu"
										onClick={onModifiersMenuClick}
									>
										<AddCircleOutlineIcon />
									</IconButton>
								)}
								<Menu
									id="basic-menu"
									anchorEl={anchorEl}
									open={menuOpen}
									onClose={onMenuClose}
									MenuListProps={{
										'aria-labelledby': 'basic-button',
									}}
								>
									{loading ? (
										<Box sx={{ padding: '1rem' }}>
											<Spinner size="1rem" />
										</Box>
									) : (
										<BillingModifiersList
											billingModifiersResults={billingModifiersResults}
											activeModifiers={billingServiceItem.modifiers}
											onClick={(modifier) => handleSelect(modifier)}
										/>
									)}
								</Menu>
							</Grid>
						)}
					</Grid>
					{hasFeature(BillingServicesFeatures.Icd10Codes) && (
						<Grid item sx={{ marginTop: '1rem' }}>
							<Typography>{t('icd10codes')}: </Typography>
							{billingServiceItem.iCD10Codes?.map((icd) => (
								<Chip
									key={icd}
									sx={{ marginLeft: '0.5rem' }}
									label={icd}
									variant="outlined"
									color="secondary"
									onDelete={!readonly ? () => handleIcdCodeDelete(icd, billingServiceItem.procedureCode) : undefined}
								/>
							))}
							{!readonly && (
								<IconButton
									size="small"
									sx={{ marginLeft: '1rem' }}
									aria-controls={icd10CodesMenuOpen ? 'split-button-menu' : undefined}
									aria-expanded={icd10CodesMenuOpen ? 'true' : undefined}
									aria-label="add icd10 code"
									color="secondary"
									aria-haspopup="menu"
									onClick={onIcdCodesMenuClick}
								>
									<AddCircleOutlineIcon />
								</IconButton>
							)}
							<Menu
								id="basic-menu"
								anchorEl={icd10CodesAnchorEl}
								open={icd10CodesMenuOpen}
								onClose={onMenuClose}
								MenuListProps={{
									'aria-labelledby': 'basic-button',
								}}
							>
								{loading ? (
									<Box sx={{ padding: '1rem' }}>
										<Spinner size="1rem" />
									</Box>
								) : (
									<Icd10CodesMenuList
										onIcdMenuItemClick={(icd10Code) =>
											handleIcdMenuItemClick(icd10Code, billingServiceItem.procedureCode)
										}
										icd10CodesList={icd10Codes.filter((item) => billingServiceItem.iCD10Codes.indexOf(item) === -1)}
									/>
								)}
							</Menu>
						</Grid>
					)}
					{hasFeature(BillingServicesFeatures.Icd10Codes) && !hasIcd10Codes && (
						<Box sx={{ padding: '1rem' }}>
							<Stack direction="row" alignItems="center" gap={1}>
								<WarningAmberIcon color="warning" sx={{ fontSize: '1.3rem' }} />
								<Typography>{t('missingDiagnosis')}</Typography>
							</Stack>
						</Box>
					)}
					{hasFeature(BillingServicesFeatures.Icd10Codes) && hasIcd10Codes && (
						<Grid item sx={{ textAlign: 'center' }}>
							<FormControlLabel
								sx={{ pl: 4.5 }}
								control={
									<Checkbox
										disabled={readonly}
										checked={billingServiceItem.billForService}
										onChange={(billValue) =>
											handleBillForServiceChange(billValue.target.checked, billingServiceItem.procedureCode)
										}
									/>
								}
								label={
									<Typography sx={{ color: `${billingServiceItem.billForService ? 'success.main' : 'error.main'}` }}>
										{t('bill')}?
									</Typography>
								}
							/>
						</Grid>
					)}
				</Grid>
			</Box>
		</Paper>
	);
}

export interface IBillingServicesItemsListProps {
	readonly: boolean;
	billingCodes: IBillingCode[];
	billingModifiersLoading: boolean;
	billingModifiers: IBillingModifier[];
	icd10Codes: Array<string>;
	onIcd10CodeSelect: (billingCode: IBillingCode, icd10Code: string) => void;
	onIcd10CodeDelete: (billingCode: IBillingCode, icd10Code: string) => void;
	onBillingModifierSelect: (billingCode: IBillingCode, modifier: IBillingModifier) => void;
	onBillingModifierDelete: (billingCode: IBillingCode, modifier: IBillingModifier) => void;
	onBillingCodeDelete: (billingCode: IBillingCode) => void;
	onBillingCodeUnitChange: (billingCode: IBillingCode, units: number | null) => void;
	onBillForServiceToggle: (billingCode: IBillingCode, bill: boolean) => void;
	disabledFeatures: BillingServicesFeatures[];
}

function BillingServicesItemsList({
	readonly,
	billingCodes,
	billingModifiersLoading,
	billingModifiers,
	icd10Codes,
	onIcd10CodeSelect,
	onIcd10CodeDelete,
	onBillingModifierSelect,
	onBillingModifierDelete,
	onBillingCodeDelete,
	onBillingCodeUnitChange,
	onBillForServiceToggle,
	disabledFeatures,
}: IBillingServicesItemsListProps) {
	const handleBillingModifierSelect = (billingCode: IBillingCode, modifier: IBillingModifier) => {
		onBillingModifierSelect(billingCode, modifier);
	};

	const handleBillingModifierDelete = (billingCode: IBillingCode, modifier: IBillingModifier) => {
		onBillingModifierDelete(billingCode, modifier);
	};

	const handleBillingCodeUnitChange = (billingCode: IBillingCode, units: number | null) => {
		onBillingCodeUnitChange(billingCode, units);
	};

	const handleBillingCodeDelete = (billingCode: IBillingCode) => {
		onBillingCodeDelete(billingCode);
	};

	const handleIcd10CodeSelect = (billingCode: IBillingCode, icd10Code: string) => {
		onIcd10CodeSelect(billingCode, icd10Code);
	};

	const handleIcd10CodeDelete = (billingCode: IBillingCode, icd10Code: string) => {
		onIcd10CodeDelete(billingCode, icd10Code);
	};

	const handleBillForServiceToggle = (billingCode: IBillingCode, bill: boolean) => {
		onBillForServiceToggle(billingCode, bill);
	};

	return (
		<Box>
			{billingCodes.map((billingCode) => {
				return (
					<BillingServicesItem
						key={billingCode.procedureCode}
						disabledFeatures={disabledFeatures}
						readonly={readonly}
						loading={billingModifiersLoading}
						billingServiceItem={billingCode}
						icd10Codes={icd10Codes}
						billingModifiersResults={billingModifiers}
						handleIcd10CodeSelect={(icd10Code) => handleIcd10CodeSelect(billingCode, icd10Code)}
						handleIcd10CodeDelete={(icd10Code) => handleIcd10CodeDelete(billingCode, icd10Code)}
						handleBillingModifierSelect={(modifier) => handleBillingModifierSelect(billingCode, modifier)}
						handleBillingModifierDelete={(modifier) => handleBillingModifierDelete(billingCode, modifier)}
						handleBillingCodeUnitChange={(units) => handleBillingCodeUnitChange(billingCode, units)}
						handleBillingCodeDelete={() => handleBillingCodeDelete(billingCode)}
						handleBillForServiceToggle={(bill) => handleBillForServiceToggle(billingCode, bill)}
					/>
				);
			})}
		</Box>
	);
}

export default BillingServicesItemsList;
