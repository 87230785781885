import { AIModelType, IRequestAISummaryRequest } from '../../api/dictations/dictations.api.models';
import { IPatientDemographic, IPatientEncounter } from '../../models/patients/patients.models';
import { IDictation } from '../../models/dictations/dictations.models';

export const getAiSummary = (data: {
	blobId: string;
	aiModel: AIModelType | null;
	prompt: string;
	instructions: string;
	appointmentID: string | null;
	documentType: string;
	documentID: string | null;
	patientDemographic: IPatientDemographic | null;
}): IRequestAISummaryRequest => {
	return {
		blobID: data.blobId,

		prompt: data.prompt,
		instructions: data.instructions,

		documentID: data.documentID,
		appointmentID: data.appointmentID ?? null,
		appointmentType: data.documentType ?? null,
		// we don't have this data yet
		documentTypeID: '0',

		patientID: data.patientDemographic?.patientID ?? null,
		gender: data.patientDemographic?.genderText ?? null,
		dateOfBirth: data.patientDemographic?.dateOfBirth ?? null,
		firstName: data.patientDemographic?.firstName ?? null,
		lastName: data.patientDemographic?.lastName ?? null,
		AIModel: data.aiModel,
	};
};

export const getAiSummaryRequest = (data: {
	blobId: string;
	aiModel: AIModelType | null;
	prompt: string;
	instructions: string;
	encounter: IPatientEncounter | null;
	dictation: IDictation | null;
	patientDemographic: IPatientDemographic | null;
}): IRequestAISummaryRequest => {
	return getAiSummary({
		blobId: data.blobId,
		aiModel: data.aiModel,
		prompt: data.prompt,
		instructions: data.instructions,
		appointmentID: data.dictation?.appointmentID ?? null,
		documentType: data.dictation?.documentType ?? '',
		documentID:
			(data.encounter?.documentID ? String(data.encounter?.documentID) : null) ?? data.dictation?.documentID ?? null,
		patientDemographic: data.patientDemographic,
	});
};
