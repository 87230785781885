import { createReducer } from '@reduxjs/toolkit';
import { IUserAIConfiguration } from '../../api/user/user-ai-settings.models';
import { userAISettingsActions } from './user-ai-settings.actions';
import { IUserDisplayName } from '../../models/users/user.models';
import { IJobDictation, IOpenAISummary } from '../../models/dictations/dictations.models';

export interface IUserAISettingsState {
	loading: boolean;
	saving: boolean;
	edited: boolean;
	userInfoId: string;
	reviewBlobId: string | null;
	configuration: IUserAIConfiguration;
	transcription: {
		openAISummary: string | null;
		openAISummaryStructured: IOpenAISummary | null;
		lastTimePromptSubmitted: number | null;
	};
	users: IUserDisplayName[];
	jobs: IJobDictation[];
}

const initialState: IUserAISettingsState = {
	loading: false,
	saving: false,
	edited: false,
	userInfoId: '',
	reviewBlobId: '',
	configuration: {
		EhrAIConfigs: [],
		EhrConfigPhrases: [],
		UserConfigPhrases: [],
		AllPhrases: [],
		FunctionId: 0,
		FunctionName: '',
		ModelId: 0,
		ModelName: '',
		IsDiscreteDiagnoses: false,
	},
	transcription: {
		openAISummary: null,
		openAISummaryStructured: null,
		lastTimePromptSubmitted: null,
	},
	users: [],
	jobs: [],
};

const userAISettingsReducer = createReducer<IUserAISettingsState>(initialState, (builder) => {
	builder
		.addCase(userAISettingsActions.setUsersList, (state, action) => {
			state.users = action.payload;
		})
		.addCase(userAISettingsActions.initUserAISettingsIsLoading, (state, action) => {
			state.loading = action.payload;
		})
		.addCase(userAISettingsActions.setUserAISettings, (state, action) => {
			state.userInfoId = action.payload.userInfoId;
			state.configuration = action.payload.configurations;
		})
		.addCase(userAISettingsActions.setUserLevelAIPhrases, (state, action) => {
			state.configuration.UserConfigPhrases = action.payload;
		})
		.addCase(userAISettingsActions.setSaveUserLevelAIPhrasesIsLoading, (state, action) => {
			state.saving = action.payload;
		})
		.addCase(userAISettingsActions.setSaveUserLevelAIPhrasesIsChanged, (state, action) => {
			state.edited = action.payload;
		})
		.addCase(userAISettingsActions.setJobsList, (state, action) => {
			state.jobs = action.payload;
			state.transcription = {
				openAISummary: null,
				openAISummaryStructured: null,
				lastTimePromptSubmitted: null,
			};
		})
		.addCase(userAISettingsActions.setReviewBlobId, (state, action) => {
			state.reviewBlobId = action.payload;
		})
		.addCase(userAISettingsActions.setPromptAISummary, (state, action) => {
			state.transcription.openAISummary = action.payload.openAISummary;
			state.transcription.openAISummaryStructured = action.payload.openAISummaryStructured;
		})
		.addCase(userAISettingsActions.setLastTimePromptSubmitted, (state, action) => {
			state.transcription.lastTimePromptSubmitted = action.payload;
		});
});

export default userAISettingsReducer;
