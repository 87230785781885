import { routes } from '../App/routes';
import { ScribePermission } from '../../core/api/user/user.api.models';
import { environment } from '../../environment/environment';

export interface Page {
	page: string;
	route: string;
	subPages?: Page[];
	requiredPermissions?: string[];
	externalRoute?: boolean;
}

const pages: Page[] = [
	{ page: 'workList', route: routes.home },
	{ page: 'accountSettings', route: routes.accountSettings },
	{
		page: 'admin',
		route: routes.admin,
		subPages: [
			{ page: 'customerMacros', route: routes.adminTabs.customerMacros, requiredPermissions: [ScribePermission.Admin] },
			{ page: 'providerMacros', route: routes.adminTabs.providerMacros },
			{ page: 'wordReplacements', route: routes.adminTabs.wordReplacements },
			{
				page: 'userAISettings',
				route: routes.adminTabs.userAISettings,
				requiredPermissions: [ScribePermission.AIPreferences],
			},
		],
	},
	{ page: 'support', route: environment.INTERCOM_TICKET_CENTER_URL, externalRoute: true },
];

export default pages;
