import { Box, Typography } from '@mui/material';
import * as React from 'react';
import { grey } from '@mui/material/colors';
import { IJobDictation, IOpenAISummary } from '../../core/models/dictations/dictations.models';
import UserAIReviewHeader from './UserAIReviewHeader';
import OpenAiSummary from '../transcription/openai-summary/OpenAiSummary';
import { EHRType } from '../../core/models/ehr.models';

export interface IAISettingsReviewSectionProps {
	isMobile: boolean;
	isLoading: boolean;
	isConfigChanged: boolean;
	ehr: EHRType;
	jobs: IJobDictation[];
	transcription: { openAISummary: string | null; openAISummaryStructured: IOpenAISummary | null };
	onReviewChanges: (blobId: string) => void;
}
function AISettingsReviewSection({
	isMobile,
	isLoading,
	isConfigChanged,
	ehr,
	jobs,
	transcription,
	onReviewChanges,
}: IAISettingsReviewSectionProps) {
	return (
		<Box
			sx={{
				display: 'flex',
				flexDirection: 'column',
				height: '100%',
				overflowY: 'auto',
			}}
		>
			<UserAIReviewHeader
				isLoading={isLoading}
				isMobile={isMobile}
				jobs={jobs}
				onReviewChanges={onReviewChanges}
				isConfigChanged={isConfigChanged}
			/>
			<Box
				sx={{
					height: '100%',
					overflowY: 'auto',
					backgroundColor: grey['100'],
				}}
			>
				{transcription.openAISummary || transcription.openAISummaryStructured ? (
					<OpenAiSummary
						ehr={ehr}
						overflowY="auto"
						palette={{
							fieldPalette: {
								headerHoveredBgColor: grey['300'],
								headerHoveredFocusedBgColor: grey['300'],
								bgColor: grey['100'],
							},
							bgColor: grey['100'],
						}}
						addDisabled
						text={transcription.openAISummary}
						summary={transcription.openAISummaryStructured}
						selectedDiagnoses={[]}
					/>
				) : (
					<Box
						sx={{
							display: 'flex',
							justifyContent: 'center',
							alignItems: 'center',
							width: '100%',
						}}
					>
						{!isLoading && (
							<Typography variant="h5" p={6} fontWeight="normal" color="primary" align="center">
								{`Click 'Review Changes' to check the AI-generated note tailored to your current settings — it's quick, easy, and personalized just for you!`}
							</Typography>
						)}
					</Box>
				)}
				<Box
					sx={{
						display: 'flex',
						justifyContent: 'center',
						alignItems: 'center',
						width: '100%',
					}}
				>
					{isLoading && (
						<Typography variant="h5" p={6} fontWeight="normal" color="primary" align="center">
							{`Hang tight! We're generating your personalized AI-powered note — this will just take a moment...`}
						</Typography>
					)}
				</Box>
			</Box>
		</Box>
	);
}

export default AISettingsReviewSection;
