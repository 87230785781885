import { createAction } from '@reduxjs/toolkit';
import { userAISettingsConstants } from './user-ai-settings.constants';
import { IUserAIConfigPhrase, IUserAIConfiguration } from '../../api/user/user-ai-settings.models';
import { IUserDisplayName } from '../../models/users/user.models';
import { IJobDictation, IOpenAISummary } from '../../models/dictations/dictations.models';

export interface IInitUserAISettingsActionPayload {
	userInfoId: string;
}

const initUserAISettings = createAction<IInitUserAISettingsActionPayload>(
	userAISettingsConstants.INIT_USER_AI_SETTINGS
);

const initUserAISettingsIsLoading = createAction<boolean>(userAISettingsConstants.INIT_USER_AI_SETTINGS_IS_LOADING);

interface ISetUserAISettingsActionPayload {
	userInfoId: string;
	configurations: IUserAIConfiguration;
}

const setUserAISettings = createAction<ISetUserAISettingsActionPayload>(userAISettingsConstants.SET_USER_AI_SETTINGS);
const setUserLevelAIPhrases = createAction<IUserAIConfigPhrase[]>(userAISettingsConstants.SET_USER_LEVEL_AI_PHRASES);

const setSaveUserLevelAIPhrasesIsLoading = createAction<boolean>(
	userAISettingsConstants.SAVE_USER_LEVEL_AI_PHRASES_IS_LOADING
);

const setSaveUserLevelAIPhrasesIsChanged = createAction<boolean>(
	userAISettingsConstants.SET_USER_LEVEL_AI_PHRASES_IS_CHANGED
);

export interface ISaveUserLevelAIPhrasesActionPayload {
	userInfoId: string;
	configurations: IUserAIConfiguration;
	isDiscreteDiagnoses: boolean;
}
const saveUserLevelAIPhrases = createAction<ISaveUserLevelAIPhrasesActionPayload>(
	userAISettingsConstants.SAVE_USER_LEVEL_AI_PHRASES
);

const setUsersList = createAction<IUserDisplayName[]>(userAISettingsConstants.SET_USERS_LIST);
const setJobsList = createAction<IJobDictation[]>(userAISettingsConstants.SET_JOBS_LIST);

export interface IGetAISummaryActionPayload {
	blobId: string;
	documentID: string | null;
	appointmentID: string | null;
	documentType: string | null;
}

const setReviewBlobId = createAction<string | null>(userAISettingsConstants.SET_REVIEW_BLOB_ID);
const getAISummary = createAction<IGetAISummaryActionPayload>(userAISettingsConstants.GET_AI_SUMMARY);
const setLastTimePromptSubmitted = createAction<number | null>(userAISettingsConstants.SET_LAST_TIME_PROMPT_SUBMITTED);

export interface IUpdatePromptAISummaryActionPayload {
	blobId: string;
}
const updatePromptAISummary = createAction<IUpdatePromptAISummaryActionPayload>(
	userAISettingsConstants.UPDATE_PROMPT_AI_SUMMARY
);

export interface ISetPromptAISummaryActionPayload {
	openAISummary: string | null;
	openAISummaryStructured: IOpenAISummary | null;
}
const setPromptAISummary = createAction<ISetPromptAISummaryActionPayload>(
	userAISettingsConstants.SET_PROMPT_AI_SUMMARY
);

export const userAISettingsActions = {
	initUserAISettings,
	initUserAISettingsIsLoading,

	setUserAISettings,
	setUserLevelAIPhrases,

	setUsersList,
	setJobsList,

	setSaveUserLevelAIPhrasesIsLoading,
	setSaveUserLevelAIPhrasesIsChanged,
	saveUserLevelAIPhrases,

	setReviewBlobId,

	getAISummary,
	setLastTimePromptSubmitted,
	updatePromptAISummary,
	setPromptAISummary,
};
