import * as React from 'react';
import { EHRType } from '../../core/models/ehr.models';
import BillingServicesDialog, { IBillingServicesDialogProps } from './BillingServicesDialog';

interface IEhrBillingServicesDialogProps extends IBillingServicesDialogProps {
	ehr: EHRType;
}
function EhrBillingServicesDialog({ ehr, ...props }: IEhrBillingServicesDialogProps) {
	if (ehr === EHRType.NextGen || ehr === EHRType.PrimeSuite) {
		return (
			<BillingServicesDialog
				// eslint-disable-next-line react/jsx-props-no-spreading
				{...props}
				procedureCodeSearchResults={props.procedureCodeSearchResults.filter(
					(x) => x.serviceType == null || x.serviceType?.toLowerCase() === 'emcode'
				)}
			/>
		);
	}
	// eslint-disable-next-line react/jsx-props-no-spreading
	return <BillingServicesDialog {...props} />;
}

export default EhrBillingServicesDialog;
