import * as React from 'react';
import { useIntercom } from 'react-use-intercom';
import { useAppSelector } from '../core/core.types';

function removeDomain(username: string) {
	if (!username) {
		return username;
	}

	let user = username;

	const idx1 = username.toLowerCase().indexOf('@iscribeprod.onmicrosoft.com');
	const idx2 = username.toLowerCase().indexOf('@iscribehealth.com');
	const idx3 = username.toLowerCase().indexOf('_iscribehealth.com#ext#');

	if (idx1 > -1) {
		user = user.substring(0, idx1);
	}
	if (idx2 > -1) {
		user = user.substring(0, idx2);
	}
	if (idx3 > -1) {
		user = user.substring(0, idx3);
	}

	return user;
}

function UseIntercomWidget() {
	const user = useAppSelector((state) => state.user.settings);

	const { boot, shutdown, update } = useIntercom();

	React.useEffect(() => {
		if (user?.apiKey && user.featureFlags.useNewSupportOption.enabled) {
			boot();
		}
		return () => shutdown();
	}, [boot, shutdown, user?.apiKey && user.featureFlags.useNewSupportOption.enabled]);

	React.useEffect(() => {
		if (update && user?.apiKey) {
			update({
				name: user.displayName,
				userHash: user.intercomUserHash,
				userId: user.username,
				email: user.email,
				companies: [
					{
						companyId: user.customerId,
						name: user.customerName,
					},
				],
				customAttributes: {
					Username: removeDomain(user.username),
					EHR: user.system,
					'AI User': user.featureFlags.openAIBypassVSS.enabled,
					'Transcription User': user.featureFlags.transcription.enabled && !user.featureFlags.openAIBypassVSS.enabled,
					'STT User': user.featureFlags.speechToText.enabled,
				},
			});
		}
	}, [user?.apiKey, update]);
}

export default UseIntercomWidget;
