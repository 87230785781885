import { Box, Divider, FormControlLabel, Grid, Switch, Typography } from '@mui/material';
import * as React from 'react';
import { IUserDisplayName } from '../../core/models/users/user.models';
import { IUserAIConfigPhrase, IUserAIConfiguration } from '../../core/api/user/user-ai-settings.models';
import UserAIConfigPhraseDropdown from './UserAIConfigPhraseDropdown';
import UserAIConfigHeader from './UserAIConfigHeader';

export interface IAISettingsConfigurationSectionProps {
	isMobile: boolean;
	isSaving: boolean;
	enableSelectUser: boolean;
	onSelectUser: (userInfoId: string) => void;
	userInfoId: string;
	users: IUserDisplayName[];
	configuration: IUserAIConfiguration;
	onConfigurationChange: (configuration: IUserAIConfigPhrase[]) => void;
	onDiscreteDiagnosisChange: (value: boolean) => void;
	onSaveChanges: () => void;
}
function AISettingsConfigurationSection({
	isMobile,
	isSaving,
	enableSelectUser,
	userInfoId,
	users,
	onSelectUser,
	configuration,
	onConfigurationChange,
	onDiscreteDiagnosisChange,
	onSaveChanges,
}: IAISettingsConfigurationSectionProps) {
	return (
		<Box
			sx={{
				display: 'flex',
				flexDirection: 'column',
				height: '100%',
				overflowY: 'auto',
			}}
		>
			{!isMobile && (
				<UserAIConfigHeader
					isMobile={isMobile}
					isSaving={isSaving}
					enableSelectUser={enableSelectUser}
					onSelectUser={onSelectUser}
					users={users}
					userInfoId={userInfoId}
					onSaveChanges={onSaveChanges}
				/>
			)}
			<Grid sx={{ height: '100%', overflowY: 'auto', px: 2, pt: 2 }} container columnSpacing={2} rowSpacing={1}>
				<Grid item xs={12}>
					<Typography color="primary" variant="h6">
						Specialty
					</Typography>
					<Divider />
				</Grid>
				<Grid item xs={12} md={6} pt={2}>
					<UserAIConfigPhraseDropdown
						phraseSubType="Specialty"
						userInfoId={userInfoId}
						configuration={configuration}
						onConfigurationChange={onConfigurationChange}
					/>
				</Grid>
				<Grid item xs={12}>
					<Typography color="primary" variant="h6" pt={2}>
						Subjective
					</Typography>
					<Divider />
				</Grid>
				<Grid item xs={12} md={6}>
					<Typography color="primary" variant="h6" fontWeight="normal">
						History of Patient Illness
					</Typography>
					<UserAIConfigPhraseDropdown
						label="Style"
						phraseSubType="HPI Style"
						userInfoId={userInfoId}
						configuration={configuration}
						onConfigurationChange={onConfigurationChange}
					/>
				</Grid>
				<Grid item xs={12} md={6}>
					<Typography color="primary" variant="h6" fontWeight="normal">
						Review of Systems
					</Typography>
					<UserAIConfigPhraseDropdown
						label="Style"
						phraseSubType="ROS Style"
						userInfoId={userInfoId}
						configuration={configuration}
						onConfigurationChange={onConfigurationChange}
					/>
				</Grid>
				<Grid item xs={12}>
					<Typography color="primary" variant="h6" pt={2}>
						Objective
					</Typography>
					<Divider />
				</Grid>
				<Grid item xs={12} md={6}>
					<Typography color="primary" variant="h6" fontWeight="normal">
						Physical Exam
					</Typography>
					<UserAIConfigPhraseDropdown
						label="Format"
						phraseSubType="PE Format"
						userInfoId={userInfoId}
						configuration={configuration}
						onConfigurationChange={onConfigurationChange}
					/>
					<UserAIConfigPhraseDropdown
						label="Style"
						phraseSubType="PE Style"
						userInfoId={userInfoId}
						configuration={configuration}
						onConfigurationChange={onConfigurationChange}
					/>
				</Grid>
				<Grid item xs={12} md={6}>
					<Typography color="primary" variant="h6" fontWeight="normal">
						Imaging\Results
					</Typography>
					<UserAIConfigPhraseDropdown
						label="Format"
						phraseSubType="Imaging Format"
						userInfoId={userInfoId}
						configuration={configuration}
						onConfigurationChange={onConfigurationChange}
					/>
					<UserAIConfigPhraseDropdown
						label="Style"
						phraseSubType="Imaging Style"
						userInfoId={userInfoId}
						configuration={configuration}
						onConfigurationChange={onConfigurationChange}
					/>
				</Grid>
				<Grid item xs={12}>
					<Typography color="primary" variant="h6" pt={2}>
						Assessment & Plan
					</Typography>
					<Divider />
				</Grid>
				<Grid item xs={12} md={6}>
					<Typography color="primary" variant="h6" fontWeight="normal">
						Assessment
					</Typography>
					<UserAIConfigPhraseDropdown
						label="Format"
						phraseSubType="Assessment Format"
						userInfoId={userInfoId}
						configuration={configuration}
						onConfigurationChange={onConfigurationChange}
					/>
					<UserAIConfigPhraseDropdown
						label="Style"
						phraseSubType="Assessment Style"
						userInfoId={userInfoId}
						configuration={configuration}
						onConfigurationChange={onConfigurationChange}
					/>
				</Grid>
				<Grid item xs={12} md={6}>
					<Typography color="primary" variant="h6" fontWeight="normal">
						Plan
					</Typography>
					<UserAIConfigPhraseDropdown
						label="Format"
						phraseSubType="Plan Format"
						userInfoId={userInfoId}
						configuration={configuration}
						onConfigurationChange={onConfigurationChange}
					/>
					<UserAIConfigPhraseDropdown
						label="Style"
						phraseSubType="Plan Style"
						userInfoId={userInfoId}
						configuration={configuration}
						onConfigurationChange={onConfigurationChange}
					/>
				</Grid>
				<Grid item xs={12}>
					<Typography color="primary" variant="h6" pt={2}>
						Other
					</Typography>
					<Divider />
				</Grid>
				<Grid item xs={12}>
					<FormControlLabel
						control={
							<Switch
								checked={configuration.IsDiscreteDiagnoses}
								onChange={(e, value) => {
									onDiscreteDiagnosisChange(value);
								}}
							/>
						}
						label="Discrete Diagnosis"
					/>
				</Grid>
				<Grid item xs={12}>
					<Typography color="primary" variant="h6" fontWeight="normal">
						Attestation
					</Typography>
				</Grid>
				<Grid item xs={12} md={6}>
					<UserAIConfigPhraseDropdown
						phraseSubType="Attestation"
						userInfoId={userInfoId}
						configuration={configuration}
						onConfigurationChange={onConfigurationChange}
					/>
				</Grid>
			</Grid>
		</Box>
	);
}

export default AISettingsConfigurationSection;
