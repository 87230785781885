import * as React from 'react';
import { Alert, Box } from '@mui/material';
import dayjs from 'dayjs';
import LoadingButton from '@mui/lab/LoadingButton';
import SelectFiled from '../select/SelectField';
import { IJobDictation } from '../../core/models/dictations/dictations.models';
import { dateFormat } from '../../core/core.constants';

export interface IUserAIReviewHeaderProps {
	isMobile: boolean;
	isConfigChanged: boolean;
	isLoading: boolean;
	jobs: IJobDictation[];
	onReviewChanges: (blobId: string) => void;
}

function UserAIReviewHeader({ isMobile, isLoading, jobs, onReviewChanges, isConfigChanged }: IUserAIReviewHeaderProps) {
	let options = jobs.map((job) => ({
		value: job.blobID,
		text: `${job.patientFirstName} ${job.patientLastName} - ${dayjs(job.createdDateTime).format(dateFormat)}`,
	}));

	const defaultValue = {
		value: 'no_values',
		text: 'No recent notes',
	};

	if (options && options.length === 0) {
		options = [defaultValue];
	}

	const [selectedNote, setSelectedNote] = React.useState<string>(options[0].value);

	return (
		<Box
			sx={{
				display: 'flex',
				flexDirection: 'column',
				py: isMobile ? 0 : 2,
				pl: isMobile ? 1 : 2,
				pr: isMobile ? 1 : 2,
				mt: isMobile ? 1 : undefined,
				width: '100%',
			}}
		>
			<Box
				sx={{
					width: '100%',
					display: 'flex',
					flexDirection: 'row',
					alignItems: 'center',
					justifyContent: 'space-between',
				}}
			>
				<SelectFiled
					sx={{
						width: isMobile ? '100%' : 300,
					}}
					size="small"
					value={selectedNote}
					data={options}
					disabled={selectedNote === 'no_values'}
					onChange={(e) => setSelectedNote(e.target.value)}
				/>
				<LoadingButton
					loading={isLoading}
					sx={{
						height: 40,
						ml: isMobile ? 1 : undefined,
						fontSize: '0.80rem',
					}}
					variant="contained"
					color="primary"
					disabled={selectedNote === 'no_values' || isLoading || isConfigChanged}
					onClick={() => onReviewChanges(selectedNote)}
				>
					Review Changes
				</LoadingButton>
			</Box>
			{isConfigChanged && (
				<Alert
					sx={{ mt: 2 }}
					severity="warning"
				>{`You have done some changes to AI Preferences. You need 'Save Changes' before 'Review Changes'`}</Alert>
			)}
		</Box>
	);
}

export default UserAIReviewHeader;
