import * as React from 'react';
import DiagnosisList, { IDiagnoseItemListProps } from './DiagnosisList';
import { EHRType } from '../../../core/models/ehr.models';

interface IEhrDiagnosisLis extends IDiagnoseItemListProps {
	ehr: EHRType;
}

function EhrDiagnosisList({ ehr, ...props }: IEhrDiagnosisLis) {
	if (ehr === EHRType.NextGen) {
		return (
			<DiagnosisList
				// eslint-disable-next-line react/jsx-props-no-spreading
				{...props}
				diagnoses={props.diagnoses.map((diag) => ({ ...diag, orders: [], problem: true }))}
				onAddOrder={undefined}
				onLateralityChange={undefined}
			/>
		);
	}

	if (ehr === EHRType.AllscriptsTW || ehr === EHRType.AllscriptsPro || ehr === EHRType.PrimeSuite) {
		return (
			<DiagnosisList
				// eslint-disable-next-line react/jsx-props-no-spreading
				{...props}
				onLateralityChange={undefined}
			/>
		);
	}

	// eslint-disable-next-line react/jsx-props-no-spreading
	return <DiagnosisList {...props} />;
}

export default EhrDiagnosisList;
