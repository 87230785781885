import React, { useEffect, useMemo, useState } from 'react';
import { Alert, Autocomplete, Box, Button, LinearProgress, TextField, Typography, useTheme } from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import { styled } from '@mui/material/styles';
import dayjs, { Dayjs } from 'dayjs';
import { useTranslation } from 'react-i18next';
import { DesktopDatePicker } from '@mui/x-date-pickers';
import SelectFiled from '../../components/select/SelectField';
import DataTable from '../../components/data-table/DataTable';
import { namespaces } from '../../application/i18n.constants';
import { dateFormat } from '../../core/core.constants';
import { IDictation, IJobDictation, IJobsSearchFilters } from '../../core/models/dictations/dictations.models';
import {
	DocumentType,
	getJobStatusText,
	JobStatus,
	JobStatusSectionsList,
} from '../../core/models/dictations/document.models';
import { IScriberPermissions, IUserDisplayName } from '../../core/models/users/user.models';
import Spinner from '../../components/spinner/Spinner';
import StatFlag from '../../components/icons/stat-flag.svg';
import { useJobMenu } from '../../components/jobs/JobMenu';
import { EHRType } from '../../core/models/ehr.models';
import { documentTypesForEHR } from '../../core/services/documents/documents.services';

const StyledTextField = styled(TextField)(() => ({
	marginRight: 0,
}));

const StyledSelectField = styled(SelectFiled)(() => ({
	marginRight: 0,
}));

interface IJobsSearchPageProps {
	ehr: EHRType | null;
	isDictatorEnabled: boolean;
	username: string;
	permissions: IScriberPermissions;
	dateParser: (date?: string | null) => Dayjs | undefined;
	dateFormatter: (date?: Dayjs | null) => string | undefined;
	jobsSearchResults: IJobDictation[];
	advancedSearchFilters: IJobsSearchFilters;
	onJobSearch: (filters: IJobsSearchFilters, rest: boolean) => void;
	handleJobClick: (dictation: IJobDictation) => void;
	loading: boolean;
	usersIsLoading: boolean;
	users: IUserDisplayName[];
	validationMessage: {
		message: string;
		errorType: 'requiredFieldsError' | string;
	} | null;
	onUpdateJob: (
		job: IDictation,
		update: {
			documentStatus?: JobStatus;
			documentTypeName?: DocumentType;
			stat?: boolean;
		}
	) => void;
}

function JobsSearchPage({
	ehr,
	isDictatorEnabled,
	username,
	permissions,
	dateParser,
	dateFormatter,
	jobsSearchResults,
	handleJobClick,
	advancedSearchFilters,
	loading,
	usersIsLoading,
	users,
	onJobSearch,
	validationMessage,
	onUpdateJob,
}: IJobsSearchPageProps) {
	const { t } = useTranslation(namespaces.pages.jobsSearch);

	const [patientId, setPatientId] = useState<string | undefined>();
	const [documentStatus, setDocumentStatus] = useState<string | undefined>();
	const [dictatedBy, setDictatedBy] = useState<string | undefined>();
	const [documentType, setDocumentType] = useState<string | undefined>();
	const [transcribedBy, setTranscribedBy] = useState<string | undefined>();
	const [qaBy, setQaBy] = useState<string | undefined>();
	const [dictatedStartDate, setDictatedStartDate] = useState<Dayjs | string | undefined | null>();
	const [dictatedEndDate, setDictatedEndDate] = useState<Dayjs | string | undefined | null>();
	const [sentStartDate, setSentStartDate] = useState<Dayjs | string | undefined | null>();
	const [sentEndDate, setSentEndDate] = useState<Dayjs | string | undefined | null>();

	const { renderDocumentStatusDialog, renderDocumentTypeDialog, renderMenu } = useJobMenu({
		ehr,
		username,
		onUpdateJob,
	});

	useEffect(() => {
		setPatientId(advancedSearchFilters.patientID);
		setDocumentStatus(advancedSearchFilters.documentStatus);
		setDictatedBy(advancedSearchFilters.dictatedBy);
		setDocumentType(advancedSearchFilters.documentType);
		setTranscribedBy(advancedSearchFilters.transcribedBy);
		setQaBy(advancedSearchFilters.qaBy);
		setDictatedStartDate(dateParser(advancedSearchFilters.dictatedStartDate));
		setDictatedEndDate(dateParser(advancedSearchFilters.dictatedEndDate));
		setSentStartDate(dateParser(advancedSearchFilters.sentStartDate));
		setSentEndDate(dateParser(advancedSearchFilters.sentEndDate));
	}, [advancedSearchFilters]);

	const columns = useMemo(() => {
		const cols = [
			{
				name: t('patient'),
			},
			{
				name: t('patientId'),
			},
			{
				name: t('provider'),
			},
			{
				name: t('apptDate'),
			},
			{
				name: t('dictatedDate'),
			},
			{
				name: t('encounterType'),
			},
			{
				name: t('documentStatus'),
			},
			{
				name: t('stat'),
			},
		];

		if (permissions.admin) {
			cols.push({
				name: '',
			});
		}

		return cols;
	}, [t, permissions]);

	const rows = useMemo(() => {
		return jobsSearchResults.map((job) => {
			const rws = {
				data: [
					{
						value:
							job.patientFirstName || job.patientLastName
								? `${job.patientFirstName || ''} ${job.patientLastName || ''}`
								: '',
					},
					{
						value: job.patientID,
					},
					{
						value: job.careProviderName,
					},
					{
						value: dayjs(job.appointmentDateTime).format(dateFormat),
					},
					{
						value: dayjs(job.createdDateTime).format(dateFormat),
					},
					{
						value: job.documentType,
					},
					{
						value: getJobStatusText(job.documentStatus),
					},
					{
						value: job.stat ? <img alt="" src={StatFlag} width="15" /> : '',
					},
				],
			};

			if (permissions.admin) {
				rws.data.push({
					value: renderMenu(job as IDictation),
				});
			}
			return rws;
		});
	}, [jobsSearchResults, permissions]);

	const onPatientIDChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		setPatientId(event.target.value);
	};

	const handleSetDictatedBy = (providerId: string | undefined) => {
		setDictatedBy(providerId);
	};

	const handleSetTranscribedBy = (providerId: string) => {
		setTranscribedBy(providerId);
	};

	const handleSetQaBy = (providerId: string) => {
		setQaBy(providerId);
	};

	const handleAdvancedSearch = (e: React.FormEvent<HTMLFormElement>) => {
		e.preventDefault();

		onJobSearch(
			{
				patientID: patientId,
				documentStatus,
				dictatedBy,
				documentType,
				transcribedBy,
				qaBy,
				dictatedStartDate: dateFormatter(dateParser(dictatedStartDate as string | undefined)),
				dictatedEndDate: dateFormatter(dateParser(dictatedEndDate as string | undefined)),
				sentStartDate: dateFormatter(dateParser(sentStartDate as string | undefined)),
				sentEndDate: dateFormatter(dateParser(sentEndDate as string | undefined)),
			},
			false
		);
	};

	const onRowClick = (rowId: number) => {
		handleJobClick(jobsSearchResults[rowId]);
	};

	const handleResetForm = () => {
		onJobSearch(
			{
				patientID: undefined,
				documentStatus: undefined,
				dictatedBy: undefined,
				documentType: undefined,
				transcribedBy: undefined,
				qaBy: undefined,
				dictatedStartDate: undefined,
				dictatedEndDate: undefined,
				sentStartDate: undefined,
				sentEndDate: undefined,
			},
			true
		);
	};

	const theme = useTheme();

	const isRequiredFieldsError = !(
		(dictatedStartDate && dictatedEndDate) ||
		(sentStartDate && sentEndDate) ||
		patientId?.length
	);

	const getFiledErrorColorStyles = (value?: string) => {
		return {
			'& .MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline': {
				borderColor:
					validationMessage?.errorType === 'requiredFieldsError' && isRequiredFieldsError && !value?.length
						? theme.palette.error
						: 'rgba(0, 0, 0, 0.23)',
			},
			'& .MuiInputLabel-root.Mui-error': {
				color:
					validationMessage?.errorType === 'requiredFieldsError' && isRequiredFieldsError && !value?.length
						? theme.palette.error
						: 'rgba(0, 0, 0, 0.6)',
			},
		};
	};

	const dictatedByOptions = useMemo(() => {
		const values = [
			{ value: undefined, text: '', firstLetter: '' },
			...users.map((x) => ({
				value: x.userKey,
				text: x.displayName,
				firstLetter: x.displayName[0].toUpperCase(),
			})),
		];

		return values.sort((a, b) => -b.firstLetter.localeCompare(a.firstLetter));
	}, [users]);

	const dictatedByValue = useMemo(
		() =>
			dictatedBy
				? {
						value: dictatedBy,
						text: users.find((x) => x.userKey === dictatedBy)?.displayName || '',
						firstLetter: users.find((x) => x.userKey === dictatedBy)?.displayName[0] || '',
				  }
				: {
						firstLetter: '',
						value: '',
						text: '',
				  },
		[dictatedBy, users]
	);

	const documentTypes: DocumentType[] = documentTypesForEHR(ehr);

	return (
		<Box>
			<Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
				<Typography align="left" mt={2} mb={2} variant="h5" sx={{ color: 'primary.main', mr: 1 }}>
					{t('title')}
				</Typography>
				{usersIsLoading && <Spinner size={20} />}
			</Box>
			<form onSubmit={handleAdvancedSearch}>
				<Box
					sx={{
						display: 'flex',
					}}
				>
					<StyledSelectField
						id="job-status"
						type="text"
						fullWidth
						value={documentStatus || ''}
						data={[
							{ value: undefined, text: 'Any' },
							...JobStatusSectionsList.map((x) => ({
								value: x,
								text: getJobStatusText(x),
							})),
						]}
						label={t('jobStatus')}
						onChange={(e) => setDocumentStatus(e.target.value as JobStatus)}
					/>
					{/* eslint-disable react/jsx-props-no-spreading */}
					<Autocomplete
						fullWidth
						id="dictated-by"
						size="medium"
						disabled={!isDictatorEnabled}
						getOptionLabel={(option) => option.text}
						options={dictatedByOptions}
						groupBy={(option) => option.firstLetter}
						value={dictatedByValue}
						onChange={(e, inputValue) => {
							if (inputValue) {
								handleSetDictatedBy(inputValue.value);
							} else {
								handleSetDictatedBy(undefined);
							}
						}}
						renderInput={(params) => <TextField {...params} label={t('dictatedBy')} />}
					/>
					<StyledSelectField
						fullWidth
						id="transcribed-by"
						type="text"
						value={transcribedBy || ''}
						disabled={!users || users.length === 0}
						data={[
							{ value: undefined, text: 'Any' },
							...users
								.map((x) => ({ value: x.userKey, text: x.displayName }))
								.sort((a, b) => -b.text.localeCompare(a.text)),
						]}
						label={t('transcribedBy')}
						onChange={(e) => handleSetTranscribedBy(e.target.value)}
					/>
					<StyledTextField
						fullWidth
						id="patient-id"
						label={t('patientId')}
						value={patientId || ''}
						onChange={onPatientIDChange}
						error={
							validationMessage?.errorType === 'requiredFieldsError' && isRequiredFieldsError && !patientId?.length
						}
					/>
					<StyledSelectField
						fullWidth
						id="document-type"
						type="text"
						value={documentType || ''}
						data={[{ value: undefined, text: 'Any' }, ...documentTypes.map((x) => ({ value: x, text: x }))]}
						label={t('documentType')}
						onChange={(e) => setDocumentType(e.target.value as DocumentType)}
					/>
					<StyledSelectField
						fullWidth
						id="qa-by"
						type="text"
						value={qaBy || ''}
						disabled={!users || users.length === 0}
						data={[{ value: undefined, text: 'Any' }, ...users.map((x) => ({ value: x.userKey, text: x.displayName }))]}
						label={t('qaBy')}
						onChange={(e) => handleSetQaBy(e.target.value)}
					/>
				</Box>
				<Box sx={{ display: 'flex' }}>
					<Box>
						<Typography variant="subtitle2" color="primary" sx={{ p: 1 }}>
							{t('dictationDate')}
						</Typography>
						<DesktopDatePicker
							label={t('startDate')}
							sx={{
								mr: 0.5,
								flex: 1,
								...getFiledErrorColorStyles(dictatedStartDate?.toString()),
							}}
							value={dictatedStartDate || ''}
							onChange={(date) => setDictatedStartDate(date !== '' ? date : undefined)}
						/>
						<DesktopDatePicker
							label={t('endDate')}
							sx={{ mr: 0.5, flex: 1, ...getFiledErrorColorStyles(dictatedEndDate?.toString()) }}
							value={dictatedEndDate || ''}
							onChange={(date) => setDictatedEndDate(date !== '' ? date : undefined)}
						/>
					</Box>
					<Box>
						<Typography variant="subtitle2" color="primary" sx={{ p: 1 }}>
							{t('sentDate')}
						</Typography>
						<DesktopDatePicker
							label={t('startDate')}
							sx={{ mr: 0.5, flex: 1, ...getFiledErrorColorStyles(sentStartDate?.toString()) }}
							value={sentStartDate || ''}
							onChange={(date) => setSentStartDate(date !== '' ? date : undefined)}
						/>
						<DesktopDatePicker
							label={t('endDate')}
							sx={{ mr: 0.5, flex: 1, ...getFiledErrorColorStyles(sentEndDate?.toString()) }}
							value={sentEndDate || ''}
							onChange={(date) => setSentEndDate(date !== '' ? date : undefined)}
						/>
					</Box>
				</Box>
				<Box sx={{ py: 2 }}>
					{validationMessage && (validationMessage?.errorType !== 'requiredFieldsError' || isRequiredFieldsError) && (
						<Alert sx={{ mb: 2 }} severity="warning">
							<Typography>{validationMessage.message}</Typography>
						</Alert>
					)}
					{loading ? (
						<LoadingButton loading variant="contained">
							{t('searchBtnLoading')}
						</LoadingButton>
					) : (
						<Button type="submit" variant="contained">
							{t('search')}
						</Button>
					)}
					<Button onClick={handleResetForm}>{t('reset')}</Button>
				</Box>
			</form>
			<Typography fontWeight="bold" sx={{ fontSize: '1rem', padding: 1.5 }} variant="subtitle1" color="primary">
				{t('searchResults')}
			</Typography>
			{loading ? (
				<Box sx={{ width: '100%' }}>
					<LinearProgress />
				</Box>
			) : (
				<DataTable
					columns={columns}
					rows={rows}
					onRowClick={onRowClick}
					tableContainerProps={{ sx: { height: '50vh', overflowY: 'auto' } }}
				/>
			)}
			{renderDocumentStatusDialog}
			{renderDocumentTypeDialog}
		</Box>
	);
}

export default JobsSearchPage;
