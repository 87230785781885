import { Box, Button, Divider, Typography } from '@mui/material';
import { sortBy } from 'lodash';
import * as React from 'react';
import LoadingButton from '@mui/lab/LoadingButton';
import AutocompleteSelectField from '../select/AutocompleteSelectField';
import { IUserDisplayName } from '../../core/models/users/user.models';

interface IUserAIConfigHeaderProps {
	isMobile: boolean;
	isSaving: boolean;
	enableSelectUser: boolean;
	onSelectUser: (userInfoId: string) => void;
	userInfoId: string;
	users: IUserDisplayName[];
	onSaveChanges: () => void;
}
function UserAIConfigHeader({
	isMobile,
	isSaving,
	enableSelectUser,
	onSelectUser,
	users,
	userInfoId,
	onSaveChanges,
}: IUserAIConfigHeaderProps) {
	const headerText = 'iScribe AI Note Settings';
	return (
		<Box
			sx={{
				zIndex: 10,
				width: '100%',
				backgroundColor: 'white',
			}}
		>
			<Box
				sx={{
					display: 'flex',
					flexDirection: isMobile ? 'column' : 'row',
					alignItems: isMobile ? 'flex-start' : 'center',
					justifyContent: 'space-between',
					width: '100%',
					py: isMobile ? 0 : 2,
					pl: 2,
					pr: isMobile ? 2 : 0,
				}}
			>
				{isMobile && (
					<Box
						sx={{
							width: '100%',
							display: 'flex',
							alignItems: 'center',
							justifyContent: 'flex-end',
							p: 0,
						}}
					>
						<LoadingButton
							loading={isSaving}
							variant="text"
							color="primary"
							onClick={onSaveChanges}
							disabled={isSaving}
						>
							Save Changes
						</LoadingButton>
					</Box>
				)}
				<Box
					sx={{
						width: isMobile ? '100%' : undefined,
					}}
					display="flex"
					flexDirection="row"
					alignItems="center"
				>
					{isMobile && !enableSelectUser ? (
						<Typography color="primary" mr={1} variant="h6" fontWeight="normal" align="center" width="100%">
							{headerText}
						</Typography>
					) : (
						<Typography color="primary" mr={1} variant="h6" fontWeight="normal">
							{headerText}
						</Typography>
					)}
					{enableSelectUser && (
						<AutocompleteSelectField
							textFieldProps={{
								required: true,
								placeholder: 'Select User',
								size: 'small',
							}}
							sx={{ width: isMobile ? '100%' : 300 }}
							fullWidth
							onChange={(e, value) => onSelectUser(value)}
							// groupBy={(option) => option.firstLetter}
							disableClearable
							value={userInfoId}
							data={[
								...sortBy([...users], [(o) => (o.displayName || o.userInfoId)?.toLowerCase()]).map((x) => ({
									value: x.userInfoId,
									text: x.displayName,
								})),
							]}
							size="medium"
						/>
					)}
				</Box>
				{!isMobile && (
					<Button
						sx={{
							height: 40,
							ml: 2,
							fontSize: '0.80rem',
						}}
						variant="contained"
						color="primary"
						onClick={onSaveChanges}
					>
						Save Changes
					</Button>
				)}
			</Box>
			<Divider sx={{ mr: isMobile ? 0 : 4 }} />
		</Box>
	);
}

export default UserAIConfigHeader;
