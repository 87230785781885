import { IServiceResult } from '../../models/service.models';
import {
	IBillingCode,
	IBillingModifier,
	IDictation,
	IDocumentVersions,
	IJobDictation,
	IOpenAISummary,
	IOrderSearchResult,
	IOrderSet,
	IOrdersSearchQuery,
	IProcedureCode,
	IProcedureCodeSearchQuery,
	IProcedureDocumentationTemplate,
} from '../../models/dictations/dictations.models';
import { endpoints } from '../endpoints';
import { getRequest, IApiResponse, mapData, mapResponse, patchRequest, postRequest } from '../api-service';
import {
	IBillingDetailsSearchResponse,
	IBillingModifiersResponse,
	ICancelDocumentCheckOutRequest,
	IDictatedDocumentsTrackingResponse,
	IDocProcedureDocumentationRequest,
	IDocumentCheckInRequest,
	IDocumentCheckInResponse,
	IDocumentCheckOutRequest,
	IDocumentCheckOutResponse,
	IDocumentCheckOutResult,
	IEncounterData,
	IEncounterResponse,
	IGetBillingDetailsSearchQuery,
	IGetDocumentDetailsRequest,
	IGetDocumentDetailsResponse,
	IimoProblemSearchResponse,
	IJobDictationResponse,
	IJobsSearchQuery,
	IOpenAISummaryResponse,
	IOrderDetailsResponse,
	IOrderSetsResponse,
	IOrdersSearchResponse,
	IPreviewDocumentResponse,
	IProcedureCodeSearchResponse,
	IProcedureDocumentationResponse,
	IProcedureDocumentationTemplatesResponse,
	IProviderDictationResponse,
	IProvidersDictationResponse,
	IRequestAISummaryRequest,
	ISaveBillingDetailsRequest,
	ISaveDocumentRequest,
	ISaveDocumentResponse,
} from './dictations.api.models';
import { ICustomPatientDemographicsData, IPatientEncounter } from '../../models/patients/patients.models';
import { mapApiDictation, mapApiQaNotes } from '../../services/documents/documents.services';
import { DocumentType, IEncounterDocumentData } from '../../models/dictations/document.models';

export async function getProvidersDictations(providerIds: string[]): Promise<IServiceResult<IDictation[]>> {
	const body = providerIds.length
		? {
				userInfoIds: encodeURIComponent(providerIds.join()),
		  }
		: {};

	const endpoint = endpoints.documents.dictatedDocumentsWorklist;
	const result = await getRequest<IProvidersDictationResponse>(endpoint, body);

	return mapData<IProvidersDictationResponse, IDictation[]>(result, (res) =>
		res.DictatedDocumentsWorklistData.map((x) => mapApiDictation(x))
	);
}

export async function getDictation(blobId: string): Promise<IServiceResult<IDictation>> {
	const body = {
		blobID: blobId,
	};

	const endpoint = endpoints.documents.dictatedDocumentsWorklistData;
	const result = await getRequest<IProviderDictationResponse>(endpoint, body);

	return mapData<IProviderDictationResponse, IDictation>(result, (res) => mapApiDictation(res));
}

export async function getDocumentDetails(
	request: IGetDocumentDetailsRequest,
	metadata?: { providerId: string | null }
): Promise<IServiceResult<IGetDocumentDetailsResponse>> {
	const body = {
		...request,
	};

	const headers = metadata?.providerId ? { ProviderUserInfoID: metadata.providerId } : {};

	const endpoint = endpoints.documents.documentDetailsGet;
	const result = await postRequest<IGetDocumentDetailsResponse>(endpoint, body, headers);

	return mapData<IGetDocumentDetailsResponse, IGetDocumentDetailsResponse>(result, (x) => x);
}

export function getDocument(request: IGetDocumentDetailsRequest): Promise<IPreviewDocumentResponse> {
	const body = {
		...request,
	};

	const endpoint = endpoints.documents.documentGet;
	return postRequest<IPreviewDocumentResponse>(endpoint, body);
}

export async function getEncounter(
	request: {
		encounterId: string;
		patientId: string;
	},
	metadata?: { providerId: string | null }
): Promise<IPatientEncounter | null> {
	const endpoint = endpoints.documents.encounter;

	const body = request.patientId
		? { encounterId: request.encounterId, patientId: request.patientId }
		: { encounterId: request.encounterId };
	const headers = metadata?.providerId ? { ProviderUserInfoID: metadata.providerId } : {};

	const encounter = await getRequest<IEncounterResponse>(endpoint, body, headers);

	if (!encounter?.IscribeDocument) {
		return null;
	}

	return {
		documentID: encounter.IscribeDocument.DocumentID,
		documentTypeName: encounter.IscribeDocument.DocumentTypeName,
		patientID: encounter.IscribeDocument.PatientID,
		createByFullName: encounter.IscribeDocument.CreateByFullName,
		createDateTime: encounter.IscribeDocument.CreateDateTime,
		documentStatus: encounter.IscribeDocument.DocumentStatus,
		iScribeDocumentStatus: encounter.IscribeDocument.IScribeDocumentStatus,
		lastModifiedDateTime: encounter.IscribeDocument.LastModifiedDateTime,
		renderingProviderFullName: encounter.IscribeDocument.RenderingProvider.FullName,
		title: encounter.IscribeDocument.Title,
		sequenceNumber: encounter.IscribeDocument.SequenceNumber,
		providerId: encounter.IscribeDocument.RenderingProvider.CareProviderID,
	};
}

export async function saveDocument(
	request: ISaveDocumentRequest,
	metadata?: { providerId: string | null }
): Promise<ISaveDocumentResponse> {
	const body = {
		...request,
	};

	const headers = metadata?.providerId ? { ProviderUserInfoID: metadata.providerId } : {};

	const endpoint = endpoints.clinical.documentSave;
	return postRequest<ISaveDocumentResponse>(endpoint, body, headers);
}

export async function documentCheckIn(request: IDocumentCheckInRequest): Promise<IDocumentCheckInResponse> {
	const body = {
		...request,
		IsCanceled: false,
		EncounterData: JSON.stringify(request.EncounterData),
	};

	const endpoint = endpoints.documents.dictatedDocumentsWorklistCheckIn;
	return postRequest<IDocumentCheckInResponse>(endpoint, body);
}

export async function documentCancelCheckOut(
	request: ICancelDocumentCheckOutRequest
): Promise<IDocumentCheckInResponse> {
	const body = {
		...request,
		IsCanceled: true,
	};

	const endpoint = endpoints.documents.dictatedDocumentsWorklistCheckIn;
	return postRequest<IDocumentCheckInResponse>(endpoint, body);
}

const parseEncounterData = (encounterData: string): IEncounterData | null => {
	if (!encounterData || encounterData.length === 0) {
		return null;
	}

	const data = JSON.parse(encounterData) as IEncounterData;

	const storeVersion = Object.getOwnPropertyDescriptor(data, 'storeVersion');
	if (!storeVersion) {
		return null;
	}

	return {
		storeVersion: data.storeVersion,
		document: data.document || null,
		reorderDiagnoses: typeof data.reorderDiagnoses === 'boolean' ? data.reorderDiagnoses : null,
		billingClaimCreated: typeof data.billingClaimCreated === 'boolean' ? data.billingClaimCreated : null,
		billing: data.billing || [],
	};
};

export async function documentCheckOut(
	request: IDocumentCheckOutRequest,
	metadata: { providerId: string | null }
): Promise<IDocumentCheckOutResult> {
	const body = request.encounterID
		? {
				...request,
		  }
		: {
				blobID: request.blobID,
		  };

	const endpoint = endpoints.documents.dictatedDocumentsWorklistCheckOut;
	const headers = metadata?.providerId ? { ProviderUserInfoID: metadata.providerId } : {};
	const response = await getRequest<IDocumentCheckOutResponse>(endpoint, body, headers);

	if (response.Success) {
		const encounterData = parseEncounterData(response.EncounterData);
		return {
			...response,
			EncounterData: encounterData,
		};
	}

	return response as unknown as IDocumentCheckOutResult;
}

export async function getOpenAISummary(
	blobId: string
): Promise<IServiceResult<{ openAISummary: string | null; openAISummaryStructured: IOpenAISummary | null }>> {
	const body = {
		blobId,
	};

	const endpoint = endpoints.dictation.openAISummary;
	const result = await getRequest<IOpenAISummaryResponse>(endpoint, body);

	return mapData<
		IOpenAISummaryResponse,
		{ openAISummary: string | null; openAISummaryStructured: IOpenAISummary | null }
	>(result, (res) => {
		return {
			openAISummary: res.OpenAISummary ?? null,
			openAISummaryStructured: res.OpenAISummaryStructured ? JSON.parse(res.OpenAISummaryStructured) : null,
		};
	});
}

export async function requestAISummary(
	request: IRequestAISummaryRequest,
	metadata?: { providerId: string | null }
): Promise<IServiceResult<void>> {
	const body = {
		...request,
	};

	const headers = metadata?.providerId ? { ProviderUserInfoID: metadata.providerId } : {};

	const endpoint = endpoints.dictation.uploadOpenAISummary;
	const result = await postRequest<IApiResponse>(endpoint, body, headers);

	return mapResponse(result);
}

export async function getDictatedDocumentsTracking(blobId: string): Promise<IServiceResult<IDocumentVersions>> {
	const body = {
		blobID: blobId,
	};

	const endpoint = endpoints.documents.dictatedDocumentsTracking;
	const result = await getRequest<IDictatedDocumentsTrackingResponse>(endpoint, body);

	return mapData<IDictatedDocumentsTrackingResponse, IDocumentVersions>(result, (res) => {
		return {
			qaNotes: mapApiQaNotes(res.QANotes),
			transcription: {
				azureASR: {
					text: res?.AzureASR ? res.AzureASR : null,
					timestampedText: res?.TimestampedDictationResults ? res.TimestampedDictationResults : null,
				},
				mModalASR: res?.MModalASR ? res.MModalASR : null,
				nuanceASR: res?.NuanceASR ? res.NuanceASR : null,
				openAISummary: res?.AISummary ? res.AISummary : null,
				openAISummaryStructured: res?.AISummaryStructured
					? (JSON.parse(res.AISummaryStructured) as IOpenAISummary)
					: null,
				prompt: res?.Prompt ? res.Prompt : null,
				instructions: res?.Instructions ? res.Instructions : null,
				patientDemographics: res?.PatientDemographics
					? (JSON.parse(res?.PatientDemographics) as ICustomPatientDemographicsData)
					: null,
			},
			providerNote: res.ProviderNote || '',
			versions: res.Versions
				? res.Versions.filter((x) => x.EncounterData && x.EncounterData.length > 0).map((x) => {
						const encounterData = parseEncounterData(x.EncounterData) as unknown as IEncounterData;
						return {
							document: encounterData.document as IEncounterDocumentData,
							billingClaimCreated: !!encounterData.billingClaimCreated,
							billing: encounterData.billing as IBillingCode[],
							versionId: x.VersionID,
							username: x.Username,
							displayName: x.DisplayName,
						};
				  })
				: [],
		};
	});
}

export async function imoProblemSearch(searchPhrase: string): Promise<IimoProblemSearchResponse> {
	const capitalizeFirstLetter = (text: string) => text.charAt(0).toUpperCase() + text.slice(1).toLowerCase();
	const body = {
		searchPhrase: capitalizeFirstLetter(searchPhrase),
	};

	const endpoint = endpoints.clinical.imoProblemSearch;
	return postRequest<IimoProblemSearchResponse>(endpoint, body);
}

export async function ordersSearch(
	searchQuery: IOrdersSearchQuery,
	metadata: { providerId: string | null }
): Promise<IServiceResult<IOrderSearchResult[]>> {
	const body = {
		EncounterID: searchQuery.encounterId,
		PatientID: searchQuery.patientId,
		SNOMEDCode: searchQuery.SNOMEDCode,
		SearchPhrase: searchQuery.searchPhrase,
	};

	const endpoint = endpoints.clinical.orderSearch;
	const headers = metadata?.providerId ? { ProviderUserInfoID: metadata.providerId } : {};
	const result = await getRequest<IOrdersSearchResponse>(endpoint, body, headers);

	return mapData<IOrdersSearchResponse, IOrderSearchResult[]>(result, (res) =>
		res.Results.map((x) => {
			return {
				orderID: x.OrderID,
				orderItemID: x.OrderItemID,
				orderTypeID: x.OrderTypeID,
				orderTypeGroup: x.OrderTypeGroup,
				description: x.Description,
			};
		})
	);
}

export async function getOrderSets(metadata?: { providerId?: string | null }): Promise<IServiceResult<IOrderSet[]>> {
	const endpoint = endpoints.clinical.orderSets;
	const headers = metadata?.providerId ? { ProviderUserInfoID: metadata.providerId } : {};
	const result = await getRequest<IOrderSetsResponse>(endpoint, {}, headers);

	return mapData<IOrderSetsResponse, IOrderSet[]>(result, (res) =>
		res.OrderSets.map((x) => {
			return {
				orderSetId: x.OrderSetID,
				orderSetName: x.OrderSetName,
			};
		})
	);
}

export async function getOrderSetDetails(orderSetId: string): Promise<IOrderDetailsResponse> {
	const body = {
		OrderSetID: orderSetId,
	};

	const endpoint = endpoints.clinical.orderSetDetails;
	return getRequest<IOrderDetailsResponse>(endpoint, body);
}

export async function procedureCodeSearch(
	searchQuery: IProcedureCodeSearchQuery,
	metadata?: { providerId: string | null }
): Promise<IServiceResult<IProcedureCode[]>> {
	const body = {
		SearchTerm: searchQuery.searchTerm,
		EncounterId: searchQuery.encounterId,
	};

	const endpoint = endpoints.billing.procedureCodes;
	const headers = metadata?.providerId ? { ProviderUserInfoID: metadata.providerId } : {};
	const result = await getRequest<IProcedureCodeSearchResponse>(endpoint, body, headers);

	return mapData<IProcedureCodeSearchResponse, IProcedureCode[]>(result, (res) =>
		res.ProcedureCodes.map((x) => {
			return {
				procedureCode: x.ProcedureCode,
				description: x.Description,
				serviceType: x.ServiceType,
			};
		})
	);
}

export async function getBillingCodes(
	request: IGetBillingDetailsSearchQuery,
	metadata?: { providerId: string | null }
): Promise<{ billingCodes: IBillingCode[]; claimCreated: boolean; reviewComplete: boolean }> {
	const body = {
		...request,
	};

	const endpoint = endpoints.billing.detailsGet;
	const headers = metadata?.providerId ? { ProviderUserInfoID: metadata.providerId } : {};
	const result = await postRequest<IBillingDetailsSearchResponse>(endpoint, body, headers);

	if (!result.Services) {
		return { billingCodes: [], claimCreated: false, reviewComplete: false };
	}

	const billingCodes = result.Services.map((x) => ({
		serviceID: x.ServiceID,
		serviceType: x.ServiceType,
		procedureCode: x.ProcedureCode,
		iCD10Codes: x.ICD10Codes,
		modifiers: x.Modifiers.map((modifier) => ({
			modifierID: modifier.ModifierID,
			description: modifier.Description,
			isValid: modifier.IsValid,
		})),
		billForService: x.BillForService,
		units: x.Units,
		modified: x.Modified,
		isValid: x.IsValid,
		encounterObjectName: x.EncounterObjectName,
		description: x.Description,
		isSelected: x.IsSelected,
		encounterObjectID: x.EncounterObjectID,
		procedureDocumentationTemplateID: x.ProcedureDocumentationTemplateID,
	}));
	return { billingCodes, claimCreated: result.ClaimCreated, reviewComplete: result.ReviewComplete };
}

const mapBillingCodes = (billingCodes: IBillingCode[]) => {
	return billingCodes.map((x) => {
		return {
			ServiceID: x.serviceID,
			ServiceType: x.serviceType,
			ProcedureCode: x.procedureCode,
			ICD10Codes: x.iCD10Codes,
			Modifiers: x.modifiers.map((modifier) => ({
				ModifierID: modifier.modifierID,
				Description: modifier.description,
				IsValid: modifier.isValid,
			})),
			BillForService: x.billForService,
			Units: x.units,
			Modified: x.modified,
			IsValid: x.isValid,
			EncounterObjectName: x.encounterObjectName,
			Description: x.description,
			IsSelected: x.isSelected,
			EncounterObjectID: x.encounterObjectID,
			ProcedureDocumentationTemplateID: x.procedureDocumentationTemplateID,
		};
	});
};

export async function saveBillingCodes(
	{
		patientId,
		documentId,
		reviewComplete,
		billingCodes,
	}: {
		patientId: string;
		documentId: string;
		reviewComplete: boolean;
		billingCodes: IBillingCode[];
	},
	metadata?: { providerId: string | null }
): Promise<ISaveBillingDetailsRequest> {
	const body = {
		Services: mapBillingCodes(billingCodes),
		ReviewComplete: reviewComplete,
		PatientID: patientId,
		DocumentID: documentId,
	};

	const endpoint = endpoints.billing.detailsSave;
	const headers = metadata?.providerId ? { ProviderUserInfoID: metadata.providerId } : {};
	return postRequest<ISaveBillingDetailsRequest>(endpoint, body, headers);
}

export async function getBillingModifiers(metadata?: {
	providerId: string | null;
}): Promise<IServiceResult<IBillingModifier[]>> {
	const endpoint = endpoints.billing.modifiers;
	const headers = metadata?.providerId ? { ProviderUserInfoID: metadata.providerId } : {};
	const result = await getRequest<IBillingModifiersResponse>(endpoint, {}, headers);

	return mapData<IBillingModifiersResponse, IBillingModifier[]>(result, (res) =>
		res.Modifiers.map((x) => {
			return {
				modifierID: x.ModifierID,
				description: x.Description,
				isValid: x.IsValid,
			};
		})
	);
}

export async function getProcedureDocumentationTemplates({
	documentId,
}: {
	documentId: string;
}): Promise<IServiceResult<IProcedureDocumentationTemplate[]>> {
	const body = {
		TemplateType: 'proceduredocumentation',
		DocumentID: documentId,
	};

	const endpoint = endpoints.template.getProcedureDocumentations;
	const result = await postRequest<IProcedureDocumentationTemplatesResponse>(endpoint, body);
	return mapData<IProcedureDocumentationTemplatesResponse, IProcedureDocumentationTemplate[]>(result, (res) =>
		res.TemplateList.map((x) => {
			return {
				documentTypeId: x.DocumentTypeID,
				ehrTemplateId: x.EHRTemplateId,
				createdDate: x.CreatedDate,
				templateName: x.TemplateName,
				templateText: x.TemplateText,
				templateType: x.TemplateType,
				lastSelectedDate: x.LastSelectedDate,
				userId: x.UserId,
			};
		})
	);
}

export async function getProcedureDocumentation({
	documentId,
	templateId,
}: {
	documentId: string;
	templateId: string;
}): Promise<IServiceResult<{ templateText: string }>> {
	const body = {
		TemplateType: 'proceduredocumentation',
		TemplateId: templateId,
		DocumentID: documentId,
	};

	const endpoint = endpoints.template.getProcedureDocumentation;
	const result = await postRequest<IProcedureDocumentationResponse>(endpoint, body);
	return mapData<IProcedureDocumentationResponse, { templateText: string }>(result, (res) => ({
		templateText: res.Template.TemplateText,
	}));
}

export async function saveProcedureDocumentation({
	documentId,
	patientId,
	procedureDocumentations,
}: {
	documentId: string;
	patientId: string;
	procedureDocumentations: IDocProcedureDocumentationRequest[];
}): Promise<IApiResponse> {
	const body = {
		DocumentID: documentId,
		PatientID: patientId,
		ProcedureDocumentations: procedureDocumentations,
	};
	const endpoint = endpoints.procedureDocumentation.save;
	return postRequest<IApiResponse>(endpoint, body);
}

export async function jobsSearch(query: Partial<IJobsSearchQuery>): Promise<IJobDictation[]> {
	let body: Record<string, string | undefined> = {
		PatientID: query.patientID,
		DictatedBy: query.dictatedBy,
		TranscribedBy: query.transcribedBy,
		QABy: query.qaBy,
		DictatedStartDate: query.dictatedStartDate,
		DictatedEndDate: query.dictatedEndDate,
		SentStartDate: query.sentStartDate,
		SentEndDate: query.sentEndDate,
		DocumentType: query.documentType,
		DocumentStatus: query.documentStatus,
	};

	const removeUndefinedKeys = (obj: Record<string, string | undefined>): Record<string, string | undefined> =>
		Object.fromEntries(Object.entries(obj).filter(([, value]) => value !== undefined));

	body = removeUndefinedKeys(body);

	const endpoint = endpoints.documents.dictatedDocuments;
	const result = await getRequest<IJobDictationResponse[]>(endpoint, body);

	return result.map((x: IJobDictationResponse) => {
		return {
			patientFirstName: x.PatientFirstName,
			patientLastName: x.PatientLastName,
			patientID: x.PatientID,
			careProviderName: x.CareProviderName,
			providerUserInfoID: x.ProviderUserInfoID,
			appointmentDateTime: x.AppointmentDateTime,
			createdDateTime: x.CreatedDateTime,
			documentType: x.DocumentType,
			stat: !!x.STAT,
			blobID: x.BlobID,
			blobUrl: x.BlobUrl,
			documentID: x.DocumentID,
			documentStatus: x.DocumentStatus || '',
			checkedOutBy: x.CheckedOutBy,
			isCheckedOut: x.IsCheckedOut,
			isHeld: x.isHeld,
		};
	});
}

export async function updateDocument(request: {
	blobId: string;
	documentStatus: 'Not Completed' | 'Removed' | 'Complete' | 'Sending' | null;
	documentTypeName: DocumentType | null;
	stat: boolean;
}): Promise<IServiceResult<void>> {
	const endpoint = endpoints.documents.dictatedDocuments;
	const result = await patchRequest<IApiResponse>(endpoint, {
		BlobID: request.blobId,
		DocumentStatus: request.documentStatus,
		DocumentTypeName: request.documentTypeName,
		STAT: request.stat,
	});

	return { ...mapResponse(result), success: result.Success && !result.Errors };
}
