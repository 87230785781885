import * as React from 'react';
import { ForwardedRef, forwardRef, useImperativeHandle, useState } from 'react';
import { Box, IconButton, SxProps, Theme, Typography } from '@mui/material';
import { grey } from '@mui/material/colors';
import AddIcon from '@mui/icons-material/Add';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { useTheme } from '@mui/system';

export type DiagnoseTextFieldType =
	| 'hpiSketchPad'
	| 'rosSketchPad'
	| 'peSketchPad'
	| 'patientGoals'
	| 'patientInstructions'
	| 'discussionNotes'
	| 'assessmentPlan'
	| 'assessment'
	| 'plan';

export type DiagnoseFieldType =
	| DiagnoseTextFieldType
	| 'chiefComplaints'
	| 'billingServices'
	| 'procedureDocumentation';

export interface IFieldPalette {
	bgColor?: string;
	headerHoveredFocusedBgColor?: string;
	headerHoveredBgColor?: string;
}

export interface IFieldProps {
	sx?: SxProps<Theme>;
	children?: React.ReactNode;
	childrenHovered?: boolean;
	childrenFocused?: boolean;
	id: DiagnoseFieldType | string;
	name: string;
	onPull?: (fieldId: string) => void;
	onAdd?: (fieldId: string) => void;
	onAppend?: (fieldId: string) => void;
	onCopy?: (fieldId: string) => void;
	palette?: IFieldPalette;
}

export interface IField {}

const DiagnosisField: React.ForwardRefRenderFunction<IField, IFieldProps> = function DiagnosisFieldFunc(
	{
		sx,
		palette,
		children,
		childrenHovered,
		childrenFocused,
		id,
		name,
		onPull,
		onAdd,
		onAppend,
		onCopy,
		...other
	}: IFieldProps,
	forwardedRef: ForwardedRef<IField>
) {
	const theme = useTheme();

	const [hoveredHeader, setHoveredHeader] = useState<boolean>(false);

	const hovered: boolean = hoveredHeader || !!childrenHovered || !!childrenFocused;

	useImperativeHandle(forwardedRef, () => ({}));

	return (
		// eslint-disable-next-line react/jsx-props-no-spreading
		<Box sx={{ pb: 2, ...sx }} {...other}>
			<Box
				onMouseEnter={() => setHoveredHeader(true)}
				onMouseLeave={() => setHoveredHeader(false)}
				sx={{
					display: 'flex',
					flexDirection: 'row',
					alignItems: 'center',
					// eslint-disable-next-line no-nested-ternary
					backgroundColor: hovered
						? childrenFocused
							? palette?.headerHoveredFocusedBgColor || grey['300']
							: palette?.headerHoveredBgColor || grey['100']
						: undefined,
					height: theme.spacing(4),
				}}
			>
				<Typography color="primary" sx={{ fontWeight: 'medium', fontSize: '1.3rem', mr: 1 }}>
					{name}
				</Typography>
				<Box>
					{hovered && onAdd && (
						<IconButton onClick={() => onAdd(id)}>
							<AddIcon sx={{ fontSize: '1.3rem' }} />
						</IconButton>
					)}
					{hovered && onPull && (
						<IconButton onClick={() => onPull(id)}>
							<ExitToAppIcon sx={{ fontSize: '1.3rem' }} />
						</IconButton>
					)}
					{hovered && onCopy && (
						<IconButton onClick={() => onCopy(id)}>
							<ContentCopyIcon sx={{ fontSize: '1.3rem' }} />
						</IconButton>
					)}
					{hovered && onAppend && (
						<IconButton onClick={() => onAppend(id)}>
							<ArrowForwardIosIcon sx={{ fontSize: '1.3rem' }} />
						</IconButton>
					)}
				</Box>
			</Box>
			{children}
		</Box>
	);
};

export default forwardRef(DiagnosisField);
