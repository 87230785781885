export const userAISettingsConstants = {
	INIT_USER_AI_SETTINGS: 'userAISettings/INIT_USER_AI_SETTINGS',
	INIT_USER_AI_SETTINGS_IS_LOADING: 'userAISettings/INIT_USER_AI_SETTINGS_IS_LOADING',
	SET_USER_AI_SETTINGS: 'userAISettings/SET_USER_AI_SETTINGS',
	SET_USER_LEVEL_AI_PHRASES: 'userAISettings/SET_USER_LEVEL_AI_PHRASES',
	SET_USER_LEVEL_AI_PHRASES_IS_CHANGED: 'userAISettings/SET_USER_LEVEL_AI_PHRASES_IS_CHANGED',
	SAVE_USER_LEVEL_AI_PHRASES: 'userAISettings/SAVE_USER_LEVEL_AI_PHRASES',
	SAVE_USER_LEVEL_AI_PHRASES_IS_LOADING: 'userAISettings/SAVE_USER_LEVEL_AI_PHRASES_IS_LOADING',
	SET_USERS_LIST: 'userAISettings/SET_USERS_LIST',
	SET_JOBS_LIST: 'userAISettings/SET_JOBS_LIST',
	SET_REVIEW_BLOB_ID: 'userAISettings/SET_REVIEW_BLOB_ID',
	GET_AI_SUMMARY: 'userAISettings/GET_AI_SUMMARY',
	SET_LAST_TIME_PROMPT_SUBMITTED: 'userAISettings/SET_LAST_TIME_PROMPT_SUBMITTED',
	UPDATE_PROMPT_AI_SUMMARY: 'userAISettings/UPDATE_PROMPT_AI_SUMMARY',
	SET_PROMPT_AI_SUMMARY: 'userAISettings/SET_PROMPT_AI_SUMMARY',
};
