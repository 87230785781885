import * as env from './env.json';

type EnvType = 'dev' | 'qa' | 'prod';

interface IEnvConfig {
	ENV: EnvType;
	VERSION: string;
	API_URL: string;
	APP_INSIGHTS_CONNECTION_STRING: string;
	LOGROCKET_APP_ID: string;
	SIGNALR_HUB_URL: string;
	INTERCOM_APP_ID: string;
	INTERCOM_TICKET_CENTER_URL: string;
}

// eslint-disable-next-line @typescript-eslint/no-var-requires
const appVersion = require('../../package.json').version;

export const environment: IEnvConfig = {
	...env,
	VERSION: appVersion,
} as IEnvConfig;
