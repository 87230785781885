export type SearchTab = 'jobs' | 'patients';
export type SearchTabPath = '/search/jobs' | '/search/patients';
export type AdminTab = 'providerMacros' | 'customerMacros' | 'wordReplacements' | 'userAISettings';
export type AdminTabPath =
	| '/admin/provider-macros'
	| '/admin/customer-macros'
	| '/admin/wordReplacements'
	| '/admin/user-ai-settings';
export const routes: {
	searchTabs: {
		[key in SearchTab]: SearchTabPath;
	};
	home: string;
	workList: string;
	login: string;
	resetPassword: string;
	accountSettings: string;
	admin: string;
	adminTabs: {
		[key in AdminTab]: AdminTabPath;
	};
	search: string;
	support: string;
	dictation: string;
	dictationView: string;
} = {
	home: '/',
	workList: '/',
	searchTabs: {
		jobs: '/search/jobs',
		patients: '/search/patients',
	},
	login: '/login',
	resetPassword: '/reset-password',
	accountSettings: '/account',
	admin: '/admin',
	adminTabs: {
		providerMacros: '/admin/provider-macros',
		customerMacros: '/admin/customer-macros',
		wordReplacements: '/admin/wordReplacements',
		userAISettings: '/admin/user-ai-settings',
	},
	support: '/support',
	search: '/search',
	dictation: '/dictation',
	dictationView: '/dictation/:blobId/view',
};

export const searchTabs: Record<string, SearchTab> = {
	jobs: 'jobs',
	patients: 'patients',
};
