import { endpoints } from '../endpoints';
import { deleteRequestV2, getRequest, postRequest, putRequestV2 } from '../api-service';
import { IUserAIConfigPhrase, IUserAIConfiguration } from './user-ai-settings.models';

export async function getUserAIConfig({ userInfoId }: { userInfoId: string }): Promise<IUserAIConfiguration> {
	const endpoint = endpoints.user.aiConfig;
	return getRequest<IUserAIConfiguration>(endpoint, { userId: userInfoId });
}

export async function saveUserAIConfig({
	userInfoId,
	functionName,
	phrases,
}: {
	userInfoId: string;
	functionName: string;
	phrases: IUserAIConfigPhrase[];
}): Promise<void> {
	const endpoint = endpoints.user.userAIConfigPhrase;
	const body = {
		FunctionName: functionName,
		UserAIConfigPhrases: phrases.map((x) => ({
			ID: x.ID,
			UserInfoID: x.UserInfoID,
			AIFunctionID: x.AIFunctionID,
			AIPhraseID: x.AIPhraseID,
		})),
		UserId: userInfoId,
	};
	await putRequestV2(endpoint, body);
}

export async function deleteSectionMapping({
	userInfoId,
	source,
	destination,
}: {
	userInfoId: string;
	source: string;
	destination: string;
}) {
	const endpoint = endpoints.user.sectionMapping;
	const body = {
		UserId: userInfoId,
		SourceName: source,
		DestinationName: destination,
	};
	return deleteRequestV2<void>(endpoint, body, {}, true, {
		forcePayloadBody: true,
	});
}

export async function createSectionMapping({
	userInfoId,
	source,
	destination,
}: {
	userInfoId: string;
	source: string;
	destination: string;
}) {
	const endpoint = endpoints.user.sectionMapping;
	const body = {
		UserId: userInfoId,
		SourceName: source,
		DestinationName: destination,
	};
	return postRequest<void>(endpoint, body);
}
