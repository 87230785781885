import * as React from 'react';
import { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useMediaQuery, useTheme } from '@mui/material';
import { actions } from '../../core/state/actions';
import { useAppDispatch, useAppSelector } from '../../core/core.types';
import { IScriberPermissions, IUserDisplayName } from '../../core/models/users/user.models';
import Spinner from '../../components/spinner/Spinner';
import UserAISettingsPage from './UserAISettingsPage';
import PageContainer from '../../components/page-container/PageContainer';
import { IUserAIConfigPhrase } from '../../core/api/user/user-ai-settings.models';
import { IJobDictation, IOpenAISummary } from '../../core/models/dictations/dictations.models';
import { EHRType } from '../../core/models/ehr.models';

function UserAISettings() {
	const dispatch = useAppDispatch();
	const [searchParams, setSearchParams] = useSearchParams();

	const theme = useTheme();
	const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

	const uid: string | null = searchParams.get('uid');
	const ehr = useAppSelector<EHRType | null>((state) => state.user.settings.system);
	const currentUser = useAppSelector((state) => state.user.settings);
	const loading = useAppSelector<boolean>((state) => state.userAISettings.loading);
	const users = useAppSelector<IUserDisplayName[]>((state) => state.userAISettings.users);
	const jobs = useAppSelector<IJobDictation[]>((state) => state.userAISettings.jobs);
	const userAIConfiguration = useAppSelector((state) => state.userAISettings.configuration);
	const permissions: IScriberPermissions = useAppSelector((state) => state.user.settings.permissions);
	const isSaving: boolean = useAppSelector((state) => state.userAISettings.saving);
	const transcription: { openAISummary: string | null; openAISummaryStructured: IOpenAISummary | null } =
		useAppSelector((state) => state.userAISettings.transcription);
	const isConfigChanged = useAppSelector<boolean>((state) => state.userAISettings.edited);

	const user = uid ?? currentUser?.userInfoId;

	const [isDiscreteDiagnoses, setIsDiscreteDiagnoses] = React.useState<boolean>(
		userAIConfiguration.IsDiscreteDiagnoses
	);

	const lastTimePromptSubmitted = useAppSelector<number | null>(
		(state) => state.userAISettings.transcription.lastTimePromptSubmitted
	);

	const [isAIPromptLoading, setIsAIPromptLoading] = useState(false);

	useEffect(() => {
		if (user) {
			dispatch(actions.userAISettings.initUserAISettings({ userInfoId: user }));
		}
	}, [user]);

	useEffect(() => {
		setIsDiscreteDiagnoses(userAIConfiguration.IsDiscreteDiagnoses);
	}, [userAIConfiguration]);

	const onUserSelect = (userInfoId: string) => {
		setSearchParams({ uid: userInfoId });
	};

	const onConfigurationChange = (userAIConfigPhrases: IUserAIConfigPhrase[]) => {
		dispatch(actions.userAISettings.setSaveUserLevelAIPhrasesIsChanged(true));
		dispatch(actions.userAISettings.setUserLevelAIPhrases(userAIConfigPhrases));
	};
	const onDiscreteDiagnosisChange = (discreteDiagnoses: boolean) => {
		setIsDiscreteDiagnoses(discreteDiagnoses);
	};

	const onSaveChanges = () => {
		dispatch(
			actions.userAISettings.saveUserLevelAIPhrases({
				userInfoId: user,
				configurations: userAIConfiguration,
				isDiscreteDiagnoses,
			})
		);
	};

	const onReviewChanges = (blobId: string) => {
		const job = jobs.find((j) => j.blobID === blobId);
		if (!job) {
			return;
		}
		dispatch(
			actions.userAISettings.getAISummary({
				blobId,
				documentType: job.documentType,
				documentID: job.documentID,
				appointmentID: null,
			})
		);
	};

	const timedOut = (time: number) => {
		return new Date().getTime() - time < 120 * 1000;
	};

	useEffect(() => {
		if (lastTimePromptSubmitted) {
			setIsAIPromptLoading(timedOut(lastTimePromptSubmitted));
			const interval = setInterval(() => {
				setIsAIPromptLoading(timedOut(lastTimePromptSubmitted));
			}, 5 * 1000);

			// Clear the interval when the component is unmounted or value changes
			return () => clearInterval(interval);
		}
		setIsAIPromptLoading(false);
		return () => {};
	}, [lastTimePromptSubmitted]);

	useEffect(() => {
		if (!isAIPromptLoading && lastTimePromptSubmitted && !timedOut(lastTimePromptSubmitted)) {
			toast.warning('AI Summary request timed out');
		}
	}, [isAIPromptLoading]);

	if (loading) {
		return (
			<PageContainer
				navbar={!isMobile}
				sx={{ display: 'flex', flex: 1, alignItems: 'center', justifyContent: 'center' }}
			>
				<Spinner />
			</PageContainer>
		);
	}

	return (
		<UserAISettingsPage
			isMobile={isMobile}
			ehr={ehr as EHRType}
			enableSelectUser={permissions.admin}
			isConfigChanged={isConfigChanged}
			isSaving={isSaving}
			userInfoId={user}
			users={users}
			jobs={jobs}
			summaryIsLoading={isAIPromptLoading}
			transcription={transcription}
			configuration={{ ...userAIConfiguration, IsDiscreteDiagnoses: isDiscreteDiagnoses }}
			onConfigurationChange={onConfigurationChange}
			onDiscreteDiagnosisChange={onDiscreteDiagnosisChange}
			onSelectUser={onUserSelect}
			onSaveChanges={onSaveChanges}
			onReviewChanges={onReviewChanges}
		/>
	);
}

export default UserAISettings;
