import * as React from 'react';
import { Avatar, Box, Card } from '@mui/material';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';
import PatientCardHeader from './PatientCardHeader';
import Patient from './patient.svg';
import Staff from './staff.svg';
import { dateFormat } from '../../core/core.constants';
import { namespaces } from '../../application/i18n.constants';
import {
	ICustomPatientDemographicsData,
	IPatientDemographic,
	IPatientEncounter,
} from '../../core/models/patients/patients.models';
import { getPatientName } from '../../core/services/patients/patients.services';

interface IKnownPatientProps {
	stat: boolean;
	demographic: IPatientDemographic;
	customDemographics?: undefined;
	integratedPatient?: undefined;
	disableEncounter?: boolean;
	encounter?: IPatientEncounter;
	encountersLoading?: boolean;
	changeEncounterDisabled?: boolean;
	changePatientDisabled?: boolean;
	onPatientUpdateClick?: () => void;
	onPatientChangeClick?: (reset: boolean) => void;
	onChangeEncounterClick?: () => void;
}

interface IUnknownPatientProps {
	stat?: undefined;
	demographic?: undefined;
	customDemographics?: ICustomPatientDemographicsData;
	integratedPatient?: boolean;
	disableEncounter?: undefined;
	encounter?: undefined;
	encountersLoading?: undefined;
	changeEncounterDisabled?: undefined;
	changePatientDisabled?: undefined;
	onPatientChangeClick: (reset: boolean) => void;
	onPatientUpdateClick?: () => void;
	onChangeEncounterClick?: undefined;
}

function isEmptyOrSpaces(str: string | null | undefined) {
	return str === undefined || str === null || str.match(/^ *$/) !== null;
}

export default function PatientDetails({
	stat,
	customDemographics,
	integratedPatient,
	demographic,
	encountersLoading,
	disableEncounter,
	encounter,
	changeEncounterDisabled,
	changePatientDisabled,
	onPatientChangeClick,
	onPatientUpdateClick,
	onChangeEncounterClick,
}: IKnownPatientProps | IUnknownPatientProps) {
	const { t } = useTranslation(namespaces.components.patientDetails);

	const custPatientDemographics = customDemographics
		? (customDemographics as ICustomPatientDemographicsData).PatientDemographics
		: null;

	const formattedDOB = (dob?: string) => (dob ? `(${dayjs(dob).format(dateFormat)})` : '');

	const age = (dob?: string) => dayjs().diff(dob, 'years');
	const formattedAge = (dob?: string) => `${age(dob)}yo`;

	const encDate = (createdDateTime?: string) => (createdDateTime ? dayjs(createdDateTime).format(dateFormat) : null);
	const enc = (createdDateTime?: string) =>
		encDate(createdDateTime) ? `${t('enc')}: ${encDate(createdDateTime)}` : '';

	const title = (firstName: string, middleName: string, lastName: string, dob: string) =>
		`${getPatientName(firstName, middleName, lastName)} ${formattedDOB(dob)}`;
	const subtitle = (gender: string, dob: string) => `${gender} ${formattedAge(dob)}`;
	const subheader = (patientId: string) => `${t('patientID')}: ${patientId}`;

	return (
		<Box
			sx={{
				display: 'flex',
				flexDirection: 'column',
			}}
		>
			<Card sx={{ boxShadow: 'none' }}>
				{/* eslint-disable-next-line no-nested-ternary */}
				{demographic ? (
					<>
						<PatientCardHeader
							avatar={<Avatar alt="" src={Patient} />}
							title={title(
								demographic.firstName,
								demographic.middleName,
								demographic.lastName,
								demographic.dateOfBirth
							)}
							subtitle={subtitle(demographic.genderText, demographic.dateOfBirth)}
							subheader={subheader(demographic.patientID)}
							changePatientDisabled={changePatientDisabled}
							onPatientChangeClick={onPatientChangeClick ? () => onPatientChangeClick(true) : undefined}
							dataPrivate
						/>
						{!disableEncounter && (
							<PatientCardHeader
								avatar={<Avatar alt="" src={Staff} />}
								title={
									// eslint-disable-next-line no-nested-ternary
									encounter
										? !isEmptyOrSpaces(encounter.renderingProviderFullName)
											? encounter.renderingProviderFullName
											: 'Provider name not available'
										: t('noEncounterSelected')
								}
								subheader={enc(encounter?.createDateTime)}
								stat={stat}
								noEncounterSelected={!encounter}
								encountersLoading={encountersLoading}
								onChangeEncounterClick={onChangeEncounterClick}
								changeEncounterDisabled={changeEncounterDisabled}
							/>
						)}
					</>
				) : custPatientDemographics ? (
					<PatientCardHeader
						avatar={<Avatar alt="" src={Patient} />}
						title={title(
							custPatientDemographics.PatientFirstName || '',
							'',
							custPatientDemographics.PatientLastName || '',
							custPatientDemographics.DateOfBirth || ''
						)}
						subtitle={`${custPatientDemographics.Gender} ${formattedAge(custPatientDemographics.DateOfBirth)}`}
						subheader={`Patient MRN: ${custPatientDemographics.MRN}`}
						onPatientUpdateClick={!integratedPatient ? onPatientUpdateClick : undefined}
						onPatientChangeClick={integratedPatient ? () => onPatientChangeClick(true) : undefined}
						dataPrivate
					/>
				) : (
					<PatientCardHeader
						avatar={<Avatar alt="" src={Patient} />}
						noPatientSelected
						onPatientChangeClick={onPatientChangeClick ? () => onPatientChangeClick(true) : undefined}
					/>
				)}
			</Card>
		</Box>
	);
}
