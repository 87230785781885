import { IApiResponse } from '../api-service';
import { EHRType } from '../../models/ehr.models';
import { MacroSection } from '../../models/macros/macros.models';
import { IScriberPermissions } from '../../models/users/user.models';

interface IFeatureFlagItem {
	FeatureId: number;
	IsCustomerOverride: boolean;
	IsEnabled: boolean;
	IsOverride: boolean;
}

export interface IApiUserCustomerPermissionsItem {
	CustomerID: number;
	CustomerName: string;
	EhrUserID: string | null;
	EhrUsername: string | null;
	UserCustomerProviderPermissions: Array<IUserCustomerProviderPermissionsItem>;
}

export interface IApiUserCustomerPermissionsItemResponse extends IApiResponse {
	UserCustomerPermission: IApiUserCustomerPermissionsItem[];
}

export interface IUserCustomerProviderPermissionsItem {
	ProviderID: string;
	ProviderUserInfoID: string;
	ProviderUsername: string;
}

export const ScribePermission = {
	QA: 'QA',
	Admin: 'Admin',
	AIAccess: 'AIAccess',
	AllCustomers: 'AllCustomers',
	TenantAccess: 'TenantAccess',
	DownloadNotes: 'DownloadNotes',
	AIPreferences: 'AIPreferences',
};

export const hasPermission = (permissions: IScriberPermissions, permission: string) => {
	switch (permission) {
		case ScribePermission.QA:
			return permissions.qa;
		case ScribePermission.Admin:
			return permissions.admin;
		case ScribePermission.AIAccess:
			return permissions.aiAccess;
		case ScribePermission.AllCustomers:
			return permissions.allCustomers;
		case ScribePermission.TenantAccess:
			return permissions.tenantAccess;
		case ScribePermission.DownloadNotes:
			return permissions.downloadNotes;
		case ScribePermission.AIPreferences:
			return permissions.aiPreferences;
		default:
			return false;
	}
};
export const checkUserPermissions = (permissions: IScriberPermissions, requiredPermissions?: string[]) => {
	if (!requiredPermissions || requiredPermissions.length === 0) {
		return true;
	}
	return requiredPermissions.reduce((acc, permission) => acc && hasPermission(permissions, permission), true);
};

export interface IUserAuthResponse extends IApiResponse {
	ApiKey: string;
	BearerToken: string;
	MFARequired?: boolean;
	InvalidMFAPin?: boolean;
	MFAAuthorizationToken?: string;
	FeatureFlags: {
		AutoSelectSingleDocument: IFeatureFlagItem;
		ViewBillingServices: IFeatureFlagItem;
		EditBillingServices: IFeatureFlagItem;
		EditProcedureDocumentation: IFeatureFlagItem;
		SaveOrderInterpretations: IFeatureFlagItem;
		VSSForceAllJobsToQA: IFeatureFlagItem;
		RetrieveOpenAISummary: IFeatureFlagItem;
		EHRLogin: IFeatureFlagItem;
		OpenAIBypassVSS: IFeatureFlagItem;
		Transcription: IFeatureFlagItem;
		SpeechToText: IFeatureFlagItem;
		UseNewSupportOption: IFeatureFlagItem;
	};
	UserCustomerPermissions: IApiUserCustomerPermissionsItem[];
	ScribePermissions: string[];
	Username: string;
	CustomerId: string;
	CustomerName: string;
	DisplayName: string;
	EmailAddress: string;
	UserInfoID: string;
	DepartmentId: string;
	iScribeUsername: string;
	IntercomUserHash: string;
	System: EHRType;
}

export interface IValidateEhrLoginResponse {
	StatusMessage?: string;
	Message?: string;
	Errors?: string;
	Success?: boolean;
	DaysUntilExpiration?: null | number;
}

export interface IGetUsersResponse {
	UserInfoID: number;
	UserKey: string;
	DisplayName: string;
}

export interface IForgotPasswordResponse extends IApiResponse {
	VerificationCode: string | null;
}

export interface IForgotPasswordRequest {
	NewPassword?: string;
	ConfirmPassword?: string;
	Username: string;
	AppVerifiedPin?: number;
	AppVersion?: string;
	MobileNumber?: string;
	EmailAddress?: string;
	VerificationPin?: string;
}

export interface IValidatePinRequest {
	Username: string;
	VerificationPin: string;
}

export interface IProviderMacroResponseItem {
	MacroID: number;
	Section: MacroSection;
	ShortCode: string;
	Description: string;
	Macro: string;
	SpokenForm: string;
}

export interface IProviderWordReplacementResponseItem {
	ID: string;
	UserInfoID: string;
	OriginalText: string;
	ReplacementText: string;
}

export interface IMacroCreateResult {
	Success: boolean;
	MacrosCount: number;
	MacrosCreated: number;
}
