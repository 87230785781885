import { call, put, select } from 'redux-saga/effects';
import dayjs from 'dayjs';
import { toast } from 'react-toastify';
import { actions, IAction } from '../actions';
import {
	IGetAISummaryActionPayload,
	IInitUserAISettingsActionPayload,
	ISaveUserLevelAIPhrasesActionPayload,
} from './user-ai-settings.actions';
import { handleGlobalException } from '../../../application/exception-handlers';
import {
	createSectionMapping,
	deleteSectionMapping,
	getUserAIConfig,
	saveUserAIConfig,
} from '../../api/user/user-ai-settings.api';
import { IUserAIConfiguration } from '../../api/user/user-ai-settings.models';
import { getUsers } from '../../api/user/user.api';
import { IUserDisplayName } from '../../models/users/user.models';
import { getOpenAISummary, jobsSearch, requestAISummary } from '../../api/dictations/dictations.api';
import { IJobsSearchQuery, IRequestAISummaryRequest } from '../../api/dictations/dictations.api.models';
import { IJobDictation, IOpenAISummary } from '../../models/dictations/dictations.models';
import { dateFormat } from '../../core.constants';
import { getAiSummary } from '../../services/dictations/dictations-services';
import { IServiceResult } from '../../models/service.models';
import { IUpdatePromptAISummaryActionPayload } from '../worklist/worklist.actions';
import { AppState } from '../../core.types';
import { defaultSearchDocumentType } from '../../services/documents/documents.services';
import { EHRType } from '../../models/ehr.models';

export function* initUserAISettingsSaga(action: IAction<IInitUserAISettingsActionPayload>) {
	yield put(actions.userAISettings.initUserAISettingsIsLoading(true));
	try {
		const { userInfoId } = action.payload;
		const userAIConfiguration: IUserAIConfiguration = yield call(getUserAIConfig, { userInfoId });
		const ehr: EHRType = yield select((state: AppState) => state.user.settings.system);
		yield put(actions.userAISettings.setUserAISettings({ userInfoId, configurations: userAIConfiguration }));

		const usersList: IUserDisplayName[] = yield call(getUsers);
		yield put(actions.userAISettings.setUsersList(usersList));

		const apiKey = usersList.find((x) => x.userInfoId === userInfoId)?.userKey;

		const searchDocumentType = defaultSearchDocumentType(ehr);

		const jobSearchParams: IJobsSearchQuery = {
			dictatedBy: apiKey,
			dictatedStartDate: dayjs().subtract(90, 'days').format(dateFormat),
			dictatedEndDate: dayjs().format(dateFormat),
			documentStatus: 'Complete',
			documentType: searchDocumentType.length === 1 ? searchDocumentType[0] : undefined,
		};

		if (!jobSearchParams.documentType) {
			delete jobSearchParams.documentType;
		}

		let jobsList: IJobDictation[] = yield call(jobsSearch, jobSearchParams);
		jobsList = jobsList
			.sort((a, b) => new Date(b.createdDateTime).getTime() - new Date(a.createdDateTime).getTime())
			.slice(0, 5);
		yield put(actions.userAISettings.setJobsList(jobsList));
	} catch (e) {
		handleGlobalException(e);
	} finally {
		yield put(actions.userAISettings.initUserAISettingsIsLoading(false));
	}
}

export function* saveUserLevelAIPhrases(action: IAction<ISaveUserLevelAIPhrasesActionPayload>) {
	try {
		yield put(actions.userAISettings.setSaveUserLevelAIPhrasesIsLoading(true));

		const currentIsDiscreteDiagnoses: boolean = yield select(
			(state) => state.userAISettings.configuration.IsDiscreteDiagnoses
		);

		const { configurations, userInfoId, isDiscreteDiagnoses } = action.payload;
		yield call(saveUserAIConfig, {
			userInfoId,
			functionName: configurations.FunctionName,
			phrases: configurations.UserConfigPhrases,
		});

		try {
			if (isDiscreteDiagnoses !== currentIsDiscreteDiagnoses) {
				if (isDiscreteDiagnoses) {
					yield call(createSectionMapping, {
						userInfoId,
						source: 'Diagnoses',
						destination: 'Diagnoses',
					});
				} else {
					yield call(deleteSectionMapping, {
						userInfoId,
						source: 'Diagnoses',
						destination: 'Diagnoses',
					});
				}
			}
		} catch (e) {
			handleGlobalException(e);
		}

		const userAIConfiguration: IUserAIConfiguration = yield call(getUserAIConfig, { userInfoId });
		yield put(actions.userAISettings.setUserAISettings({ userInfoId, configurations: userAIConfiguration }));
		yield put(actions.userAISettings.setSaveUserLevelAIPhrasesIsChanged(false));
		yield put(actions.userAISettings.setPromptAISummary({ openAISummary: null, openAISummaryStructured: null }));
	} catch (e) {
		handleGlobalException(e);
	} finally {
		yield put(actions.userAISettings.setSaveUserLevelAIPhrasesIsLoading(false));
	}
}

export function* getAISummarySaga(action: IAction<IGetAISummaryActionPayload>) {
	const ehr: EHRType = yield select((state: AppState) => state.user.settings.system);

	yield put(actions.userAISettings.setReviewBlobId(action.payload.blobId));
	const errorMessage = 'Something went wrong during requesting AI summary';

	const searchDocumentType = defaultSearchDocumentType(ehr);

	const data: IRequestAISummaryRequest = getAiSummary({
		blobId: action.payload.blobId,
		aiModel: null,
		prompt: '',
		instructions: '',
		documentType: action.payload.documentType || searchDocumentType.length === 1 ? searchDocumentType[0] : '',
		appointmentID: null,
		documentID: null,
		patientDemographic: null,
	});

	yield put(actions.userAISettings.setPromptAISummary({ openAISummary: null, openAISummaryStructured: null }));

	try {
		yield put(actions.userAISettings.setLastTimePromptSubmitted(new Date().getTime()));
		const result: IServiceResult<void> = yield call(requestAISummary, data);

		if (!result.success) {
			toast.error(errorMessage);
		}
	} catch (e) {
		toast.error(errorMessage);
		handleGlobalException(e);
	}
}

export function* updateAISettingsSummarySaga(action: IAction<IUpdatePromptAISummaryActionPayload>) {
	const reviewBlobId: string | undefined = yield select((state: AppState) => state.userAISettings.reviewBlobId);

	const errorMessage = 'Something went wrong during requesting AI summary';

	if (reviewBlobId !== action.payload.blobId) {
		return;
	}

	try {
		const aiSummaryResult: IServiceResult<{
			openAISummary: string | null;
			openAISummaryStructured: IOpenAISummary | null;
		}> = yield call(getOpenAISummary, action.payload.blobId);

		if (
			aiSummaryResult.success &&
			(aiSummaryResult.data.openAISummary || aiSummaryResult.data.openAISummaryStructured)
		) {
			yield put(
				actions.userAISettings.setPromptAISummary({
					openAISummary: aiSummaryResult.data.openAISummary,
					openAISummaryStructured: aiSummaryResult.data.openAISummaryStructured,
				})
			);
			yield put(actions.userAISettings.setLastTimePromptSubmitted(null));
			// toast.success('AI summary updated');
		} else {
			toast.error(errorMessage);
		}
	} catch (e) {
		toast.error(errorMessage);
		handleGlobalException(e);
	} finally {
		yield put(actions.userAISettings.setReviewBlobId(null));
	}
}
