import * as React from 'react';
import { Box, Button, Menu, Stack, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import BillingServicesItemsList, {
	BillingServicesFeatures,
	IBillingServicesItemsListProps,
} from './BillingServiceItemList';
import { IBillingCode, IBillingModifier } from '../../core/models/dictations/dictations.models';
import { namespaces } from '../../application/i18n.constants';
import BillingServicesListContainer from './BillingServicesListContainer';
import Spinner from '../spinner/Spinner';
import { isBillingServiceSelected } from '../../core/services/billingCodes/billing-codes.services';

type BillingCodeGroupKey = 'procedureDocumentationTemplateID' | 'encounterObjectID';

function groupBillingServicesByTemplateId(
	billingServices: IBillingCode[],
	groupByKey: BillingCodeGroupKey
): {
	[key: string]: IBillingCode[];
} {
	const keyBillingServices: {
		[key: string]: IBillingCode[];
	} = {};

	billingServices.forEach((billingService) => {
		if (billingService[groupByKey]) {
			if (Object.keys(keyBillingServices).find((key) => key === billingService[groupByKey])) {
				keyBillingServices[billingService[groupByKey] as BillingCodeGroupKey].push(billingService);
			} else {
				keyBillingServices[billingService[groupByKey] as BillingCodeGroupKey] = [billingService];
			}
		}
	});

	return keyBillingServices;
}

interface IBillingServicesWithSuggestionsProps extends Omit<IBillingServicesItemsListProps, 'onBillingCodeDelete'> {
	title?: string;
	warning?: string;
	onSelectBillingCode: (billingCode: IBillingCode, select: boolean) => void;
}
function BillingServicesWithSuggestions({
	readonly,
	title,
	warning,
	billingCodes,
	billingModifiersLoading,
	billingModifiers,
	icd10Codes,
	onIcd10CodeSelect,
	onIcd10CodeDelete,
	onBillingModifierSelect,
	onBillingModifierDelete,
	onBillingCodeUnitChange,
	onBillForServiceToggle,
	onSelectBillingCode,
	disabledFeatures,
}: IBillingServicesWithSuggestionsProps) {
	const { t } = useTranslation(namespaces.components.billingDetails);

	const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
	const menuOpen = Boolean(anchorEl);

	const onAddServiceOpen = (event: React.MouseEvent<HTMLButtonElement>) => {
		setAnchorEl(event.currentTarget);
	};

	const onAddServiceClose = () => {
		setAnchorEl(null);
	};

	const onSelect = (billingCode: IBillingCode) => {
		onAddServiceClose();
		onSelectBillingCode(billingCode, true);
	};

	const onDeselect = (billingCode: IBillingCode) => {
		onAddServiceClose();
		onSelectBillingCode(billingCode, false);
	};

	const toBeAdded = (x: IBillingCode) => !isBillingServiceSelected(x);
	const addedBillingServices = billingCodes.filter((b) => b.modified !== 2).filter((x) => !toBeAdded(x));

	const billingServicesToBeAdded = billingCodes.filter(
		(x) => toBeAdded(x) && !addedBillingServices.find((y) => y.procedureCode === x.procedureCode)
	);

	if (readonly && addedBillingServices.length === 0) {
		return null;
	}

	return (
		<Box sx={{ pt: 2 }}>
			<Typography fontWeight="bold">{title}</Typography>
			{warning ? (
				<Stack direction="row" alignItems="center" gap={1}>
					<WarningAmberIcon color="warning" sx={{ fontSize: '1.3rem' }} />
					<Typography>{warning}</Typography>
				</Stack>
			) : null}
			<BillingServicesItemsList
				readonly={readonly}
				billingModifiersLoading={billingModifiersLoading}
				billingModifiers={billingModifiers}
				billingCodes={addedBillingServices}
				icd10Codes={icd10Codes}
				onIcd10CodeSelect={onIcd10CodeSelect}
				onIcd10CodeDelete={onIcd10CodeDelete}
				onBillingModifierSelect={onBillingModifierSelect}
				onBillingModifierDelete={onBillingModifierDelete}
				onBillingCodeUnitChange={onBillingCodeUnitChange}
				onBillingCodeDelete={onDeselect}
				onBillForServiceToggle={onBillForServiceToggle}
				disabledFeatures={disabledFeatures}
			/>
			{billingServicesToBeAdded.length > 0 ? (
				<Box>
					<Button fullWidth variant="contained" onClick={onAddServiceOpen}>
						{title ? t('addBillingServiceForProcedure', { procedure: title }) : t('addBillingService')}
					</Button>
					<Menu
						id="addBillingServiceForProcedureTemplateMenu"
						anchorOrigin={{ horizontal: 'center', vertical: 'center' }}
						anchorEl={anchorEl}
						open={menuOpen}
						onClose={onAddServiceClose}
						MenuListProps={{
							'aria-labelledby': 'basic-button',
						}}
					>
						<Box display="flex" flexDirection="column">
							{billingServicesToBeAdded.map((x) => (
								<Button key={x.procedureCode} onClick={() => onSelect(x)}>
									<Box flexDirection="column" justifyContent="center" alignItems="center">
										<Typography fontWeight="bold">{x.procedureCode}</Typography>
										<Typography>{x.description}</Typography>
									</Box>
								</Button>
							))}
						</Box>
					</Menu>
				</Box>
			) : null}
		</Box>
	);
}
export interface IBillingServicesSectionProps {
	billingCodes: IBillingCode[];
	readonly: boolean;
	isLoading: boolean;
	procedureDocumentationTemplates: string[];
	icd10Codes: Array<string>;
	hasChangesThatMayAffectBillingServicesData: boolean;
	onIcd10CodeSelect: (billingCode: IBillingCode, icd10Code: string) => void;
	onIcd10CodeDelete: (billingCode: IBillingCode, icd10Code: string) => void;
	billingModifiersLoading: boolean;
	billingModifiers: IBillingModifier[];
	onBillingModifierSelect: (billingCode: IBillingCode, modifier: IBillingModifier) => void;
	onBillingModifierDelete: (billingCode: IBillingCode, modifier: IBillingModifier) => void;
	onBillingCodeDelete: (billingCode: IBillingCode) => void;
	onBillingCodeUnitChange: (billingCode: IBillingCode, units: number | null) => void;
	onBillForServiceToggle: (billingCode: IBillingCode, bill: boolean) => void;
	onApplyAllIcdCodes: (billingCodes: IBillingCode[]) => void;
	onSelectBillingCode: (billingCode: IBillingCode, select: boolean) => void;
	disabledFeatures: BillingServicesFeatures[];
}

function BillingServicesSection({
	billingCodes,
	readonly,
	procedureDocumentationTemplates,
	isLoading,
	icd10Codes,
	hasChangesThatMayAffectBillingServicesData,
	onIcd10CodeSelect,
	onIcd10CodeDelete,
	billingModifiersLoading,
	billingModifiers,
	onBillingModifierSelect,
	onBillingModifierDelete,
	onBillingCodeDelete,
	onBillingCodeUnitChange,
	onApplyAllIcdCodes,
	onBillForServiceToggle,
	onSelectBillingCode,
	disabledFeatures,
}: IBillingServicesSectionProps) {
	const { t } = useTranslation(namespaces.components.billingDetails);

	const emBillingServices = billingCodes
		.filter((b) => b.modified !== 2)
		.filter((b) => b.serviceType?.toLowerCase() === 'emcode');

	const procedureDocumentationBillingServices = billingCodes
		.filter((b) => b.serviceType?.toLowerCase() === 'proceduredocumentation' && !!b.procedureDocumentationTemplateID)
		.filter(
			(b) =>
				!!procedureDocumentationTemplates.find((ptId) => ptId === b.procedureDocumentationTemplateID) ||
				isBillingServiceSelected(b)
		);

	const servicesByProcedureTemplate: {
		[templateId: string]: IBillingCode[];
	} = groupBillingServicesByTemplateId(procedureDocumentationBillingServices, 'procedureDocumentationTemplateID');

	const otherBillingServices = billingCodes.filter(
		(b) =>
			!b.serviceType ||
			(b.serviceType?.toLowerCase() !== 'proceduredocumentation' && b.serviceType.toLowerCase() !== 'emcode')
	);

	const servicesByEncounterObject: {
		[templateId: string]: IBillingCode[];
	} = groupBillingServicesByTemplateId(
		otherBillingServices.filter((x) => x.encounterObjectID),
		'encounterObjectID'
	);

	const handleApplyAllIcdCodes = (codes: IBillingCode[]) => {
		onApplyAllIcdCodes(codes);
	};

	return (
		<Box>
			{isLoading ? (
				<Box sx={{ display: 'fex', width: '100%', alignItems: 'center', justifyContent: 'center', mt: 1 }}>
					<Spinner />
				</Box>
			) : null}
			{emBillingServices.length || procedureDocumentationBillingServices.length || otherBillingServices.length ? (
				<Box>
					{emBillingServices.length > 0 ? (
						<BillingServicesListContainer
							title={t('emSection')}
							onApplyAllIcdCodes={() =>
								handleApplyAllIcdCodes(emBillingServices.filter((x) => isBillingServiceSelected(x)))
							}
							icdCodesEnabled={!disabledFeatures.includes(BillingServicesFeatures.Icd10Codes) && !readonly}
						>
							{hasChangesThatMayAffectBillingServicesData && !isLoading && (
								<Box sx={{ display: 'flex', justifyContent: 'center' }}>
									<Stack direction="row" alignItems="center" gap={1}>
										<WarningAmberIcon color="warning" sx={{ fontSize: '1.3rem' }} />
										<Typography>{t('outOfDate')}</Typography>
									</Stack>
								</Box>
							)}
							<BillingServicesItemsList
								readonly={readonly}
								billingModifiersLoading={billingModifiersLoading}
								billingModifiers={billingModifiers}
								billingCodes={emBillingServices}
								icd10Codes={icd10Codes}
								onIcd10CodeSelect={onIcd10CodeSelect}
								onIcd10CodeDelete={onIcd10CodeDelete}
								onBillingModifierSelect={onBillingModifierSelect}
								onBillingModifierDelete={onBillingModifierDelete}
								onBillingCodeUnitChange={onBillingCodeUnitChange}
								onBillingCodeDelete={onBillingCodeDelete}
								onBillForServiceToggle={onBillForServiceToggle}
								disabledFeatures={disabledFeatures}
							/>
						</BillingServicesListContainer>
					) : null}
					{procedureDocumentationBillingServices.length > 0 &&
					(!readonly || procedureDocumentationBillingServices.filter((x) => x.serviceID).length > 0) ? (
						<BillingServicesListContainer
							sx={{ mt: 2 }}
							title={t('procedureDocumentationSection')}
							onApplyAllIcdCodes={() =>
								handleApplyAllIcdCodes(procedureDocumentationBillingServices.filter((x) => isBillingServiceSelected(x)))
							}
							icdCodesEnabled={!disabledFeatures.includes(BillingServicesFeatures.Icd10Codes) && !readonly}
						>
							{Object.keys(servicesByProcedureTemplate).map((key) => {
								const services = servicesByProcedureTemplate[key];
								const procedureTemplateID = services[0]?.procedureDocumentationTemplateID;
								const procedureTemplateTitle = services[0]?.encounterObjectName;
								const missingProcedureTemplateWarning = !procedureDocumentationTemplates.find(
									(templateId) => templateId === procedureTemplateID
								);
								return (
									<BillingServicesWithSuggestions
										key={procedureTemplateID}
										readonly={readonly}
										title={procedureTemplateTitle || ''}
										warning={
											missingProcedureTemplateWarning && !isLoading
												? t('missingProcedureDocumentation', { procedureTemplateName: procedureTemplateTitle })
												: undefined
										}
										billingModifiersLoading={billingModifiersLoading}
										billingModifiers={billingModifiers}
										billingCodes={services}
										icd10Codes={icd10Codes}
										onIcd10CodeSelect={onIcd10CodeSelect}
										onIcd10CodeDelete={onIcd10CodeDelete}
										onBillingModifierSelect={onBillingModifierSelect}
										onBillingModifierDelete={onBillingModifierDelete}
										onBillingCodeUnitChange={onBillingCodeUnitChange}
										onBillForServiceToggle={onBillForServiceToggle}
										onSelectBillingCode={onSelectBillingCode}
										disabledFeatures={disabledFeatures}
									/>
								);
							})}
						</BillingServicesListContainer>
					) : null}
					{otherBillingServices.length > 0 &&
					(!readonly || otherBillingServices.filter((x) => x.serviceID).length > 0) ? (
						<BillingServicesListContainer
							sx={{ mt: 2 }}
							title={t('otherSection')}
							onApplyAllIcdCodes={() =>
								handleApplyAllIcdCodes(otherBillingServices.filter((x) => isBillingServiceSelected(x)))
							}
							icdCodesEnabled={!disabledFeatures.includes(BillingServicesFeatures.Icd10Codes) && !readonly}
						>
							<BillingServicesWithSuggestions
								readonly={readonly}
								billingModifiersLoading={billingModifiersLoading}
								billingModifiers={billingModifiers}
								billingCodes={otherBillingServices.filter((x) => !x.encounterObjectID)}
								icd10Codes={icd10Codes}
								onIcd10CodeSelect={onIcd10CodeSelect}
								onIcd10CodeDelete={onIcd10CodeDelete}
								onBillingModifierSelect={onBillingModifierSelect}
								onBillingModifierDelete={onBillingModifierDelete}
								onBillingCodeUnitChange={onBillingCodeUnitChange}
								onBillForServiceToggle={onBillForServiceToggle}
								onSelectBillingCode={onSelectBillingCode}
								disabledFeatures={disabledFeatures}
							/>
							{Object.keys(servicesByEncounterObject).map((key) => {
								const services = servicesByEncounterObject[key];
								const encounterObjectID = services[0]?.encounterObjectID;
								const encounterObjectName = services[0]?.encounterObjectName;
								return (
									<BillingServicesWithSuggestions
										key={encounterObjectID}
										readonly={readonly}
										title={encounterObjectName || ''}
										billingModifiersLoading={billingModifiersLoading}
										billingModifiers={billingModifiers}
										billingCodes={services}
										icd10Codes={icd10Codes}
										onIcd10CodeSelect={onIcd10CodeSelect}
										onIcd10CodeDelete={onIcd10CodeDelete}
										onBillingModifierSelect={onBillingModifierSelect}
										onBillingModifierDelete={onBillingModifierDelete}
										onBillingCodeUnitChange={onBillingCodeUnitChange}
										onBillForServiceToggle={onBillForServiceToggle}
										onSelectBillingCode={onSelectBillingCode}
										disabledFeatures={disabledFeatures}
									/>
								);
							})}
						</BillingServicesListContainer>
					) : null}
				</Box>
			) : (
				!isLoading && (
					<Box>
						<Typography sx={{ display: 'flex', flex: 1 }}>{t('noBillingServices')}</Typography>
					</Box>
				)
			)}
		</Box>
	);
}

export default BillingServicesSection;
