import { call, put, select } from 'redux-saga/effects';
import { actions, IAction } from '../actions';
import {
	IResetPasswordConfirmNewPasswordActionPayload,
	IResetPasswordRequestActionPayload,
	IResetPasswordSendConfirmCodeActionPayload,
	ISignOutActionPayload,
	IUserLoginActionPayload,
} from './auth.actions';
import { handleGlobalException } from '../../../application/exception-handlers';
import { resetPassword, userSignIn, validatePin } from '../../api/user/user.api';
import {
	deleteUserCache,
	getMfaAuthorizationTokenKeyCache,
	setApiKeyCache,
	setBearerCache,
	setIsAuthenticatedCache,
	setMfaAuthorizationTokenKeyCache,
	setUserSettingsCache,
} from '../../../system/local-storage';
import { push } from '../../../system/navigator';
import { routes } from '../../../pages/App/routes';
import { IServiceResult } from '../../models/service.models';
import { IUserAuth, IUserCustomerPermissionsItem, IUserSettings } from '../../models/users/user.models';
import { clearAppInsightsUser, trackEvent } from '../../../application/app-insights';
import { EHRType } from '../../models/ehr.models';
import { AppState } from '../../core.types';
import { getEhrCustomers } from '../../api/customer/customer.api';

export function* userSignInSaga(action: IAction<IUserLoginActionPayload>) {
	try {
		// reset data
		yield put(actions.auth.signInIsLoadingAction({ loading: true }));

		yield put(actions.auth.setMfaPinIsInvalid(false));

		const result: IServiceResult<{ settings: IUserSettings; auth: IUserAuth }> = yield call(userSignIn, {
			username: action.payload.username,
			password: action.payload.password,
			mfaAuthorizationToken: getMfaAuthorizationTokenKeyCache() || undefined,
			mfaVerificationPIN: action.payload.pin,
		});

		if (result.success) {
			const { settings: tempSettings, auth } = result.data;
			const settings = { ...tempSettings };

			yield put(actions.auth.signInResultAction({ success: result.success, error: result.error || '' }));

			const billingServicesEnabled =
				settings.system === EHRType.Athena ||
				settings.system === EHRType.NextGen ||
				settings.system === EHRType.PrimeSuite;
			const ehrLoginEnabled =
				settings.system === EHRType.Athena ||
				settings.system === EHRType.AllscriptsTW ||
				settings.system === EHRType.AllscriptsPro;

			settings.featureFlags.viewBillingServices.enabled =
				settings.featureFlags.viewBillingServices.enabled && billingServicesEnabled;
			settings.featureFlags.editBillingServices.enabled =
				settings.featureFlags.viewBillingServices.enabled && billingServicesEnabled;
			settings.featureFlags.ehrLogin.enabled = settings.featureFlags.ehrLogin.enabled && ehrLoginEnabled;

			setBearerCache(auth.bearer);
			setApiKeyCache(auth.apiKey);

			if (settings.permissions.allCustomers || settings.permissions.tenantAccess) {
				const customers: IServiceResult<IUserCustomerPermissionsItem[]> = yield call(getEhrCustomers);
				if (customers.success) {
					customers.data.forEach((customer) => {
						if (!settings.userCustomerPermissions.find((x) => x.customerId === customer.customerId)) {
							settings.userCustomerPermissions.push(customer);
						}
					});
				}
			}

			if (auth.mfaAuthorizationToken) {
				setMfaAuthorizationTokenKeyCache(auth.mfaAuthorizationToken);
			}
			setIsAuthenticatedCache(true);
			setUserSettingsCache(settings);

			yield put(actions.app.initUserServicesAction({ userSettings: settings }));
			push(routes.home);
		} else if (result.data.auth?.mfaRequired) {
			yield put(
				actions.auth.signInResultAction({
					success: result.success,
					error: result.error || 'Authorization failed',
					mfaRequired: result.data.auth.mfaRequired,
					emailAddress: result.data.auth.emailAddress || '',
					invalidMfaPin: result.data.auth.invalidMfaPin,
				})
			);
		} else {
			yield put(
				actions.auth.signInResultAction({ success: result.success, error: result.error || 'Authorization failed' })
			);
		}
	} catch (e) {
		handleGlobalException(e);
		yield put(actions.auth.signInResultAction({ success: false, error: 'Something went wrong' }));
	} finally {
		yield put(actions.auth.signInIsLoadingAction({ loading: false }));
	}
}

export function* userSignOutSaga(action: IAction<ISignOutActionPayload | undefined>) {
	try {
		if (!action.payload?.notRedirect) {
			push(routes.login);
		}
		trackEvent('2501001', 'LogOut', {
			onTimeOut: !!action.payload?.onTimeOut,
			onDocument: !!action.payload?.onDocument,
			onUnauthorized: !!action.payload?.onUnauthorized,
			onSessionExpired: !!action.payload?.onSessionExpired,
		});
		clearAppInsightsUser();
		deleteUserCache();
		yield put(actions.user.clearUserSettingsAction());
	} catch (e) {
		handleGlobalException(e);
	}
}

export function* resetPasswordSaga(
	action: IAction<IResetPasswordRequestActionPayload | IResetPasswordConfirmNewPasswordActionPayload>
) {
	try {
		yield put(actions.auth.setResetPasswordIsLoading(true));

		let result: IServiceResult<string | null> | null = null;
		if ((action.payload as IResetPasswordConfirmNewPasswordActionPayload).newPassword) {
			const payload = action.payload as IResetPasswordConfirmNewPasswordActionPayload;

			const confirmationCode: string | null = yield select((state: AppState) => state.auth.resetPassword.code);

			result = yield call(resetPassword, {
				Username: payload.username.split('@')[0],
				EmailAddress: payload.email.toLowerCase(),
				NewPassword: payload.newPassword,
				ConfirmPassword: payload.confirmPassword,
				VerificationPin: confirmationCode || '',
				AppVerifiedPin: 1,
			});

			if (result?.success) {
				yield put(actions.auth.setResetPasswordRequestResult({ success: true }));
			} else {
				yield put(actions.auth.setResetPasswordRequestResult({ error: result?.error || 'Something went wrong' }));
			}
		} else if ((action.payload as IResetPasswordSendConfirmCodeActionPayload).confirmationCode) {
			yield put(actions.auth.setResetPasswordRequestResult({ codeConfirmed: null }));
			const payload = action.payload as IResetPasswordSendConfirmCodeActionPayload;
			const validateCodeResult: IServiceResult<void> = yield call(validatePin, {
				Username: payload.username.split('@')[0],
				VerificationPin: payload.confirmationCode,
			});

			if (validateCodeResult.success) {
				yield put(
					actions.auth.setResetPasswordRequestResult({
						codeConfirmed: true,
						code: payload.confirmationCode,
						error: null,
					})
				);
			} else {
				yield put(
					actions.auth.setResetPasswordRequestResult({
						codeConfirmed: false,
						error: validateCodeResult?.error || 'Something went wrong',
					})
				);
			}
		} else {
			const payload = action.payload as IResetPasswordRequestActionPayload;
			result = yield call(resetPassword, {
				Username: payload.username.split('@')[0],
				EmailAddress: payload.email.toLowerCase(),
				AppVerifiedPin: 0,
			});

			if (result?.success) {
				yield put(actions.auth.setResetPasswordRequestResult({ codeRequested: true, error: null }));
			} else {
				yield put(actions.auth.setResetPasswordRequestResult({ error: result?.error || 'Something went wrong' }));
			}
		}
	} catch (e) {
		handleGlobalException(e);
	} finally {
		yield put(actions.auth.setResetPasswordIsLoading(false));
	}
}
