import { AnyObject } from '../../shared/types';
import { getApiKeyCache, getBearerCache, getUserSettingsCache } from '../../system/local-storage';
import { forcePushLogin } from '../../system/navigator';
import { IServiceResult } from '../models/service.models';

export interface IApiResponse {
	Success: boolean;
	Errors?: string;
}

export interface IApiSimpleHttpResponse {
	status: number;
	data?: {
		Success?: boolean;
		Errors?: string;
	};
}
export interface IApiHttpResponse<T> {
	status: number;
	data?: T & {
		Success?: boolean;
		Errors?: string;
	};
}

function queryParams(params: AnyObject): string {
	return (
		(Object.keys(params).length > 0 ? '?' : '') +
		Object.keys(params)
			.filter((k) => params[k] !== '')
			.map((k) => `${k}=${params[k]}`)
			.join('&')
	);
}

function fetchEndpoint(
	endpoint: string,
	method: string,
	body: AnyObject | FormData,
	additionalHeaders: AnyObject = {},
	formData = false,
	forcePayloadBody = false,
	forcePayloadQueryParams = false
): Promise<Response> {
	const bearerToken = getBearerCache();
	const apiKey = getApiKeyCache();
	const userSettings = getUserSettingsCache();

	const authorization = bearerToken && { authorization: `Bearer ${bearerToken}` };
	const apiKeyHeader = apiKey && { 'API-Key': apiKey };
	const customerId = userSettings?.customerId && { 'Customer-ID': userSettings.customerId };
	const contentTypeHeader = formData ? {} : { 'Content-Type': 'application/json' };

	const headers = {
		Accept: 'application/json',
		...contentTypeHeader,
		ClientApp: 'web',
		...authorization,
		...apiKeyHeader,
		...customerId,
		...additionalHeaders,
	};

	let requestProps = {};

	let newEndpoint = endpoint;

	if (forcePayloadBody) {
		requestProps = {
			method,
			headers,
			body: formData ? body : JSON.stringify(body),
		};
	} else if (forcePayloadQueryParams) {
		requestProps = {
			method,
			headers,
		};
		newEndpoint += queryParams(body as AnyObject);
	} else if (method === 'POST' || method === 'PUT' || method === 'PATCH') {
		requestProps = {
			method,
			headers,
			body: formData ? body : JSON.stringify(body),
		};
	} else if (method === 'GET' || method === 'DELETE') {
		requestProps = {
			method,
			headers,
		};
		newEndpoint += queryParams(body as AnyObject);
	}

	return fetch(newEndpoint, requestProps);
}

async function request<T>(
	method: string,
	endpoint: string,
	body: AnyObject,
	headers: AnyObject,
	logoutOnNotAuthorized: boolean
): Promise<T> {
	const bearerToken = getBearerCache();
	const apiKey = getApiKeyCache();

	const response: Response = await fetchEndpoint(endpoint, method, body, headers);
	if (response.status === 401 && bearerToken && apiKey && logoutOnNotAuthorized) {
		forcePushLogin();
	}

	return response.json();
}

async function requestV2<T>(
	method: string,
	endpoint: string,
	body: AnyObject | FormData,
	headers: AnyObject,
	logoutOnNotAuthorized: boolean,
	formData = false,
	forcePayloadBody = false,
	forcePayloadQueryParams = false
): Promise<{ status: number; data: T | undefined }> {
	const bearerToken = getBearerCache();
	const apiKey = getApiKeyCache();

	const response: Response = await fetchEndpoint(
		endpoint,
		method,
		body,
		headers,
		formData,
		forcePayloadBody,
		forcePayloadQueryParams
	);
	if (response.status === 401 && bearerToken && apiKey && logoutOnNotAuthorized) {
		forcePushLogin();
	}

	let data;
	try {
		data = (await response.json()) as unknown as T;
	} catch (e) {
		data = undefined;
	}

	return { status: response.status, data };
}

export async function postRequest<T>(
	endpoint: string,
	body: AnyObject = {},
	headers: AnyObject = {},
	logoutOnNotAuthorized = true
): Promise<T> {
	return request('POST', endpoint, body, headers, logoutOnNotAuthorized);
}

export async function putRequestV2<T>(
	endpoint: string,
	body: AnyObject = {},
	headers: AnyObject = {},
	logoutOnNotAuthorized = true
): Promise<{ status: number; data: T | undefined }> {
	return requestV2('PUT', endpoint, body, headers, logoutOnNotAuthorized);
}

export async function postRequestV2<T>(
	endpoint: string,
	body: AnyObject = {},
	headers: AnyObject = {},
	logoutOnNotAuthorized = true
): Promise<{ status: number; data: T | undefined }> {
	return requestV2('POST', endpoint, body, headers, logoutOnNotAuthorized);
}

export async function postFormRequestV2<T>(
	endpoint: string,
	formData: FormData,
	headers: AnyObject = {},
	logoutOnNotAuthorized = true
): Promise<{ status: number; data: T | undefined }> {
	return requestV2('POST', endpoint, formData, headers, logoutOnNotAuthorized, true);
}

export async function putRequest<T>(
	endpoint: string,
	body: AnyObject = {},
	headers: AnyObject = {},
	logoutOnNotAuthorized = true
): Promise<T> {
	return request('PUT', endpoint, body, headers, logoutOnNotAuthorized);
}

export async function getRequest<T>(
	endpoint: string,
	body: AnyObject = {},
	headers: AnyObject = {},
	logoutOnNotAuthorized = true
): Promise<T> {
	return request('GET', endpoint, body, headers, logoutOnNotAuthorized);
}

export async function getRequestV2<T>(
	endpoint: string,
	body: AnyObject = {},
	headers: AnyObject = {},
	logoutOnNotAuthorized = true
): Promise<{ status: number; data: T | undefined }> {
	return requestV2('GET', endpoint, body, headers, logoutOnNotAuthorized);
}

export async function deleteRequestV2<T>(
	endpoint: string,
	body: AnyObject = {},
	headers: AnyObject = {},
	logoutOnNotAuthorized = true,
	options?: {
		forcePayloadBody?: boolean;
		forcePayloadQueryParams?: boolean;
	}
): Promise<{ status: number; data: T | undefined }> {
	return requestV2(
		'DELETE',
		endpoint,
		body,
		headers,
		logoutOnNotAuthorized,
		false,
		!!options?.forcePayloadBody,
		!!options?.forcePayloadQueryParams
	);
}

export async function patchRequest<T>(
	endpoint: string,
	body: AnyObject = {},
	headers: AnyObject = {},
	logoutOnNotAuthorized = true
): Promise<T> {
	return request('PATCH', endpoint, body, headers, logoutOnNotAuthorized);
}

export async function patchRequestV2<T>(
	endpoint: string,
	body: AnyObject = {},
	headers: AnyObject = {},
	logoutOnNotAuthorized = true
): Promise<{ status: number; data: T | undefined }> {
	return requestV2('PATCH', endpoint, body, headers, logoutOnNotAuthorized);
}

export function mapResponse<R extends IApiResponse>(result: R): IServiceResult<void> {
	return {
		success: result.Success,
		error: result.Errors,
		data: undefined,
	};
}

export function mapHttpResponse(result: IApiSimpleHttpResponse): IServiceResult<void> {
	return {
		success: result.status === 200 && result.data?.Success !== false,
		error: result.data?.Errors,
		data: undefined,
	};
}

export function mapData<R extends IApiResponse, T>(result: R, mapper: (res: R) => T): IServiceResult<T> {
	return {
		success: result.Success !== undefined ? result.Success : true,
		error: result.Errors,
		data: mapper(result),
	};
}

export function mapHttpData<R, T>(result: IApiHttpResponse<R>, mapper: (res: R) => T): IServiceResult<T> {
	return {
		success: result.status === 200 && result.data?.Success !== false,
		error: result.data?.Errors,
		data: result.data ? mapper(result.data) : (undefined as unknown as T),
	};
}
