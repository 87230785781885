import * as React from 'react';
import { Box, Typography } from '@mui/material';
import { IPhrase, IUserAIConfigPhrase, IUserAIConfiguration } from '../../core/api/user/user-ai-settings.models';
import SelectFiled from '../select/SelectField';

interface IUserAIConfigPhraseDropdownProps {
	userInfoId: string;
	configuration: IUserAIConfiguration;
	phraseSubType: string;
	onConfigurationChange: (configuration: IUserAIConfigPhrase[]) => void;
	label?: string;
}
function UserAIConfigPhraseDropdown({
	userInfoId,
	configuration,
	onConfigurationChange,
	phraseSubType,
	label,
}: IUserAIConfigPhraseDropdownProps) {
	const defaultLabel = 'System Default';

	const allPhrases = configuration.AllPhrases.filter((x) => x.AIPhraseType.Type.toLowerCase() === 'provider');
	const userPhrases = configuration.UserConfigPhrases;
	const ehrPhrases = configuration.EhrConfigPhrases.filter(
		(x) => x.AIPhrase.AIPhraseType.Type.toLowerCase() === 'provider'
	);

	let options: { value: string; text: string }[] = allPhrases
		.filter((x) => x.AIPhraseType.SubType.toLowerCase() === phraseSubType.toLowerCase())
		.map((x) => {
			return {
				value: x.ID.toString(),
				text: x.Description,
			};
		});
	if (options.length === 0) {
		options = [
			{
				value: 'systemDefault',
				text: `${defaultLabel}`,
			},
			...options,
		];
	}

	let selectedValue: string | undefined = userPhrases
		.find((x) => x.AIPhrase.AIPhraseType.SubType.toLowerCase() === phraseSubType.toLowerCase())
		?.AIPhrase.ID?.toString();

	if (!selectedValue) {
		selectedValue = ehrPhrases
			.find((x) => x.AIPhrase.AIPhraseType.SubType.toLowerCase() === phraseSubType.toLowerCase())
			?.AIPhrase.ID?.toString();
		if (!selectedValue && options.find((x) => x.value === 'systemDefault')) {
			selectedValue = 'systemDefault';
		}
	}

	const onSelectChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		const { value } = e.target;

		const newUserConfigPhrases = userPhrases.filter(
			(x) => x.AIPhrase.AIPhraseType.SubType.toLowerCase() !== phraseSubType.toLowerCase()
		);

		if (value === 'systemDefault') {
			onConfigurationChange([...newUserConfigPhrases]);
		} else {
			const selectedPhrase = allPhrases.find((x) => x.ID.toString() === value.toString()) as IPhrase;
			onConfigurationChange([
				...newUserConfigPhrases,
				{
					ID: 0,
					AIPhrase: { ...selectedPhrase },
					AIPhraseID: selectedPhrase.ID,
					UserInfoID: Number(userInfoId),
					AIFunctionID: configuration.FunctionId,
				},
			]);
		}
	};

	return (
		<Box>
			{label && (
				<Typography color="primary" fontSize="1rem">
					{label}
				</Typography>
			)}
			<SelectFiled
				size="small"
				fullWidth
				data={options}
				value={selectedValue}
				InputProps={{ style: { height: '32px' } }}
				onChange={onSelectChange}
			/>
		</Box>
	);
}

export default UserAIConfigPhraseDropdown;
